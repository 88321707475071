import {useLivraisonsPossiblesContext} from '@/contexts/useContexts/useLivraisonsPossiblesContext';
import {useNavigate} from 'react-router-dom';
import {useNavigatePanier} from '../panier/useNavigatePanier';
import {useLivraisons} from '@/haligator-factory';
import {useHalStates} from '@synako/haligator';

export const useAfficherPanier = () => {
  const {setLivraisonSelectionnee} = useLivraisonsPossiblesContext();
  const navigate = useNavigate();
  const navigatePanier = useNavigatePanier();

  const livraisonsPossibles = useHalStates(useLivraisons().followAll('items'));

  const afficherPanier = (dateLivraison: string) => {
    const state = livraisonsPossibles.states?.find(livraison => livraison.data.tolede.date_de_livraison === dateLivraison);
    if (state) {
      setLivraisonSelectionnee(state);
    }
    navigate(navigatePanier(dateLivraison));
  };

  return afficherPanier;
};
