import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import routes from '@/routes';
import {Text, VStack} from '@chakra-ui/react';
import {TFunction} from 'i18next';
import {Link} from 'react-router-dom';

export const LeftJeViensDeValider: React.FC<{t: TFunction}> = ({t}) => {
  const {buildAssistanceRoute} = useIsAssistanceMode();
  return(
    <VStack bg='blue.main' color='white' justify='center'
      backgroundImage='url("/ffconnect/panier/bg_merci.png")'
      backgroundPosition='center' backgroundSize='cover'
      backgroundRepeat='no-repeat'
      fontWeight='700' textAlign='center' gap='0' py={{base:'10', md:'0'}} px={{base:'2', md:'0'}}>
      <Text fontSize={{base:'3xs', md:'4xl'}}>{t('panier.vide.merci')}</Text>
      <Text fontSize={{base:'2xl', md:'3xl'}} mb='10'>{t('panier.vide.pour_votre_commande')}</Text>
      <Link to={buildAssistanceRoute(routes.client.commandes)} style={{display: 'block', width: '100%'}}>
        <Text fontSize='lg' w={{base:'100%', md:'80%'}} mb='5' mx="auto" textAlign="center" cursor="pointer">{t('panier.vide.consulter_detail_commande')}</Text>
      </Link>
      <Text
        fontSize='sm'
        fontWeight='400'
        w={{base:'100%', md:'60%'}}
        fontStyle='italic'
      >
        {t('panier.vide.pour_toute_question')}
      </Text>
    </VStack>
  );
};
