import {Dispatch, MouseEvent, useCallback} from 'react';
import {GridItem, HStack} from '@chakra-ui/react';
import {Resource, State} from '@synako/halitrek';
import {ItemCatalogueDesc, LivraisonDesc} from 'api-types/ffconnect-hal';
import {DateButton} from './DateButton';
import {selectedProps} from '../calendar-utils';
import {UniteQuantite} from 'api-types/ffconnect';

export const CalendarCellLivraisonArticle: React.FC<{
  date: Date,
  isSelected: boolean
  livraisonAssociee: State<LivraisonDesc>,
  handleDateClick: (livraison: Resource<LivraisonDesc>, event: MouseEvent<HTMLButtonElement>) => void,
  itemPreco: State<ItemCatalogueDesc>,
  setSelectionUnite: (unite:UniteQuantite)=>void,
  setQuantite: Dispatch<React.SetStateAction<number>>
}> = ({
  date,
  isSelected,
  livraisonAssociee,
  handleDateClick,
  itemPreco,
  setSelectionUnite,
  setQuantite
}) => {
  const applyQuantite = useCallback(() => {
    setSelectionUnite('unitaire');
    const quantite = Math.max(
      itemPreco.data.tolede.minimum_de_commande_client, 
      itemPreco.data.tolede.multiple_de_commande_client
    );
    setQuantite(quantite);
  }, [setQuantite, setSelectionUnite, itemPreco]);

  return (
    <GridItem as={HStack} alignItems='stretch' justify='center'>
      <DateButton 
        date={date} 
        {...selectedProps(isSelected)} 
        onClick={event => {
          handleDateClick(livraisonAssociee.resource, event);
          //Est-ce qu'on veut vraiment reset la quantité à chaque clique?
          applyQuantite(); 
        }} 
      />
    </GridItem>
  );
};
