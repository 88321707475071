import {CustomSorterFunction, Orama, Result, Results, SearchParams, SearchableType, WhereCondition, create, insertMultiple, search} from '@orama/orama';
import {Article, CategorieWeb, HitCategorieWeb, HitItemCatalogue, NatureDuPrix, PaysEnum, SearchClient} from 'api-types/ffconnect';

import {useContext} from 'react';
import {CoerceOf} from '@/hooks/use-filters';
import {SearchClientContext} from './SearchClientContext';
import {deburr, intersection, uniq} from 'lodash-es';

export interface FiltresItemCatalogueConfig {
  Data: FiltresItemCatalogue,
  Coerce: typeof coerceFiltresItemCatalogue
}

/**
 * Au final le meilleur calcul du score permet de déclencher sur des tokens plus petits types : OS, VF, etc.
 */
export const SEARCH_MIN_CHARS = 2;
/**
 * Permet de faire jouer "plus" le tri standard mais réduit la pertinence de la correspondance textuelle
 */
export const ECART_SCORE_TRI = 3;

export interface FiltresItemCatalogue {
  /** Recherche full-text sur le libelle_commercial * 4 + mot_directeur */
  q?: string;
  est_cadencier?: boolean;
  cat_web?: string[];
  nature_du_prix?: NatureDuPrix;

  // Pas utilisé directement dans les filtres, juste en remplacement
  mot_directeur?: string;
  cat_std?: string[];
  
  // Utilisé uniquement pour le classement
  // est_TAC?: boolean;
  est_MP?: boolean;
  // type_de_marque?: string[],
  // delai_dispo?: number

  pays_d_origine?: PaysEnum[];
  labels?: string[];
  sortBy?: 'pertinence' | 'alphabetique';
  page?: number;
  precommande?: boolean;
}

export const coerceFiltresItemCatalogue = {
  q: 'string',
  nature_du_prix: 'string',
  mot_directeur: 'string',
  cat_web: 'string[]',
  cat_std: 'string[]',
  est_cadencier: 'boolean',
  pays_d_origine: 'string[]',
  labels: 'string[]',
  sortBy: 'string',
  page: 'number',
  est_MP: 'boolean',
  // type_de_marque: 'string[]',
  // type_de_mar
  precommande: 'boolean',
} satisfies CoerceOf<FiltresItemCatalogue>;

export const ITEMS_PAR_PAGE = 30;

export function useSearchClient() {
  const indexes = useContext(SearchClientContext);

  const getNomenclatureWeb = () => {
    if (!indexes?.categories_idx.data.docs.docs) {
      return undefined;
    }
    const docs = indexes.categories_idx.data.docs.docs as Record<string, CategorieWeb>;
    return Object.values(docs);
  };
  
  return {
    indexIsBuilt: !!indexes,
    indexes,
    // Items du catague
    searchItems,
    remplacementPour,
    remplacementPour2,
    // Catégories Web
    categorieCounts,
    searchCategories,
    tokenize,
    getNomenclatureWeb,
  };

  async function searchItems(filters: FiltresItemCatalogue, itemsPerPage = ITEMS_PAR_PAGE) {
    const where : WhereCondition<CatalogueOramaSchema>= {};
    if (filters.cat_std) {
      where.cat_std = {containsAll: filters.cat_std};
    }
    if (filters.cat_web) {
      where.cat_web = {containsAll: filters.cat_web};
    }
    if (filters.nature_du_prix) {
      where.nature_du_prix = {eq: filters.nature_du_prix};
    }
    if (filters.mot_directeur) {
      where.mot_directeur = {eq: filters.mot_directeur};
    }
    if (filters.pays_d_origine) {
      where.pays_d_origine = {containsAll: filters.pays_d_origine};
    }
    if (filters.labels) {
      where.labels = {containsAll: filters.labels};
    }
    if (filters.precommande !== undefined) {
      where.precommande = filters.precommande;
    }
    if (filters.est_MP) {
      where.type_de_marque = {eq: 'MP'};
    }

    const page = filters.page ?? 1;
    const offset = (page - 1) * itemsPerPage;
    const params : CatalogueSearchParams = {
      where,
      limit: itemsPerPage,
      offset,
      facets: {
        cat_web: {
          limit: 100
        },
        cat_std: {},
        pays_d_origine: {},
        labels: {},
        nature_du_prix: {},
      }
    };

    if (filters.sortBy === 'alphabetique') {
      params.sortBy = triAlphabetique;
    } else {
      params.sortBy = triStandard;
    }

    if(filters.q) {
      params.term = filters.q;
      params.properties = [
        'code',
        'libelle_commercial',
        'marque',
        'mot_directeur',
      ];
      // exact: true,
      params.tolerance = 1; // c'est sur qu'on veut cela, c'est que qui donne la typo tolerance
      // si on met 2 en tolérance ça ramène plus mais aussi plus de bruit
      params.threshold = 1; // Recherche plus large et surtout permet de récupérer les facets si recherche sans terme

      params.sortBy = triRecherche(filters.q);

      // Le boost n'est pas vraiment utile si dernière on ne corrige pas avec le match exact
      // Exemple: Maitres laitiers creme fait que le lait fait un gros score alors que c'est juste la similarité avec "laitiers"
      params.boost = {
        libelle_commercial: 2,
        mot_directeur: 1,
        marque: 0.5,
      };
    }
    // FUTURE: à voir si on combine 2 ou 3 recherches en jouant sur exact et threshold ?
    
    return search(indexes!.catalogue_idx, params);
  }

  function tokenize(s: string) {
    return indexes!.catalogue_idx.tokenizer.tokenize(s) as string[];
  }

  async function remplacementPour(art: Article, filters: {
    // nature_du_prix?: NatureDuPrix,
    // pays_d_origine?: PaysEnum[],
    // labels?: string[],
    enleverPrecommande: boolean
  }) {
    const artT = art.tolede;
    return remplacementPour2({
      code_article: artT.code_article,
      sous_sous_famille_std: `${artT.code_activite}-${artT.code_famille}-${artT.code_sous_famille}-${artT.code_sous_sous_famille}`,
      mot_directeur: artT.mot_directeur,
      // q,
      ...filters
    });
  }

  async function remplacementPour2(config: {
    code_article: string,
    // Cela permet d'une part de ramasser d'autre part de classer
    sous_sous_famille_std: string,
    // Cela permet de ramasser
    mot_directeur: string | undefined,
    enleverPrecommande: boolean,
  }) {
    const {code_article, sous_sous_famille_std, mot_directeur, enleverPrecommande} = config;
    // Sélection de tous ceux qui ont le même mot directeur
    // Sélection de la même sous_sous_famille
    const docs : HitItemCatalogue[] = Object.values(indexes!.catalogue_idx.data.docs.docs);
    const selection = docs.filter(hit => {
      if (hit.precommande && enleverPrecommande) return false;
      if (hit.code === code_article) return false;
      return hit.cat_std.includes(sous_sous_famille_std) || hit.mot_directeur === mot_directeur;
    });

    const hits: Result<HitItemCatalogue>[] = selection.map(h => ({
      document: h,
      id: h.code,
      score: 0,
    }));
    const sortFn = triRemplacement(mot_directeur, sous_sous_famille_std);
    hits.sort((A, B) => sortFn([0, A.score, A.document], [0, B.score, B.document]));
    // Maintenant il faut trier : 
    // - d'abord ce qui n'est pas précommande (d'ailleurs dans certain cas il faut exclure).
    // - d'abord le même mot directeur puis même sous_sous_famille
    // - par contre il faut mettre aussi toutes

    // TODO: hits.sort();

    const final : Results<HitItemCatalogue> = {
      count: hits.length,
      elapsed: {
        raw: 1,
        formatted: 'TODO',
      },
      hits,
    };
    return final;
  }

  async function categorieCounts(filters?: FiltresItemCatalogue) {
    const where: WhereCondition<CatalogueOramaSchema> = {};
    if (filters?.nature_du_prix) {
      where.nature_du_prix = {eq: filters.nature_du_prix};
    }

    const query : CatalogueSearchParams = {
      where,
      facets: {
        cat_web: {
          limit: 100
        }
      }
    };
    const r = await search(indexes!.catalogue_idx, query);
    return {
      totalCount: r.count,
      elapsed: r.elapsed,
      ...r.facets!['cat_web']
    };
  }

  async function searchCategories({q}:{q: string}) {
    const params : CategorieSearchParams = {
      term: q,
      properties: ['libelle'],
      tolerance: 1, // c'est sur qu'on veut cela, c'est que qui donne la typo tolerance
      threshold: 1, // Recherche plus large et surtout permet de récupérer les facets si recherche sans terme
      limit: 5,
    };
    return search(indexes!.categories_idx, params);
  }
}

export type ExtendendSortItem = [number, number, HitItemCatalogue, number | undefined]; 

// Exemple 1 : boeuf a decouper
// Exemple 2 : creme maitres laitiers
// Cla veut dire qu'il faut redresser les scores
export const triRecherche = (q: string) : CustomSorterFunction<HitItemCatalogue> => {
  const qTokens = customTokenizer.tokenize(q);
  function boostExact (hit: HitItemCatalogue, _scoreOriginal: number) {
    const hitTokens = customTokenizer.tokenize(hit.libelle_commercial);
    const exactLibTokens = intersection(qTokens, hitTokens);
    const prefixLibTokens = hitTokens.filter(hToken => {
      return !! qTokens.find(qToken => hToken.startsWith(qToken)) && exactLibTokens.includes(hToken);
    });
    
    let exactMarqueTokens : string[] = [];
    if (hit.marque) {
      exactMarqueTokens = intersection(qTokens, customTokenizer.tokenize(hit.marque));
    }

    let exactMotDirecteurTokens : string[] = [];
    if (hit.mot_directeur) {
      exactMotDirecteurTokens = intersection(qTokens, customTokenizer.tokenize(hit.mot_directeur));
    }

    const codeExact = intersection(qTokens, [hit.code]);

    // if(['170981', '104333', '181225', '105465'].includes(hit.code)) {
    //   console.log({hit, scoreOriginal, qTokens, hitTokens, exactLibTokens, exactMarqueTokens});
    // }

    return exactLibTokens.length * 10
      + prefixLibTokens.length * 6
      + (qTokens[0] === hitTokens[0] ? 10 : 0)
      + codeExact.length * 20 // Match exact du code !
      + exactMotDirecteurTokens.length * 3
      + exactMarqueTokens.length * 2;
  }
  return (A, B) => {
    // Modification du score d'origine.
    const [, scoreA, docA, origScoreA] = A as unknown as ExtendendSortItem;
    const [, scoreB, docB, origScoreB] = B as unknown as ExtendendSortItem;
    // Calcul des scores redressés
    if (!origScoreA) {
      A[1] = scoreA/2 + boostExact(docA, scoreA);
      // A[1] = boostExact(docA);
      A.push(scoreA);
    }
    if (!origScoreB) {
      B[1] = scoreB/2 + boostExact(docB, scoreB);
      // B[1] = boostExact(docB);
      B.push(scoreB);
    }

    const diff = B[1] - A[1];
    if (Math.abs(diff) < ECART_SCORE_TRI) {
      return triStandard(A, B);
    }
    return diff;
  };
};

const triAlphabetique: CustomSorterFunction<HitItemCatalogue> = (
  [, , itemA],
  [, , itemB],
) => {
  return itemA.libelle_commercial.localeCompare(itemB.libelle_commercial);
};

const triRemplacement = (mot_directeur: string | undefined, sous_sous_famille_std: string) : CustomSorterFunction<HitItemCatalogue> => {
  return createTri([
    function est_Stock(hit) {return !hit.precommande;},
    function memeMotDirecteur(hit) {return mot_directeur ? hit.mot_directeur === mot_directeur : false;},
    function memeSSF(hit) {return hit.cat_std.includes(sous_sous_famille_std);},
    'est_cadencier',
    'est_TAC',
    function est_MP(hit) {return hit.type_de_marque === 'MP';},
    function est_MDD(hit) {return hit.type_de_marque === 'MDD';},
  ]);
};

export const triStandard = createTri([
  'est_cadencier',
  function est_Stock(hit) {return !hit.precommande;},
  'est_TAC',
  function est_MP(hit) {return hit.type_de_marque === 'MP';},
  function est_MDD(hit) {return hit.type_de_marque === 'MDD';},
]);

type PrioriteDeTri = (keyof HitItemCatalogue | ((hit : HitItemCatalogue) => boolean))[];
function createTri(prios: PrioriteDeTri) : CustomSorterFunction<HitItemCatalogue> {
  return (A, B) => {
    const [,scoreA, itemA] = A;
    const [,scoreB, itemB] = B;
    for (const test of prios) {
      let valA;
      let valB;
      if (typeof test === 'function') {
        valA = test(itemA);
        valB = test(itemB);
      } else {
        valA = itemA[test];
        valB = itemB[test];
      }
      // S'ils sont différents alors le vrai prend la priorité
      if (valA !== valB) {
        return valA ? -1 : 1;
      }
    }
    if(scoreA === scoreB) {
      return triAlphabetique(A, B);
    }
    return scoreB - scoreA;
  };
}

export interface SearchIndexes {
  catalogue_idx: Orama<CatalogueOramaSchema>;
  categories_idx: Orama<CategorieOramaSchema>;
}

type CatalogueOramaSchema = Record<keyof Omit<HitItemCatalogue, 'delai_dispo' | 'vignette_url'>, SearchableType> & {multiple_de_commande_client: SearchableType
};
type CategorieOramaSchema = Record<keyof Omit<HitCategorieWeb, 'id'>, SearchableType>;

type CatalogueOrama = Orama<CatalogueOramaSchema>;
type CategorieOrama = Orama<CategorieOramaSchema>;

type CatalogueSearchParams = SearchParams<CatalogueOrama, HitItemCatalogue>;
type CategorieSearchParams = SearchParams<CategorieOrama, HitCategorieWeb>;

const remplacements : Record<string, string[]> = {
  '1/2': ['demi'],
  'st': ['saint'],
  'ent': ['entier'],
  'entieres': ['entier'],
  'lc': ['lait', 'cru'],
  'bte': ['boite'],
  'vanillee': ['vanille'],
  'vanil': ['vanille'],
  'croissants': ['croissant'],
  'couv': ['couverture'],
  // Spéciale Sud-Ouest
  'chocolatine': ['pain', 'chocolat'],
  // On tape Noix mais il faut que ça match en priorité les cerneaux de noix
  'cerneaux': ['noix', 'cerneaux'],
  'cerneau': ['noix', 'cerneau'],
  'coule': ['oeuf', 'liquide', 'entier'],
  'coul': ['oeuf', 'liquide', 'entier'],
};

export const customTokenizer /* : Tokenizer */ = {
  tokenize: (raw: string, _language?: string, prop?: string): string[] => {
    if (prop === 'code') {
      return [raw.trim()];
    }
    const split = deburr(raw.toLocaleLowerCase()).replaceAll(/[\s\n\r]/g, ' ').trim().split(' ');
    const rempl = split.flatMap(s => remplacements[s] ?? [s]);
    // return rempl;
    // const filtered = rempl.filter(p => p.length > 2 || parseInt(p, 10) > 10);
    const filtered = rempl.filter(p => p.length >= SEARCH_MIN_CHARS);
    // évite que la "crème de la crème soit toujours en premier"
    const uniqued = uniq(filtered);
    return uniqued;
  },
  language: 'fr',
  normalizationCache: new Map(),
};

export async function buildIndexes(search_client: SearchClient) : Promise<SearchIndexes> {
  const schemaCatalogue : CatalogueOramaSchema = {
    code: 'string',
    // vignette_url: undefined, // Non-indexé
    libelle_commercial: 'string',
    mot_directeur: 'string',
    marque: 'string',
    cat_web: 'enum[]',
    cat_std: 'enum[]',
    est_TAC: 'boolean',
    type_de_marque: 'enum',
    est_cadencier: 'boolean',
    nature_du_prix: 'enum',
    pays_d_origine: 'enum[]',
    labels: 'enum[]',
    multiple_de_commande_client: 'number',
    precommande: 'boolean',
    temperature_livraison: 'enum',
  };
  const catalogue_idx = await create({
    id: 'catalogue',
    schema: schemaCatalogue,
    components: {
      // tokenizer: {
      //   // language: 'french',
      //   tokenizeSkipProperties: ['code', 'cat_web'],
      //   stemming: false,
      //   stemmerSkipProperties: ['code', 'cat_web'],
      //   stopWords
      // },
      tokenizer: customTokenizer,
    }
  });

  const schemaCategorie : CategorieOramaSchema = {
    // id: undefined, // Non-indexé
    libelle: 'string',
    id_parent: 'string',
  };
  const categories_idx = await create({
    id: 'categories',
    schema: schemaCategorie,
    components: {
      // tokenizer: {
      //   tokenizeSkipProperties: ['id'],
      //   stemming: false,
      //   stemmerSkipProperties: ['id'],
      //   stopWords
      // },
      tokenizer: customTokenizer,
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  await insertMultiple(catalogue_idx, search_client.items_catalogue as any, undefined, undefined, true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  await insertMultiple(categories_idx, search_client.categories as any, undefined, undefined, true);

  return {
    catalogue_idx,
    categories_idx,
  };
}
