import {useDebouncedCallback} from '@/hooks/useDebouncedCallback';
import {useState, useEffect} from 'react';
import {createSearchParams, useSearchParams} from 'react-router-dom';

export interface HookFilters<Filters = unknown, Coerce extends CoerceOf<Filters> = CoerceOf<Filters>> {
  filters: Filters,
  changeFilter: <K extends FieldNames<Filters>>(name : K, val: ChangeValue<Coerce[K]> | undefined) => void,
  changeMultipleFilters: <K extends FieldNames<Filters>>(updates: Record<K, ChangeValue<Coerce[K]> | undefined>) => void
}
 
export function useFilters<
  Filters,
  Coerce extends CoerceOf<Filters> = CoerceOf<Filters>
>(
  {
    coerce,
    debounceDelay
  } : {
    coerce?: Coerce,
    debounceDelay?: number,
  }
) : HookFilters<Filters, Coerce> {
  const [query, setQuery] = useSearchParams();
  const [filters, setFilters] = useState<Filters>(parseQuery(query, coerce ?? {}));

  useEffect(() => {
    setFilters(parseQuery(query, coerce ?? {}));
  // Le coerce n'a pas vocation à changer.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const changeFilter = <K extends FieldNames<Filters>>(name : K, val: ChangeValue<Coerce[K]> | undefined) => {
    setQuery(changeFilterInQuery(query, name, val));
  };
  const debouncedChangeFilter = useDebouncedCallback(changeFilter, debounceDelay);

  const changeMultipleFilters = <K extends FieldNames<Filters>>(updates: Record<K, ChangeValue<Coerce[K]> | undefined>) => {
    const newQuery = createSearchParams(query);
    Object.entries(updates).forEach(([name, val]) => {
      if(val === undefined) {
        newQuery.delete(name);
      } else if(Array.isArray(val)) {
        newQuery.delete(name);
        val.forEach(v => {newQuery.append(name, v);});
      } else {
        newQuery.set(name, String(val));
      }
    });
    setQuery(newQuery);
  };

  return {
    filters,
    changeFilter: debouncedChangeFilter,
    changeMultipleFilters
  };
}

type FieldNames<Filters> = Extract<keyof Filters, string>;
// type FieldNames<Filters> = keyof Filters;

export type CoerceOf<Filters> = {
  [K in FieldNames<Filters>]: CoerceTo;
};
type CoerceTo = 'string[]' | 'boolean' | 'string' | 'number';
type ChangeValue<CoerceTo> =
  CoerceTo extends 'boolean' ? boolean
    : CoerceTo extends 'string[]' ? string | string[]
      : CoerceTo extends 'number' ? number
        : string;

function parseQuery<Filters>(params: URLSearchParams, coerce: Record<string, CoerceTo | undefined>): Filters {
  const filters: Record<string, string | boolean | string[] | number> = {};
  for (const key of params.keys()) {
    if(coerce[key] === 'boolean') {
      filters[key] = Boolean(params.get(key));
    }
    else if(coerce[key] === 'string[]') {
      filters[key] = params.getAll(key);
    } else if (coerce[key] === 'number') {
      filters[key] = Number(params.get(key));
    }
    else {
      const val = params.get(key);
      if(val) {
        filters[key] = val;
      }
    }
  }
  return filters as Filters;
}

function changeFilterInQuery(
  oldQuery: URLSearchParams,
  name: string,
  // eslint-disable-next-line 
  val: ChangeValue<any> | undefined
) {
  const query = createSearchParams(oldQuery);
  if(val === undefined) {
    query.delete(name);
  }
  else if(typeof val === 'boolean') {
    query.set(name, String(val));
  }

  else if (typeof val === 'number') {
    query.set(name, val.toString());
  }
  else if(Array.isArray(val)) {
    query.delete(name);
    val.forEach(v => {query.append(name, v);});
  }
  else {
    // Donc '' est delete
    if(val) {
      query.set(name, val);
    } else {
      query.delete(name);
    }
  }
  return query;
}
