import {BlueButton} from '@/components/Button/BlueButton';
import {ModaleLayout} from '@/components/Modale/ModaleLayout';
import {useSocieteCommerciale} from '@/haligator-factory';
import {PanierCommands} from '@/hooks/panier/use-panier-commands';
import {WarningIcon} from '@chakra-ui/icons';
import {VStack, Text} from '@chakra-ui/layout';
import {Checkbox, HStack, Link} from '@chakra-ui/react';
import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {useHalState, UseHalStateHook} from '@synako/haligator';
import {PanierValideEventDesc, PanierValiderOptionsDesc} from 'api-types/ffconnect-hal';
import {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import routes from '@/routes';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {useDefaultOnError} from '@/hooks/useDefaultOnError';
import {State} from '@synako/halitrek';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';

export const PanierRecapValider: React.FC<{
  validerCommand: PanierCommands['valider'],
  validerOptions: UseHalStateHook<PanierValiderOptionsDesc>,
  message_post_validation?:string,
}> = ({
  validerCommand, 
  validerOptions,
  message_post_validation,
}) =>  {
  const [isCGVChecked, setCGVChecked] = useState<boolean>(false);
  const navigate = useNavigate();
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const {date_panier_actif} = useParams();
  const {t} = useTranslation('common');
  const onError = useDefaultOnError();
  const textToast = useSimpleTextToast();

  const onClick = () => {
    validerCommand.submitAsync({
      cgv_acceptee: isCGVChecked,
    }).then((event: State<PanierValideEventDesc>) => {
      if (event.data.data.logique === 'avertissement') {
        textToast(<Trans t={t}>{t('toast.panier_valide_safelive')}</Trans>, null);
      }
      else if (event.data.data.logique === 'simulation') {
        textToast(<Trans t={t}>{t('toast.panier_valide_simulation')}</Trans>, null);
      }
      if (message_post_validation){
        NiceModal.show(PopupMessagePostValidation, {message: message_post_validation});
      }
      window.scrollTo({
        top: 0,
        left: 0
      // behavior: 'smooth', //This cause the window to stop scrolling when the collapse opens
      });
      navigate(buildAssistanceRoute(routes.client.panier, {date_panier_actif}, {derniereAction: 'valide'}));
    // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
    }).catch(onError);
  };
  const mustCheckCGV = !validerOptions.data?.cgv_acceptee;
  const disable = 
    validerCommand.isSubmitting
    || validerCommand.isDisabled
    || validerOptions.isDisabled
    || validerOptions.loading
    || validerOptions.refreshing
    || !!validerOptions.data?.nb_problemes_bloquants
    || (!(isCGVChecked) && mustCheckCGV);

  const soc = useHalState(useSocieteCommerciale());

  return  <VStack gap='3' order={{base: '-1', lg: 'initial'}} alignItems='stretch' mt={!mustCheckCGV ? '5' : '0'}>
    {mustCheckCGV && <Checkbox alignItems='baseline' gap='2' my='2' isChecked={isCGVChecked} onChange={(e) => {setCGVChecked(e.target.checked);}}>
      <Text textAlign='start' textColor='black'>
        <Trans 
          t={t}
          components={{
            Link: <Link href={soc.data?.url_cgv} target="_blank" textDecorationLine='underline' cursor='pointer' />
          }}  
        >{t('panier.recap.accepter_conditions')}</Trans>
        <Text as="span" textColor='red.warning'> *</Text>
      </Text>
    </Checkbox>}
    <BlueButton
      texte={t('panier.recap.valider_le_panier')}
      onClick={onClick}
      mt='0'
      isDisabled={disable}
    />
  </VStack>;
};

export const PopupMessagePostValidation = NiceModal.create(({message}:{message:string})=>{
  const {t} = useTranslation('common');
  const modal = useModal();
  return <ModaleLayout
    visible={modal.visible}
    hide={modal.remove}
    headerIcon={<WarningIcon/>}
    titre={t('global.attention')}
  >
    <HStack alignItems={'baseline'} bg='blue.light'>
      <Text>{message}</Text>
    </HStack>
    <BlueButton w='100%' mt='10' onClick={modal.remove} texte={t('global.compris')}/>
  </ModaleLayout>; 
});
