import {Box, HStack} from '@chakra-ui/layout';
import {Button, Stack, Tag} from '@chakra-ui/react';
import {ProductControlSideHeader} from '../../../components/Product/ProductControlSideHeader';
import {useTranslation} from 'react-i18next';
import {CadencierTitreNombreDeProduits} from './CadencierTitreNombreDeProduits';

export interface CadencierHeaderProps {
  nombreDeProduits:number,
  editMode:boolean,
  setEditMode:(_v:boolean)=>void,
  categories: {categorieId: string; categorie: string}[],
  scrollVersCategorie: (categorieId: string) => void,
  activeTag: string | null
}

export const CadencierHeader: React.FC<CadencierHeaderProps> = ({nombreDeProduits, editMode, setEditMode, categories, scrollVersCategorie, activeTag}) => {
  return (
    <Stack>
      <Stack gap='0' flexDirection={{base: 'column', md:'row'}} justify='start'
        width='100%' alignItems='baseline' p={{base: '0', md: '2', lg: '5'}}
        bg='white'>
        <Box display={{base:'none', md:'contents'}}>
          <CadencierTitreNombreDeProduits nombreDeProduits={nombreDeProduits} />
          {!editMode?<ProductControlSideHeader deleteAction={()=>{setEditMode(true);}}/>:
            <ProductControlSideHeaderEdition validateAction={()=>{console.log('Validate edition');}} backAction={()=>{setEditMode(false);}} />
          }
        </Box>
      </Stack>
      <Box bg="white" width="100%">
        <HStack wrap="wrap" spacing={2} p={{base:'0 10px 0 10px', md:'0 0 10px 10px'}} bg='white' zIndex='dropdown' mb='10px'>
          {categories.map((cat) => (
            <Tag
              key={`tag_${cat.categorieId}`}
              size='md'
              cursor="pointer"
              border='solid 1px'
              borderColor={activeTag === cat.categorieId ? 'black.text' : 'gray.light'}
              bg='white'
              color={activeTag === cat.categorieId ? 'black.text' : 'gray.dark'}
              onClick={() => {scrollVersCategorie(cat.categorieId);}}
              _hover={{bg: 'gray.dark', color:'white'}}
            >
              {cat.categorie}
            </Tag>
          ))}
        </HStack>
      </Box>
    </Stack>
  );
};

export const ProductControlSideHeaderEdition:React.FC<{validateAction:()=>void,backAction:()=>void}> = ({validateAction, backAction})=>{
  const {t} = useTranslation('common');
  
  return <>
    {/* Version mobile */}
    <HStack flexWrap='wrap' w='100%' gap='0' display={{base:'flex', md:'none'}}>
      <Button minW='min(fit-content, 50%)' flex='1' w='max-content' borderRadius='0' p='5'
        borderY='1px solid' borderX='0.5px solid' borderColor='gray.light' variant='ghost' textDecorationLine='underline'
        onClick={()=>{backAction();}}
      >
        {t('global.annuler')}
      </Button>
      <Button minW='min(fit-content, 50%)' flex='1' w='max-content' borderRadius='0' p='5' borderY='1px solid' borderX='0.5px solid' colorScheme='blue'
        onClick={()=>{validateAction(); backAction();}}
      >
        {t('cadencier.enregistrer_modifications')}
      </Button>
    </HStack>
    {/* ----- */}
    {/* Version desktop */}
    <HStack ml='auto' alignItems='baseline' display={{base:'none', md:'flex'}}>
      <Button maxW='50%' variant='ghost' textDecorationLine='underline'
        onClick={()=>{backAction();}}
      >
        {t('global.annuler')}
      </Button>
      <Button colorScheme='blue' onClick={()=>{validateAction(); backAction();}}>{t('cadencier.enregistrer_modifications')}</Button>
    </HStack>
  </>;
};
