import {toDecimalFr} from '@/utils/toDecimalFr';
import {TriangleDownIcon} from '@chakra-ui/icons';
import {Select, StyleProps} from '@chakra-ui/react';
import {State} from '@synako/halitrek';
import {UniteQuantite} from 'api-types/ffconnect';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

export interface SelectUniteProps {
  /**
   * Pour commander, tout part d'un item du catalogue.
   */
  itemCatalogue: State<ItemCatalogueDesc>,
  /**
   * Dans le panier, on peut vouloir partir de l'unité qui avait mise sur la ligne de panier.
   */
  initialValue: UniteQuantite | undefined,
  quantite_max?: number | undefined
  onSelect: (unite: UniteQuantite) => void,
}

export const SelectUnite: React.FC<StyleProps & SelectUniteProps> = ({
  itemCatalogue,
  initialValue,
  quantite_max,
  onSelect,
  ...props
}) => {
  const {t} = useTranslation('common');
  const ic = itemCatalogue.data.tolede;
  const commandableEnColis =
      ic.quantite_colis_entier !== undefined &&
      ic.quantite_colis_entier !== 0 &&
      ic.quantite_colis_entier !== 999999999 &&
      ic.quantite_colis_entier !== ic.multiple_de_commande_client;
  const isDisabled = !commandableEnColis && (!quantite_max || quantite_max < ic.quantite_colis_entier!);

  // Gérer son state, permet d'avoir la dropdown à jour pendant que par exemple la ligne de panier change
  const [unite, setUnite] = useState(initialValue ?? (commandableEnColis ? 'colis' : 'unitaire'));

  // TODO TODO: gérer la préférence client, quand on arrive et qu'on a rien de défini !?
  return (
    <Select 
      bg='gray.bg' 
      borderColor={'#CDCFD7'} 
      icon={!isDisabled ? <TriangleDownIcon /> : <></>} 
      iconColor='gray.dark' 
      iconSize='xs' 
      w={{base: 'inherit', md: '200px'}}
      fontSize={{base: 'sm', md: 'md'}} 
      fontWeight={{base: '500', md: 'normal'}} 
      _disabled={{opacity:1}}
      value={unite} 
      onChange={(event) => {
        setUnite(event.target.value as UniteQuantite);
        event.preventDefault();
        onSelect(event.target.value as UniteQuantite);
      }}
      isDisabled={isDisabled}
      {...props}
    >
      {ic.unite_de_commande_client === ic.unite_de_facturation ?
        // Donc ça c'est le cas le plus simple
        <option value='unitaire'>
          {t('commande.par_multiple', {
            quantite: ic.multiple_de_commande_client,
            unite: t(`unite.${ic.unite_de_facturation}`, {count: ic.multiple_de_commande_client}),
          })}&nbsp;
        </option>
        :
        // Maintenant les cas un peu plus compliqué
        <option value='unitaire'>
          { // Tu commandes par unite_de_commande_client
            t('commande.par_multiple', {
              quantite: ic.multiple_de_commande_client,
              unite: t(`unite.${ic.unite_de_commande_client}`, {count:ic.multiple_de_commande_client}),
            })}&nbsp;
          { // Et pour chaque unité tu es facturé une quantité correspondante
            t('commande.de_multiple_facturation', {
              multiple_facturation: toDecimalFr(
                ic.coefficient_commande_client_vers_facturation! * 
              ic.multiple_de_commande_client, 
                0, 2
              ),
              unite_facturation: t(`unite.${ic.unite_de_facturation}`, {count: (ic.coefficient_commande_client_vers_facturation ?? 1) * ic.multiple_de_commande_client})
            })}
        </option>
      }
      {!isDisabled && (
        <option value='colis'>
          {t('commande.par_colis', {
            quantite: ic.quantite_colis_entier,
            unite: t(`unite.${ic.unite_de_commande_client}`, {count: ic.quantite_colis_entier})
          })}
        </option>
      )}
    </Select>
  );
};
