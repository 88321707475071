/**
 * Contexte de gestion des livraisons possibles
 * Héberge le state de sélection de la livraison pour toute l'application
 */
import {useLivraisons} from '@/haligator-factory';
import {ChargementCompte} from '@/domains/auth/ChargementCompte';
import {HaliRenderMany} from '@synako/haligator';
import {FollowPromiseMany, State} from '@synako/halitrek';
import {LivraisonDesc} from 'api-types/ffconnect-hal';
import {createContext,Dispatch,ReactNode, SetStateAction, useEffect, useState} from 'react';

export interface LivraisonsPossiblesContextValue {
  livraisonSelectionnee: State<LivraisonDesc>, 
  setLivraisonSelectionnee: Dispatch<SetStateAction<State<LivraisonDesc>>>,
}

export const LivraisonsPossiblesContext = createContext<Partial<LivraisonsPossiblesContextValue>>({});

export const LivraisonsPossiblesContextProvider: React.FC<{
  livraisonsPossiblesStates: State<LivraisonDesc>[],
  children: ReactNode
}> = ({livraisonsPossiblesStates, children})=>{
  // initialisation du state avec la 1ère livraison possible
  const [
    livraisonSelectionnee, 
    setLivraisonSelectionnee
  ] = useState<State<LivraisonDesc>>(livraisonsPossiblesStates[0]);

  const livraisonActuelle = livraisonsPossiblesStates.find(livraison => livraison.resource === livraisonSelectionnee.resource);

  // permet de prendre en compte la mise à jour suite à un invalidate / refetch
  useEffect(() => {
    if (livraisonActuelle) {
      setLivraisonSelectionnee(livraisonActuelle);
    }
  }, [livraisonActuelle]);

  const contextValue: LivraisonsPossiblesContextValue = {
    livraisonSelectionnee, 
    setLivraisonSelectionnee,
  };

  return (
    <LivraisonsPossiblesContext.Provider value={contextValue}>
      {children}
    </LivraisonsPossiblesContext.Provider>
  );
};

export const LivraisonsPossibles: React.FC<{
  children: ReactNode
  livraisonsPossiblesPromise?: FollowPromiseMany<LivraisonDesc>
}> = ({
  children,
  livraisonsPossiblesPromise
}) => {
  const defaultPromise =  useLivraisons().followAll('items');

  return (
    <HaliRenderMany 
      many={livraisonsPossiblesPromise ?? defaultPromise} 
      skeleton={() => <ChargementCompte idMessage="chargement_tournees" />}
      render={({states}) => (
        <LivraisonsPossiblesContextProvider livraisonsPossiblesStates={states}>
          {children}
        </LivraisonsPossiblesContextProvider>
      )}
    />
  );
};
