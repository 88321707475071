import {Box, HStack} from '@chakra-ui/layout';
import {VStack} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';
import routes from '@/routes';
import {NewLivraisonButtonMobile} from '../components/NewLivraisonButtonMobile';
import {InfoLivraisonDesktop, InfoLivraisonMobile} from '../components/InfoLivraison';
import {useHalState, useHalStates} from '@synako/haligator';
import {State} from '@synako/halitrek';
import {LivraisonDesc} from 'api-types/ffconnect-hal';
import {CheckLoaded} from '@/containers/CheckLoaded';
import {PolygonDown} from '@/components/svg/PolygonDown';
import {useTranslation} from 'react-i18next';
import {useLivraisonsPossiblesContext} from '@/contexts/useContexts/useLivraisonsPossiblesContext';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {HeaderPanierListe} from './HeaderPanierListe';
import {useLivraisons} from '@/haligator-factory';

export const PanierListeLivraison: React.FC 
= () => {
  const {t} = useTranslation('common');

  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();
  const livraisonsPossibles = useHalStates(useLivraisons().followAll('items'));
  if (!livraisonsPossibles.states || livraisonsPossibles.error) {
    return;
  }

  const livraisonsAvecPanier = livraisonsPossibles.states.filter(livraison => livraison.hasOneLink('panier_ouvert'));

  return (
    <>
      <HeaderPanierListe nbPaniers={livraisonsAvecPanier.length}/>
      {livraisonsAvecPanier.length > 0 && (
        <Box p='2' pb='3' w='100%'>
          <Box overflowX='auto' width='100%' display={{'base':'none', 'lg':'block'}}>
            <PanierListeLivraisonDesktop livraisons={livraisonsAvecPanier}/>
          </Box>
          <Box overflowX='auto' width='100%' display={{'base':'block', 'lg':'none'}}>
            <PanierListeLivraisonMobile livraisons={livraisonsAvecPanier}/>
          </Box>
          {!livraisonSelectionnee.hasOneLink('panier_ouvert') &&
            <Box m='1' position='absolute' zIndex='dropdown'>
              <VStack ml='3' alignItems='start' mb='-1px' h='14px' transform='scaleY(-1)'><PolygonDown color='blue.main'/></VStack>
              <Box w='fit-content' bg='blue.main'color='white' p='3' borderRadius='10px'>{t('panier.panier_vide_cta_top')}</Box>
            </Box>
          }
        </Box>)
      }
    </>
  );
};

const PanierListeLivraisonMobile: React.FC<{livraisons:State<LivraisonDesc>[]}> = ({livraisons}) => {
  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();

  return (<HStack justifyItems='stretch' w='max-content'>
    {livraisons.map((livraisonState)=>{
      return <PanierItemLivraisonMobile key={livraisonState.data.id} livraisonState={livraisonState} isActive={livraisonState.resource.uri==livraisonSelectionnee.uri}/>;
    })}
    {/* <InfoLivraisonMobile date={'lundi 10 mars'} nb_article={1} active={false} /> */}
    <NewLivraisonButtonMobile />
  </HStack>);
};
const PanierItemLivraisonMobile:React.FC<{livraisonState:State<LivraisonDesc>, isActive:boolean}> = ({livraisonState, isActive})=>{
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const navigate = useNavigate();

  const panierHook = useHalState(livraisonState.follow('panier_ouvert'));

  return <CheckLoaded hook={panierHook} render={({data})=>{
    return <InfoLivraisonMobile onClick={()=>{
      const {date_de_livraison} = livraisonState.data.tolede;
      navigate(buildAssistanceRoute(routes.client.panier_redirect, {date_de_livraison}));
    }}
    date_de_livraison={livraisonState.data.tolede.date_de_livraison}
    nb_article={data.nombre_de_lignes} active={isActive} />;
  }}/>;
};

const PanierListeLivraisonDesktop: React.FC<{livraisons:State<LivraisonDesc>[]}> = ({livraisons}) => {
  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();

  return (<HStack>
    {livraisons.map((livraisonState)=>{
      return <PanierItemLivraisonDesktop key={livraisonState.data.id} livraisonState={livraisonState} isActive={livraisonState.resource.uri==livraisonSelectionnee.uri}/>;
    })}
  </HStack>);
};
const PanierItemLivraisonDesktop:React.FC<{livraisonState:State<LivraisonDesc>, isActive:boolean}> = ({livraisonState, isActive})=>{
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const navigate = useNavigate();

  const panierHook = useHalState(livraisonState.follow('panier_ouvert'));
  const {date_de_livraison} = livraisonState.data.tolede;

  return <CheckLoaded hook={panierHook} render={({data})=>{
    return <InfoLivraisonDesktop onClick={()=>{
      navigate(buildAssistanceRoute(routes.client.panier_redirect, {date_de_livraison}));
    }}
    date_de_livraison={date_de_livraison}
    nb_article={data.nombre_de_lignes} active={isActive} />;
  }}/>;
};
