import React, {useMemo, useState} from 'react';
import {Box, Button, HStack, IconButton, Text, Heading, Spacer, Stack, useRadioGroup} from '@chakra-ui/react';
import {DownloadIcon} from '@chakra-ui/icons';
import {useTranslation} from 'react-i18next';
import {FactureIcon} from '@/components/svg/FactureIcon';
import {useClient} from '@/haligator-factory';
import {useHalStates} from '@synako/haligator';
import * as tolede from 'api-types/tolede';
import {dateAvecSlashes} from '@/utils/date-tools';
import {DownloadLink} from '../../components/DownloadLink';
import DataTable from '@/components/DataTable';
import {createColumnHelper} from '@tanstack/react-table';
import {State} from '@synako/halitrek';
import {BonDeLivraisonDesc} from 'api-types/ffconnect-hal';
import {RadioCard} from '@/chakra/components/Radio/RadioCard';

export const BonLivraisonView: React.FC = () => {
  const {t} = useTranslation('common');
  const [periode, setPeriode] = useState<tolede.PeriodeBonDeLivraison>('2_derniers_mois');
  const {loading, refreshing, states} = useHalStates(useClient().follow('bons_de_livraison', {periode}).followAll('items'));

  const periods = [
    {value: '2_derniers_mois', label: t('bon_de_livraison.2_derniers_mois')},
    {value: '3_6_mois', label: t('bon_de_livraison.3_6_mois')},
  ];

  const {getRootProps, getRadioProps} = useRadioGroup({
    name: 'periode',
    defaultValue: periode,
    onChange: (value) => {setPeriode(value as tolede.PeriodeBonDeLivraison);},
  });

  const group = getRootProps();

  const columnHelper = createColumnHelper<State<BonDeLivraisonDesc>>();

  const columns = useMemo(() => [
    columnHelper.accessor('data.tolede', {
      header: t('bon_de_livraison.bon_de_livraison_titre'),
      cell: (ctx) => (
        <HStack justifyContent="start">
          <FactureIcon color='black' width='24px' height='29px' />
          <Text>{ctx.getValue().numero}</Text>
        </HStack>
      ),
      meta: {
        tdProps: {fontWeight: '500', textAlign: 'left', py: 3},
      },
    }),
    columnHelper.accessor('data.tolede', {
      header: t('bon_de_livraison.commande'),
      cell: (ctx) => `${t('bon_de_livraison.N°')}${ctx.getValue().numero}`,
      meta: {
        tdProps: {textAlign: 'left', py: 3},
      },
    }),
    columnHelper.accessor('data.tolede.emis_le', {
      header: t('bon_de_livraison.date_de_commande'),
      cell: (ctx) => dateAvecSlashes(ctx.getValue()),
      meta: {
        tdProps: {textAlign: 'left', py: 3},
      },
    }),
    columnHelper.accessor('data.tolede.montant_ttc', {
      header: t('bon_de_livraison.total_de_commande'),
      cell: (ctx) => `${ctx.getValue()} €`,
      meta: {
        tdProps: {textAlign: 'left', fontWeight: '700', py: 3},
      },
    }),
    columnHelper.accessor((row) => row, {
      id: 'actions',
      header: t('bon_de_livraison.action'),
      cell: (ctx) => (
        <DownloadLink state={ctx.getValue()}>
          {/* en mobile */}
          <Button
            leftIcon={<DownloadIcon />}
            variant="ghost"
            borderWidth="1px"
            color="black.text"
            fontSize="xs"
            fontWeight="600"
            w='100%'
            display={{base: 'flex', lg: 'none'}}
            cursor='pointer'
          >
            {t('bon_de_livraison.telecharger')}
          </Button>
          {/* en desktop */}
          <IconButton
            icon={<DownloadIcon />}
            aria-label={t('bon_de_livraison.telecharger')}
            variant="ghost"
            borderWidth="1px"
            color="black.text"
            display={{base: 'none', lg: 'flex'}}
            cursor='pointer'
          />
        </DownloadLink>
      ),
      meta: {
        tdProps: {width: {base: '100%', lg: '10%'}, textAlign: 'left', py: 3},
      },
    }),
  ], [t, columnHelper]);

  const isLoading = loading || refreshing;

  return (
    <HStack alignItems="start" bg="gray.bg" overflowX="hidden" width="100%">
      <Box p={5} mr={{base:'0px', md:'20px'}} ml={{base:'0px', md:'20px'}} width="100%">
        <Heading size={{base:'md', md:'lg'}} ml={{base:'30px', md:'0'}} mb={6} mt="10px" color="black.text">
          {t('menu.bons_de_livraison')}
        </Heading>
        <Box bg='white' p={{base: 4, md: 6}} borderRadius="8px" boxShadow="sm" w="100%">
          <Stack direction={{base:'column', lg:'row'}} mb={4} width="100%">
            <HStack {...group} spacing={2}>
              {periods.map((period) => {
                const radio = getRadioProps({value: period.value});
                return (
                  <RadioCard key={period.value} {...radio} loading={isLoading}>
                    {period.label}
                  </RadioCard>
                );
              })}
            </HStack>
            <Spacer/>
          </Stack>

          {!isLoading && states && states.length > 0 && (
            <Text mb={4} fontSize={{base: 'sm', md: 'md'}} fontWeight="400" color="gray.dark">
              {t('bon_de_livraison.bon_de_livraison', {count: states.length})}
            </Text>
          )}

          <DataTable
            columns={columns}
            data={states ?? []}
            isLoading={isLoading}
            empty={
              <Text fontSize={{base: 'sm', md: 'md'}} textAlign='center' fontWeight="400" color="gray.dark" my={7} mt="50px">
                {t('bon_de_livraison.aucun_bon_de_livraison')}
              </Text>
            }
          />
        </Box>
      </Box>
    </HStack>
  );
};

export default BonLivraisonView;
