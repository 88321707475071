import {Box, Button, Heading, Text, VStack, HStack, Avatar, Stack} from '@chakra-ui/react';
import {ContactCommercialIcon} from '@/components/svg/ContactCommercialIcon.tsx';
import {RightSingleArrow} from '@/components/svg/RightSingleArrowIcon.tsx';
import {useState} from 'react';
import {CloseIcon} from '@/components/svg/CloseIcon.tsx';
import {MailIcon} from '@/components/svg/MailIcon.tsx';
import {PhoneBlackIcon} from '@/components/svg/PhoneBlackIcon.tsx';
import {useClient, useLivraisons, useMe, useRepresentants} from '@/haligator-factory.tsx';
import {useTranslation} from 'react-i18next';
import {Addressesclient} from './informations/components/AdressesClient';
import {HaliRenderOne, useHalCollection, useHalStates} from '@synako/haligator';

export const ProfilView: React.FC = () => {
  const [isContactVisible, setIsContactVisible] = useState(false);
  const client = useClient();
  const {t} = useTranslation('common');
  
  const adressesLivraison = client.follow('adresses_de_livraison').followAll('items');
  const adresseFacturation = client.follow('adresse_de_facturation');
  const tournees = client.follow('tournees').followAll('items');

  const representantsHook = useHalCollection(useRepresentants());
  const representantCommercial = representantsHook.items?.find(state => state.data.tolede.en_tant_que == 'commercial');

  const handleContactClick = () => {
    setIsContactVisible(!isContactVisible);
  };

  const contactPromise = useMe().follow('contact');

  const livraisonsPossibles = useHalStates(useLivraisons().followAll('items'));
  if (!livraisonsPossibles.states || livraisonsPossibles.error) {
    return;
  }

  return (<Box display="flex" bg="gray.bg" overflowX="hidden" width="100%"> {/* globale */}
    {/* Version lg */}
    <Box display={{base:'none', lg:'block'}} width={{base:'104px', xl:'450px'}} position="absolute"
      top="17px" right={{base:'50px', xl:'85px'}} bg="white" p={3} 
      borderWidth="1px" borderRadius="lg" boxShadow="md" cursor="pointer" 
      onClick={handleContactClick}>
      <HStack paddingRight="10px">
        <Box padding="10px" paddingTop="12px" paddingBottom="12px" bg='blue.main_transparency_10' borderRadius="8px">
          <ContactCommercialIcon/>
        </Box>
        {/* Version xl */}
        <VStack display={{base:'none', xl:'block'}} align="start" mr="10px" ml="10px" spacing="0px">
          <Text fontWeight='700' color="blue.main">{t('mes_informations.modification_alerte')}</Text>
          <Text variant="link" color="blue.main" textDecoration="underline" >{t('mes_informations.contacter_commercial')}</Text>
        </VStack>
        <RightSingleArrow />
      </HStack>
    </Box>
    {isContactVisible && (
      <Box width="450px" position="absolute" top="100px" right={{base:'50px', xl:'85px'}} bg="gray.bg" p={4} borderWidth="1px" borderRadius="lg" boxShadow="md">
        <VStack>
          <HStack width="100%" justifyContent="space-between">
            <Text fontWeight='700' color="blue.main" padding="10px">{t('mes_informations.votre_commercial')}</Text>
            <Button rightIcon={<CloseIcon />} variant="ghost" _hover={{bg: 'gray.bg'}} onClick={handleContactClick}></Button>
          </HStack>
          <Box bg="white" borderWidth="1px" borderRadius="lg" p={4} pr={8} pl={8} mb={1} width="100%">
            <HStack justifyContent="center">
              <Avatar name={`${representantCommercial?.data.tolede.individu.prenom} ${representantCommercial?.data.tolede.individu.nom}`} mr={4} />
              <VStack align="start" spacing={3} mb={1}>
                <Text fontWeight='600' color="black.text">{representantCommercial?.data.tolede.individu.prenom} {representantCommercial?.data.tolede.individu.nom}</Text>
                <HStack spacing={4}>
                  <Button leftIcon={<MailIcon />} fontWeight='600' fontSize="xs" color="black.text" 
                    onClick={() => window.location.href = `mailto:${representantCommercial?.data.tolede.individu.email}`}
                    isDisabled={!representantCommercial?.data.tolede.individu.email}>
                    {t('mes_informations.par_mail')}
                  </Button>
                  <Button leftIcon={<PhoneBlackIcon />} fontWeight='600' fontSize="xs" color="black.text"
                    isDisabled={!representantCommercial?.data.tolede.individu.telephone}>
                    {representantCommercial?.data.tolede.individu.telephone ?? 'Non renseigné'}
                  </Button>
                </HStack>
              </VStack>
            </HStack>
          </Box>
        </VStack>
      </Box>
    )}

    <Box p={5} mr={{base:'0px', md:'20px', xl:'40px', '2xl':'65px'}} ml={{base:'0px', md:'20px', xl:'40px', '2xl':'65px'}} width="100%">
      <Heading size={{base:'md', md:'lg'}} ml={{base:'30px', md:'0'}} mb={12} mt="10px" color="black.text">{t('menu.informations')}</Heading>
      <Box width="100%">
        <Stack direction={{base:'column', lg:'row'}} spacing={8} align="stretch" mb={10}>
          <Box flex={1} p={5} borderWidth="1px" borderRadius="lg" bg='white' padding="35px">
            <Heading fontWeight='700' size="md" textColor='blue.main' mt="10px" mb="30px">{t('mes_informations.mes_adresses')}</Heading>
            <Addressesclient
              adresseFacturation={adresseFacturation}
              adressesLivraison={adressesLivraison}
              tournees={tournees}
              prochaineLivraison={livraisonsPossibles.states[0]}
            />

          </Box>
          <Box flex={1} p={5} borderWidth="1px" borderRadius="lg" bg='white' padding="35px">
            <Heading fontWeight='700' size="md" textColor='blue.main' mt="10px" mb="30px">{t('mes_informations.mon_identite')}</Heading>
            <Text fontWeight='600' color="black.text" mb="10px">{t('mes_informations.mon_entreprise')}</Text>
            <HaliRenderOne
              nav={client}
              render={({data}) =>
                <Box p={2} borderWidth="1px" borderRadius="lg" mb="30px" padding="20px">
                  <Text mb={4}>{data.tolede.raison_sociale}</Text>
                  <Text>{t('mes_informations.numero_de_compte')} {data.tolede.code_client}</Text>
                  <Text>{t('mes_informations.siret')} {data.tolede.siret}</Text>
                  <Text>{t('mes_informations.tva')} {data.tolede.tva_intracommunautaire}</Text>
                </Box>
              }/>
            <Text fontWeight='600' color="black.text" mb="10px">{t('mes_informations.mes_coordonnees')}</Text>
            <HaliRenderOne
              nav={contactPromise}
              fallback={() => <Text>{ }</Text>}
              render={({data}) =>
                <Box p={2} borderWidth="1px" borderRadius="lg" mb="30px" padding="20px">
                  <Text color="blue.main" fontWeight="700" mb={4}> {data.tolede.prenom} {data.tolede.nom} </Text>
                  <Text mb={4}>{data.tolede.telephone} </Text>
                  <Text> {data.tolede.email} </Text>
                </Box>
              }/>
          </Box>
        </Stack>
      </Box>
    </Box>
  </Box>
  );
};

export default ProfilView;
