import {Box, HStack, Spacer, VStack} from '@chakra-ui/react';
import {LignePanierRemplacementPicto} from '@/domains/ligneProduit/LignePanierRemplacementPicto';
import {ArticleBlock} from '../ArticleBlock';
import {PrixBlock} from '../PrixBlock';
import {useTranslation} from 'react-i18next';
import {DeleteBlock} from '../DeleteBlock';
import {useLigneDePanierCommands} from '@/hooks/ligneDePanier/use-ligne-de-panier-commands';
import {useContext} from 'react';
import {useHalState} from '@synako/haligator';
import {PoidsVariableTooltip} from './PoidsVariableTooltip';
import {useBuyingBlocks} from '@/hooks/panier/useBuyingBlocks';
import {LigneProduitLayout} from '@/domains/liste/components/LigneProduitLayout';
import {BreakPointContext} from '@/chakra/BreakpointProvider';
import {LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {State} from '@synako/halitrek';
import {LigneProblem} from 'api-types/ffconnect';
import {Warning} from './Warning';

export const ProduitPanierAffiche: React.FC<{
  ligneDePanier: State<LigneDePanierDesc>,
  idError: string,
  probleme: LigneProblem | undefined,
}> = ({
  ligneDePanier,
  idError,
  probleme,
}) => {
  const {t} = useTranslation('common');
  const isMobile = useContext(BreakPointContext)?.isMobile;

  const remplacement_de = ligneDePanier.data.remplacement_de;
  // Gestion à un seul endroit des commandes et donc du somethingSubmitting
  const commands = useLigneDePanierCommands(ligneDePanier);
  const {
    somethingSubmitting,
    supprimer,
  } = commands;

  const articleBlock = <ArticleBlock
    code_article={ligneDePanier.data.code_article}
    article={ligneDePanier.follow('article')}
    dispo={ligneDePanier.follow('disponibilite_article')}
  />;
  const prixBlock = <PrixBlock
    refreshing={somethingSubmitting}
    ligneDePanier={ligneDePanier}
  />;
  
  const article = useHalState(ligneDePanier.follow('article'));
  const item = useHalState(ligneDePanier.follow(('item')));
  const {uniteQuantiteBlock, buyBlock} = useBuyingBlocks(item.state, ligneDePanier);
  const selectBlock = <Box 
    mt='15px'
    ml={{base: 0, md: 'auto'}}
    minW='fit-content'
  >
    {uniteQuantiteBlock({minW:'245px', w:'245px'})}
    {article.data?.tolede.a_peser && <PoidsVariableTooltip/>}
  </Box>;
  
  const warning = probleme && 
    <Warning
      idError={idError}
      ligneDePanier={ligneDePanier}
      probleme={probleme}
      commands={commands}
    />;
  return <LigneProduitLayout
    flexDirection='column'
    // opacity={refreshing ? 0.5 : 1}
  >

    <DeleteBlock onClick={ () => {supprimer.submit({});}}/>

    {remplacement_de && (
      <LignePanierRemplacementPicto remplacement_de={remplacement_de} t={t} />
    )}

    {!isMobile ?
    // Desktop
      <HStack 
        width='100%' 
        alignItems='start' 
        justify='end' 
        flexWrap='wrap' 
        gap='0' pt="0px" 
        flex='1' 
      >
        {articleBlock}
        <Spacer/>
        {prixBlock}
        <HStack justifyContent='end' alignItems='start' justifySelf='end' w="100%">
          {selectBlock}
          {buyBlock({somethingSubmitting})}
        </HStack>
        {warning}
      </HStack>

    // Mobile
      : <VStack
        display={{base: 'block', md: 'none'}}
        width='100%' 
        alignItems='start' 
        justify='end'
      >
        {articleBlock}
        <Spacer />
        <HStack justify='space-between'>
          {selectBlock}
          {prixBlock}
        </HStack>
        {buyBlock({somethingSubmitting})}
        {warning}
      </VStack>} 

  </LigneProduitLayout>;
};
