import {HStack, VStack, Text} from '@chakra-ui/layout';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {toDecimalFr} from '@/utils/toDecimalFr';
import {Unite} from 'api-types/ffconnect';

//Utilisé dans le cadencier, où on veut mettre en avant le prix unitaire
export const PrixBlockUnitaire: React.FC<{
  prixUnitaire: number;
  uniteFacturation: Unite | undefined;
  tauxTVA: number | undefined;
}> = ({prixUnitaire, uniteFacturation, tauxTVA}) => {
  const {t} = useTranslation('common');
  return <VStack minW='9em' alignItems='start' gap='0' mt='15px'>
    <HStack gap='0.5' alignItems='start'>
      <Text textColor='black.text' fontSize='xl' fontWeight='700'>{toDecimalFr(prixUnitaire, 2, 3)}</Text>;
      <Text fontSize='xs'>€</Text>
      {uniteFacturation && <Text fontSize='xs'>{t('ligne_produit.HT')}/{t(`unite.${uniteFacturation}`, {count: 1})}</Text>}
    </HStack>
    {tauxTVA && <Text fontSize='xs' color='gray.dark'>
      {t('ligne_produit.tva_percent', {tauxTVA})}
    </Text>}
  </VStack>;
};
