import {SwitchIcon} from '@/components/svg/SwitchIcon';
import {ErrorButton} from './ErrorButton';
import NiceModal from '@ebay/nice-modal-react';
import {ModaleRemplacementCatalogue} from '../../components/ModaleRemplacementCatalogue';
import {useTranslation} from 'react-i18next';
import {LigneDePanierCommands} from '@/hooks/ligneDePanier/use-ligne-de-panier-commands';
import {State} from '@synako/halitrek';
import {LigneDePanierDesc} from 'api-types/ffconnect-hal';

export const RemplacerErrorButton: React.FC<{
  // probleme: LigneProblem,
  ligneDePanier: State<LigneDePanierDesc>,
  commands: LigneDePanierCommands,
}> = ({
  ligneDePanier,
  commands,
}) => {
  const {t} = useTranslation('common');
  const {somethingSubmitting, remplacer} = commands;
  const onClick = () => {
    ligneDePanier.follow('article').get().then((article) => {
      NiceModal.show(ModaleRemplacementCatalogue, {
        articleData: article.data, 
        remplacerAction: remplacer.submit, 
      });
    });
  };
  return (
    <>
      <ErrorButton
        display={{base: 'none', md: 'block'}}
        leftIcon={<SwitchIcon color='blue.main'/>}
        flex='0.4' 
        minW='150px'
        libelle={t('ligne_produit.indisponible.remplacer')}
        refreshing={somethingSubmitting}
        onClick={onClick}
      />
      <ErrorButton
        flex='1'
        display={{base: 'flex', md: 'none'}}
        leftIcon={<SwitchIcon color='blue.main'/>}
        minW="150px"
        maxW="220px"
        libelle={t('ligne_produit.indisponible.remplacer')}
        refreshing={somethingSubmitting}
        onClick={onClick}
      />
    </>
  );  
};
