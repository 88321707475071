import {ChangeEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {Panier} from 'api-types/ffconnect';
import {UseHalStateHook} from '@synako/haligator';
import {PanierValiderOptionsDesc} from 'api-types/ffconnect-hal';

import {InfoIcon} from '@chakra-ui/icons';
import {HStack, VStack, Text} from '@chakra-ui/layout';
import {Divider, Input, Tooltip} from '@chakra-ui/react';

import {toDecimalFr} from '@/utils/toDecimalFr';
import {PanierCommands} from '@/hooks/panier/use-panier-commands';
import {useDebouncedCallback} from '@/hooks/useDebouncedCallback';

import {PanierRecapDate} from './PanierRecapDate';
import {PanierRecapAdresse} from './PanierRecapAdresse';
import {PanierRecapValider} from './PanierRecapValider';

export const PanierRecapitulatif: React.FC<{
  panier: Panier,
  panierWithCommands: PanierCommands,
  validerOptions: UseHalStateHook<PanierValiderOptionsDesc>
}> = ({
  panier,
  panierWithCommands,
  validerOptions,
}) => {
  // const panierData = panierWithCommands.panier.data;
  const {t} = useTranslation('common');

  const onReferenceChange = useDebouncedCallback((event: ChangeEvent<HTMLInputElement>) => {
    panierWithCommands.changerReference.submit({
      reference: event.target.value
    });
  });

  return (<VStack
    bg='white'
    p={{base: '5', lg: '5', xl: '10'}}
    pt={{base: '10', lg: '10'}}
    gap='3'
    borderRadius='8px'
    alignItems='stretch'
    position='sticky'
    top='0'
    textColor='gray.dark'
    fontSize='md'
    flexDirection={{base: 'column-reverse', lg: 'column'}}
    // mb='10'
  >
    <Text display={{base: 'none', lg: 'block'}}
      fontWeight='bold' textColor='black' fontSize='xl' alignSelf='center' borderRadius='8px' mb='5'>
      {t('panier.recap.recapitulatif')}
    </Text>
    <VStack gap='3' alignItems='stretch'>
      <HStack w='100%' justify='start'>
        <Text>{t('panier.recap.total_ht')}</Text>
        <Tooltip label={t('panier.recap.tooltip_total_ht')}><InfoIcon /></Tooltip>
        <Text ml='auto'>{toDecimalFr(panier.total_estime_ht)}&nbsp;€</Text>
      </HStack>

      <HStack w='100%' justify='start'>
        <Text>{t('panier.recap.total_tva')}</Text>
        <Text ml='auto'>{toDecimalFr(panier.total_estime_tva)}&nbsp;€</Text></HStack>
      <HStack w='100%' justify='start' textColor='black'>
        <Text fontWeight='600'>{t('panier.recap.total_ttc')}</Text><Text ml='auto' fontWeight='700'>{toDecimalFr(panier.total_estime_ttc)}&nbsp;€</Text>
      </HStack>
    </VStack>
    <PanierRecapValider
      validerCommand={panierWithCommands.valider} 
      validerOptions={validerOptions}
    />;
    <Divider m='3' />

    <VStack gap='3' alignItems='stretch'>
      <Text>{t('panier.recap.reference_commande')}</Text>
      <Input defaultValue={panier.reference} onChange={onReferenceChange} mb='5' />
    </VStack>;
    
    <Divider m='3' display={{base: 'block', lg: 'none'}} />
    <VStack gap='3' alignItems='stretch'>
      <PanierRecapDate
        date_de_livraison_ou_d_enlevement={panier.livraison_tolede.date_de_livraison}
        changerDateCommand={panierWithCommands.changerDateDeLivraison}
      />
      {panier.livraison_tolede.code_adresse_de_livraison && 
        <PanierRecapAdresse
          panier={panier}
          panierWithCommands={panierWithCommands}
        />}
    </VStack>
  </VStack>);
};
