import {useHalStates} from '@synako/haligator';
import {FollowPromiseMany} from '@synako/halitrek';
import {LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {useMemo} from 'react';
// import {useLivraisonContext} from '@/contexts/useContexts/useLivraisonContext';

export const useLignePourArticle = (code_article: string, lignesDePanierPromise: FollowPromiseMany<LigneDePanierDesc>|undefined) => {
  // const {lignesPanierActuellesHook:{states}} = useLivraisonContext();
  const lignesDePanier = useHalStates(lignesDePanierPromise);
  const ligne = useMemo(() => {
    return lignesDePanier.states?.find((l) => l.data.code_article == code_article);
  }, [code_article, lignesDePanier.states]);

  return ligne;
};
