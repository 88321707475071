import {LOGO_FF_COULEUR_URL} from '@/domains/mediatheque/logoFFCouleurUrl';
import {AssistanceClientBanniere} from '@/backoffice/assistance/AssistanceClientBanniere';
import {Avatar, HStack, Image, Text, VStack} from '@chakra-ui/react';
import {State} from '@synako/halitrek';
import {RepresentantDesc} from 'api-types/ffconnect-hal';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {ChangerClientButton} from './ChangerClientButton';
import {useMe} from '@/haligator-factory';
import {HaliRenderOne} from '@synako/haligator';
import {imageOptimisee} from '../mediatheque/optimisation';

export const CompteBloque: React.FC<{commerciale?: State<RepresentantDesc> | undefined}> = ({commerciale}) => {
  const {t} = useTranslation('common');
  const {id_client_assistance} = useParams<{id_client_assistance?: string}>();
  const prenomNomCommmercial = `${commerciale?.data.tolede.individu.prenom} ${commerciale?.data.tolede.individu.nom}`;

  return(
    <>
      {id_client_assistance && <AssistanceClientBanniere />}
      <VStack bg='white' minHeight='100vh' width='100%' justify='center' align='center' spacing={8}>
        <Image 
          src={imageOptimisee(LOGO_FF_COULEUR_URL, 300)}
          alt={t('global.alt_logo_france_frais')}
          maxWidth="300px"
          mb={2}
        />
        <Text>{t('accueil.compte_bloque')}</Text>
        {commerciale &&
      <>
        <HStack alignItems="center" spacing={4}>
          <Avatar name={prenomNomCommmercial} src='https://bit.ly/broken-link' />
          <VStack align="flex-start" spacing={1}>
            <Text>{commerciale.data.tolede.individu.prenom} {commerciale.data.tolede.individu.nom}</Text>
            <Text>{commerciale.data.tolede.individu.telephone}</Text>
            <Text>{commerciale.data.tolede.individu.email}</Text>
          </VStack>
        </HStack>
      </>
        }
        <HaliRenderOne 
          nav={useMe().follow('switch')} 
          render={({data}) => {
            if (data.items.length > 1) {
              return <ChangerClientButton />;
            }
            return <></>;
          }}
          fallback={() => <></>}
        />
      </VStack>
    </>
    
  );
};
