import {VStack} from '@chakra-ui/react';
import {useVignetteArticle} from './use-vignette';
import {placeholderImage, imageOptimisee} from './optimisation';
import {Navigable} from '@synako/halitrek';
import {ArticleDesc} from 'api-types/ffconnect-hal';

export const VignetteArticleVStack : React.FC<{
  article: Navigable<ArticleDesc>|undefined,
  boxSize?: string,
}> =
({
  article,
  boxSize,
}) => {
  if (!article) {
    return <InnerVignetteArticleVStack imageUrl={placeholderImage} boxSize={boxSize} />;
  }
  return <RealVignetteArticleVStack article={article} boxSize={boxSize}/>;
};

export const RealVignetteArticleVStack : React.FC<{
  article: Navigable<ArticleDesc>,
  boxSize?: string,
}> =
({
  article,
  ...imgProps
}) => {
  const img = useVignetteArticle(article);
  const imageUrl = imageOptimisee(img ?? placeholderImage, 70);
  return <InnerVignetteArticleVStack imageUrl={imageUrl} {...imgProps}/>;
};

export const InnerVignetteArticleVStack: React.FC<{
  imageUrl: string,
  boxSize?: string
}> = ({
  imageUrl, 
  boxSize='70px'
}) => {
  return (
    <VStack
      justifyContent='center' 
      bg='transparent' 
      minH={boxSize} 
      minW={boxSize} 
      alignSelf='baseline' 
      bgImage={imageUrl} 
      bgPos='center' 
      bgSize='contain' 
      bgRepeat='no-repeat'
    />
  );
};