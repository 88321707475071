import {Box, HStack, Heading, Switch, VStack, Text, Stack} from '@chakra-ui/react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

export const SettingsView: React.FC = () => {
  const {t} = useTranslation('common');

  //mettre les switchs dans un tableau et les set à false au début
  const [allChecked, setAllChecked] = useState(false);
  const [switches, setSwitches] = useState<Record<string, boolean>>({
    veilleCutOff: false,
    deuxHeuresCutOff: false,
    auCutOff: false,
  });

  //switch global pour tout activer
  const handleAllChecked = () => {
    const newCheckedState = !allChecked; //inverse l'état actuel pour permettre de tout désactiver
    setAllChecked(newCheckedState);
    setSwitches({
      veilleCutOff: newCheckedState,
      deuxHeuresCutOff: newCheckedState,
      auCutOff: newCheckedState,
    });
  };

  const handleIndividualSwitch = (name: string | number) => {
    const newSwitches = {...switches, [name]: !switches[name]};
    setSwitches(newSwitches);
    // Si tous les switchs individuels sont activés, active "Tout activer"
    const allActivated = Object.values(newSwitches).every(v => v);
    setAllChecked(allActivated);
  };

  return (
    <Box display="flex" bg="gray.bg" overflowX="hidden" width="100%"> {/* globale */}
      <Box
        p={5}
        mr={{base: '0px', md: '30px', xl:'40px', '2xl':'65px'}}
        ml={{base: '0px', md: '30px', xl:'40px', '2xl':'65px'}}
        width="100%"
        maxWidth="1000px" // Définir une largeur maximale
        overflow="hidden" // Gérer le débordement
      >
        <Heading size={{base:'md', md:'lg'}} ml={{base:'30px', md:'0'}} mb={6} mt="10px" color="black.text">{t('menu.preferences')}</Heading>
        <Box p={8} borderWidth="1px" borderRadius="lg" bg="white" height="auto" width="100%">
          <Stack direction={{base: 'column', lg: 'row'}} justifyContent="flex-start" mb={10} spacing={5}>
            <Heading fontSize={{base:'md', md:'lg'}} fontWeight="700">Mes alertes commandes</Heading>
            <HStack alignItems="center" >
              <Switch
                colorScheme='green'
                id='tout-activer'
                isChecked={allChecked}
                onChange={handleAllChecked} />
              <Text>Tout activer</Text>
            </HStack>
          </Stack>
          <HStack align="start" spacing={8} width="100%" pr={0}>
            <VStack spacing={5}>
              <Text>La veille du cut off</Text>
              <Text>2h avant le cut off</Text>
              <Text>Au cut off</Text>
            </VStack>
            <VStack spacing={6}>
              <Switch
                colorScheme='green'
                isChecked={switches['veilleCutOff']}
                onChange={() => {handleIndividualSwitch('veilleCutOff');}}
              />
              <Switch
                colorScheme='green'
                isChecked={switches['deuxHeuresCutOff']}
                onChange={() => {handleIndividualSwitch('deuxHeuresCutOff');}}
              />
              <Switch
                colorScheme='green'
                isChecked={switches['auCutOff']}
                onChange={() => {handleIndividualSwitch('auCutOff');}}
              />
            </VStack>
          </HStack>
          {/* <VStack align="start" spacing={8} width="100%" pr={0} bg="red">
            <HStack justifyContent="flex-start" width="100%" spacing={5} bg="blue">
            <Text>La veille du cut off</Text>
              <Switch
                colorScheme='green'
                isChecked={switches['veilleCutOff']}
                onChange={() => {handleIndividualSwitch('veilleCutOff');}}
              />
            </HStack>
            <HStack justifyContent="flex-start" width="100%" spacing={5}>
              <Text>2h avant le cut off</Text>
              <Switch
                colorScheme='green'
                isChecked={switches['deuxHeuresCutOff']}
                onChange={() => {handleIndividualSwitch('deuxHeuresCutOff');}}
              />
            </HStack>
            <HStack justifyContent="flex-start" width="100%" spacing={5}>
              <Text>Au cut off</Text>
              <Switch
                colorScheme='green'
                isChecked={switches['auCutOff']}
                onChange={() => {handleIndividualSwitch('auCutOff');}}
              />
            </HStack>
          </VStack> */}
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsView;
