import {PanierFound} from './PanierFound';
import {PanierNotFound} from './PanierNotFound';
import {useLivraisonsPossiblesContext} from '@/contexts/useContexts/useLivraisonsPossiblesContext';

export const PanierView: React.FC = () => {  
  const derniereAction = new URLSearchParams(location.search).get('derniereAction');

  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();
  if (livraisonSelectionnee.hasOneLink('panier_ouvert')) {
    return <PanierFound panier={livraisonSelectionnee.follow('panier_ouvert')} />;
  }
  return <PanierNotFound derniereAction={derniereAction} />;
};
