import {BoxProps, Input, InputGroup, InputLeftElement, Box, Text, VStack, Stack, Image, HStack, useDisclosure, Button, InputRightAddon} from '@chakra-ui/react';
import {SearchIcon} from '../../svg/SearchIcon';
import {SEARCH_MIN_CHARS, useSearchClient} from '@/hooks/catalogue/use-search';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import routes from '@/routes';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {Result} from '@orama/orama';
import {HitItemCatalogue} from 'api-types/ffconnect';
import {CartIcon} from '@/components/svg/CartIcon';
import {useHalState} from '@synako/haligator';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {useTranslation} from 'react-i18next';
import {State} from '@synako/halitrek';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {useClient} from '@/haligator-factory';
import {useLivraisonsPossiblesContext} from '@/contexts/useContexts/useLivraisonsPossiblesContext';
import {imageOptimisee} from '@/domains/mediatheque/optimisation';
import {ModaleLayout} from '@/components/Modale/ModaleLayout';
import {debounce} from 'lodash-es';
import ColdIcon from '@/components/svg/ColdIcon';
import {BreakPointContext} from '@/chakra/BreakpointProvider';
import {useAjouterAuPanier} from '@/hooks/livraison/use-ajouter-au-panier';
import NiceModal from '@ebay/nice-modal-react';
import {CatalogueModale} from '@/domains/article/NomenclatureModale/CatalogueModale';

const SearchResultItem: React.FC<{item: Result<HitItemCatalogue>, onProductClick: () => void;}> = ({item, onProductClick}) => {
  const itemVignette = imageOptimisee(item.document.vignette_url, 40);

  return (
    <Stack direction="row" px={4} py={2} cursor="pointer" _hover={{bg: 'gray.100'}} alignItems='center' spacing={3} onClick={onProductClick} position='relative'>
      <VStack flexShrink={0} boxSize="40px">
        <Image src={itemVignette} alt={item.document.libelle_commercial} maxHeight='40px' maxWidth='40px'/>
      </VStack>
      {item.document.temperature_livraison === 'negatif' && <Box position='absolute' bottom='3' left='2'><ColdIcon boxSize='13px' /></Box>}
      <VStack alignItems='start' spacing={0} flex={1}>
        <HStack>
          <Text fontSize='2xs' fontStyle='initial'>{item.document.code}</Text>
          {item.document.nature_du_prix === 'promo' && <Text fontSize='2xs' fontStyle='initial' color='red.500'>Promotion</Text>}
          {item.document.est_cadencier && <Text fontSize='2xs' fontStyle='initial' color='blue.main'>Cadencier</Text>}
        </HStack>
        <Text fontSize='xs' fontWeight='700'>{item.document.libelle_commercial}</Text>
        <Text fontSize='xs'>{item.document.marque}</Text>
      </VStack>
    </Stack>
  );
};

export const Searchbar: React.FC<{props?: BoxProps, onModalClose?: () => void;}> = ({props, onModalClose}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const search = useSearchClient();
  const [itemResults, setItemResults] = useState<Awaited<ReturnType<typeof search.searchItems>>>();
  const [searchParams] = useSearchParams();
  const [term, setTerm] = useState(searchParams.get('q') ?? '');
  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();
  const navigate = useNavigate();
  const {t} = useTranslation('common');

  useEffect(() => {
    setTerm(searchParams.get('q') ?? '');
  }, [searchParams]);

  const handleClose = useCallback(() => {
    onClose();
    if (onModalClose) {
      onModalClose();
    }
    setTerm(searchParams.get('q') ?? '');
  }, [onClose, onModalClose, searchParams]);

  const handleTermClick = useCallback(() => {
    if (term) {
      const rechercheUrl = buildAssistanceRoute(routes.client.recherche, {}, {q: term});
      navigate(rechercheUrl);
    }
    handleClose();
  }, [navigate, term, handleClose, buildAssistanceRoute]);

  const debouncedSearch = useMemo(
    () =>
      debounce(async (searchTerm: string) => {
        if (search.indexIsBuilt && searchTerm.length >= SEARCH_MIN_CHARS) {
          const results = await search.searchItems({q: searchTerm});
          setItemResults(results);
        } else {
          setItemResults(undefined);
        }
      }, 300),
    [search]
  );

  const handleTermChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newTerm = event.target.value;
    setTerm(newTerm);
    debouncedSearch(newTerm);
  }, [debouncedSearch]);

  // Touche entrée
  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleTermClick();
    }
  }, [handleTermClick]);

  const textToast = useSimpleTextToast();

  const {submit: AjouterAction} = useAjouterAuPanier(livraisonSelectionnee);

  const AjouterAuPanier = useCallback(async (code_article: string, quantite: number) => {
    AjouterAction({code_article, quantite, commande_en: 'unitaire'});
  }, [AjouterAction]);

  const itemLink = useCallback((code_article: string) => {
    return buildAssistanceRoute(routes.client.produit, {code_article}, {from: 'recherche', query: encodeURI(`?q=${term}`)});
  }, [term, buildAssistanceRoute]);

  // pour un article qui n'est pas en precommande
  const handleCartIconClick = useCallback((code: string, multiple_de_commande_client: number | undefined) => {
    AjouterAuPanier(code, multiple_de_commande_client ?? 1);
    navigate(itemLink(code));
    onClose();
    NiceModal.remove(CatalogueModale);
  }, [AjouterAuPanier, navigate, itemLink, onClose]);

  // pour un article qui est en precommande
  const handlePrecommandeAction = useCallback((itemState: State<ItemCatalogueDesc>) => {
    textToast(t('toast.produit_precommande'));
    navigate(itemLink(itemState.data.tolede.code_article));
    onClose();
  }, [navigate, itemLink, textToast, t, onClose]);

  const contenuBarreModale = useMemo(() => (
    <Box>
      <InputGroup zIndex={1003} bg='white' borderTopRadius='8px' opacity={search.indexIsBuilt ? 1 : 0.2}>
        <Input
          placeholder={search.indexIsBuilt ? `Chercher un produit : ${SEARCH_MIN_CHARS} lettres minimum` : 'Nous chargeons votre catalogue ...'}
          onChange={handleTermChange}
          value={term}
          onKeyDown={handleKeyDown}
          isDisabled={!search.indexIsBuilt}
          autoFocus={true}
          _focus={{
            boxShadow: 'none',
            borderColor: 'gray.200',
          }}
          height="40px"
          cursor='pointer'
        />
        <InputRightAddon pointerEvents="auto" cursor="pointer" onClick={handleTermClick}><SearchIcon /></InputRightAddon>
      </InputGroup>
      {itemResults && term.length >= SEARCH_MIN_CHARS && (
        <Box bg='white' w='100%' fontSize='sm' fontWeight='400' maxHeight={{base:'calc(100vh - 120px)', md:'400px'}} overflowY="auto" mt={2} sx={{
          '&::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent'
          },
          '&': {
            scrollbarWidth: 'none',
            msOverflowStyle: 'none'
          },
        }}>
          {itemResults.hits.slice(0, 15).map(item => (
            <SearchResultItemComponent
              key={item.id}
              item={item}
              itemLink={itemLink}
              handleProductClick={handleClose}
              handleCartIconClick={handleCartIconClick}
              handlePrecommandeAction={handlePrecommandeAction}
            />
          ))}
        </Box>
      )}
    </Box>
  ), [search.indexIsBuilt, handleTermClick, handleTermChange, term, handleKeyDown, itemResults, itemLink, handleClose, handleCartIconClick, handlePrecommandeAction]);

  const breakpoint = useContext(BreakPointContext);
  return (
    <>
      <Box position="relative" width={{base:'100%', md:'30px', lg:'100%'}} {...props} onClick={onOpen}>
        {breakpoint?.breakpoint!=='md' ? <InputGroup zIndex={1003} bg='white' borderTopRadius='8px' opacity={search.indexIsBuilt ? 1 : 0.2}>
          <InputLeftElement pointerEvents="auto" cursor="pointer">
            <SearchIcon />
          </InputLeftElement>
          <Input
            placeholder={search.indexIsBuilt ? `Chercher un produit : ${SEARCH_MIN_CHARS} lettres minimum` : 'Nous chargeons votre catalogue ...'}
            isDisabled={!search.indexIsBuilt}
            tabIndex={-1}
            readOnly
            cursor='pointer'
          />
        </InputGroup>
          :
          <Button variant='ghost'>
            <SearchIcon />
          </Button>}
      </Box>
      <ModaleLayout
        visible={isOpen}
        hide={handleClose}
        size_desktop="xl"
        drawerPlacement="top"
        drawerSize="full"
        contentStyle={{
          top: '0',
          h: {base: '100vh', md: 'auto'},
          mb: '0',
        }}
        closeButtonStyle={{
          marginTop: {base: '5px', md: '6'},
          marginRight: {base: 'auto', md: '1'},
          height: '40px',
        }}
      >
        <Box pr={{base: '20px', md: '0'}} width="100%">
          <VStack spacing={4} align="stretch">
            {contenuBarreModale}
          </VStack>
        </Box>
      </ModaleLayout>
    </>
  );
};

interface SearchResultItemComponentProps {
  item: Result<HitItemCatalogue>;
  itemLink: (code: string) => string;
  handleProductClick: () => void;
  handleCartIconClick: (code: string, multiple: number | undefined) => void;
  handlePrecommandeAction: (itemState: State<ItemCatalogueDesc>) => void;
}

const SearchResultItemComponent: React.FC<SearchResultItemComponentProps> = ({item, itemLink, handleProductClick, handleCartIconClick, handlePrecommandeAction}) => {
  const client = useClient();
  const itemNav = useMemo(() => client.follow('go:item_catalogue', {code_article: item.document.code}), [client, item.document.code]);
  const itemState = useHalState(itemNav).state;

  // gére le cas d'un article en precommande ou non
  const handleIconClick = () => {
    if (itemState?.data.tolede.precommande) {
      handlePrecommandeAction(itemState);
    } else {
      handleCartIconClick(item.document.code, item.document.multiple_de_commande_client);
    }
  };

  return (
    <HStack key={item.id} w='100%' justifyContent='space-between' spacing={0}>
      <Link to={itemLink(item.document.code)} style={{width: 'calc(100% - 60px)', display: 'block'}}>
        <SearchResultItem
          item={item}
          onProductClick={handleProductClick}
        />
      </Link>
      <Box cursor="pointer" width='60px' height='100%' display="flex" justifyContent="center" alignItems="center" _hover={{transform: 'scale(1.2)'}} transition="transform 0.2s ease-in-out" onClick={handleIconClick}>
        <CartIcon color='gray.dark' />
      </Box>
    </HStack>
  );
};
