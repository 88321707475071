import {ChevronDownIcon, ChevronUpIcon} from '@chakra-ui/icons';
import {Box, HStack, VStack, Text} from '@chakra-ui/layout';
import {Button, Collapse, useDisclosure} from '@chakra-ui/react';
import React, {useCallback, useContext} from 'react';
import {State} from '@synako/halitrek';
import {ArticleCadencierEnleveEventDesc, ArticleDesc, ItemCatalogueDesc, ItemListeSelectionDesc} from 'api-types/ffconnect-hal';
import {HaliRenderOne, useCommand, useHalState} from '@synako/haligator';
import {useDefaultOnError} from '@/hooks/useDefaultOnError';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {Trans, useTranslation} from 'react-i18next';
import {useLivraisonsPossiblesContext} from '@/contexts/useContexts/useLivraisonsPossiblesContext';
import {ArticleBlock} from '@/domains/panier/PanierFound/ArticleBlock';
import {TrashIcon} from '@/components/svg/TrashIcon';
import {PoidsVariableTooltip} from '@/domains/panier/PanierFound/ProduitPanier/PoidsVariableTooltip';
import {useLignePourArticle} from '@/hooks/ligneDePanier/useLignePourArticle';
import {LabelDisponibilite} from '@/domains/catalogue/components/LabelDisponibilite';
import {PrixBlockUnitaire} from '../components/PrixBlockUnitaire';
import {useBuyingBlocks} from '../../../hooks/panier/useBuyingBlocks';
import {LigneProduitLayout} from '@/domains/liste/components/LigneProduitLayout';
import {BreakPointContext} from '@/chakra/BreakpointProvider';

export const CadencierSection: React.FC<{title: string; produits: State<ItemListeSelectionDesc>[]; editMode?: boolean}>
  = ({title, produits, editMode = false}) => {
    const {isOpen, onToggle} = useDisclosure();
    const {livraisonSelectionnee} = useLivraisonsPossiblesContext();

    return <VStack alignItems='stretch' width='100%' mt='1' opacity={'100%'}>
      <HStack margin={{base: '2', lg: '0'}}>
        <Text fontWeight='600'>{title}</Text>
        <Box textColor='gray.dark' fontWeight='600' fontSize='xs' bg='gray.light' p='1' borderRadius='8px' minW={'2em'} aspectRatio={1} textAlign='center'>
          {produits.length}
        </Box>
        <Button variant='ghost' p='0' onClick={onToggle}>{!isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}</Button>
      </HStack>
      <Collapse in={!isOpen}>
        <Box marginBottom='1px'>
          {produits.map((p:State<ItemListeSelectionDesc>) => {
            return (
              <LoadLigneCadencier key={`ligne_cadencier_${p.data.id}`} produit={p} canDelete={editMode} avecPanier={livraisonSelectionnee.hasOneLink('panier_ouvert')}/>
            );
          }
          )}
        </Box>
      </Collapse>
    </VStack>;
  };

const LoadLigneCadencier: React.FC<{produit: State<ItemListeSelectionDesc>, canDelete: boolean, avecPanier: boolean}> = ({produit, canDelete}) => {
  const textToast = useSimpleTextToast();
  const {state: articleState, loading: articleLoading} = useHalState(produit.follow('article'));
  const {t} = useTranslation('common');

  const onSuccess = useCallback((event: State<ArticleCadencierEnleveEventDesc>)=>{
    const {code_article} = produit.data.tolede;
    switch (event.data.data.logique) {
      case 'avertissement':
        textToast(<Trans t={t}>{t('toast.supprime_cadencier_safelive', {code_article})}</Trans>, null);
        break;
      case 'simulation':
        textToast(<Trans t={t}>{t('toast.supprime_cadencier_simulation', {code_article})}</Trans>, null);
        break;
      default:
        textToast(<Trans t={t}>{t('toast.supprime_cadencier', {code_article})}</Trans>);
        break;
    }
  }, [produit.data.tolede, textToast, t]);
  const onError = useDefaultOnError();
  const {submit:submitEnleverDuCadencier, isDisabled:deleteEnCours, isSubmitting} = useCommand(produit.resource, 'c:enlever-du-cadencier', {onSuccess, onError}); 

  const deleteAction = useCallback(()=>{
    submitEnleverDuCadencier({code_article: produit.data.tolede.code_article});
  }
  ,[produit, submitEnleverDuCadencier]);

  if(articleLoading) {
    return 'Chargement...'; // en embedded n'arrive jamais normaleent
  }
  
  return (
    <HaliRenderOne nav={produit.follow('item_catalogue')} render={({state: config_client}) => {
      if (!articleState) {
        return <div>Article dans le cadencier mais pas dans la base FF {config_client.data.tolede.code_article}</div>;
      }
      return <ProduitCadencier
        itemState={config_client}
        articleState={articleState}

        prixHT={config_client.data.tolede.prix_par_livraison![0].montant_ht}
        tauxTVA={config_client.data.tolede.taux_de_tva}

        somethingSubmitting={isSubmitting}
        deleteButton={canDelete}
        deleteEnCours={deleteEnCours}
        deleteAction={deleteAction}/>;
    }}/>);
};

const ProduitCadencier: React.FC<{
  itemState: State<ItemCatalogueDesc>,
  articleState: State<ArticleDesc>,
  prixHT:number,
  tauxTVA?:number,
  somethingSubmitting:boolean,
  deleteButton:boolean,
  deleteEnCours?: boolean,
  deleteAction?:()=>void,
}> = 
({itemState, articleState, prixHT, tauxTVA, 
  somethingSubmitting,
  deleteButton, deleteEnCours, deleteAction})=>{
  const {t} = useTranslation('common');
  const isMobile = useContext(BreakPointContext)?.isMobile;

  const precommande = itemState.data.tolede.precommande;

  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();
  const lignesDePanierPromise = livraisonSelectionnee.hasOneLink('panier_ouvert') ? livraisonSelectionnee.follow('panier_ouvert').followAll('lignes_de_panier') : undefined;
  const lignePanier = useLignePourArticle(itemState.data.tolede.code_article, lignesDePanierPromise);

  const prixBlock = <PrixBlockUnitaire
    prixUnitaire={prixHT}
    uniteFacturation={itemState.data.tolede.unite_de_facturation} 
    tauxTVA={tauxTVA}  
  />;

  const deleteBlock = deleteButton && <Button alignSelf='center' ml={{base:'auto', lg:'5em'}} leftIcon={<TrashIcon />} variant='ghost'
    onClick={deleteAction} isDisabled={deleteEnCours ?? somethingSubmitting}
  >
    {t('ligne_produit.retirer_le_produit')}
  </Button>;

  const articleBlock = <ArticleBlock code_article={articleState.data.tolede.code_article} article={articleState.follow('self')} />;

  const {uniteQuantiteBlock, buyBlock} = useBuyingBlocks(itemState, lignePanier);
  const selectBlock = <Box 
    mt='15px'
    ml='0'
    minW='fit-content'
  >
    {uniteQuantiteBlock({minW:'245px', w:'245px'})}
    {articleState.data.tolede.a_peser && <PoidsVariableTooltip/>}
  </Box>;

  const precommandeBlock = precommande && <LabelDisponibilite precommande={precommande}/>;

  return <LigneProduitLayout precommande={precommande}>
    {!isMobile ?
    /* Version desktop */
      <HStack w='100%' flexWrap='wrap'>
        {precommandeBlock}
        <HStack>
          {articleBlock}
        </HStack>
        <HStack alignItems='start' ml='auto'>
          {prixBlock}
          {selectBlock}
          {buyBlock({somethingSubmitting, afficher_bouton_liste:true, minW:'280px', w:'280px'})}
        </HStack>
        {deleteBlock}
      </HStack>
      : /* Version mobile */
      <VStack alignItems='stretch' w='100%'>
        <HStack>
          {precommandeBlock}
          {articleBlock}
          {deleteBlock}
        </HStack>
        <HStack justify='space-between' w='100%' mb='2'>
          {selectBlock}
          {prixBlock}
        </HStack>
        <HStack w='100%'>
          {buyBlock({somethingSubmitting, afficher_bouton_liste:true})}
        </HStack>
      </VStack>
    }
  </LigneProduitLayout>;
};
