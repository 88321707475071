import {useAjouterAuPanier} from '@/hooks/livraison/use-ajouter-au-panier';
import {Resource} from '@synako/halitrek';
import {LivraisonDesc} from 'api-types/ffconnect-hal';
import {TFunction} from 'i18next';
import {BuyButton} from './BuyButton';
import {ItemCatalogue} from 'api-types/ffconnect';

export const AjouterButton: React.FC<{
  livraisonSelectionnee: Resource<LivraisonDesc>,
  itemCatalogue: ItemCatalogue,
  commandeEnColis: boolean,
  t: TFunction
}> = ({
  livraisonSelectionnee,
  itemCatalogue,
  commandeEnColis,
  t
}) => {
  const {
    submit: ajouterAuPanierAction,
    isSubmitting: ajouterAuPanierSubmitting
  } = useAjouterAuPanier(livraisonSelectionnee);
  
  const {tolede:{
    code_article:codeArticle,
    quantite_colis_entier:quantiteColisEntier,
    minimum_de_commande_client:minimumCommande,
    multiple_de_commande_client:multipleCommande
  }} = itemCatalogue;

  return (
    <BuyButton
      libelle={t('bouton_achat.buy')}
      onClick={() => {
        ajouterAuPanierAction(
          {
            code_article: codeArticle,
            quantite: commandeEnColis ? quantiteColisEntier! : Math.max(minimumCommande, multipleCommande),
            commande_en: commandeEnColis ? 'colis' : 'unitaire'
          }
        );
      }}
      isSubmitting={ajouterAuPanierSubmitting}
    />
  );
};
