import {ChevronLeftBlueIcon} from '@/components/svg/ChevronLeftBlueIcon';
import {ChevronRightBlueIcon} from '@/components/svg/ChevronRightBlueIcon';
import {moisAnneeEnTexte} from '@/utils/date-tools';
import {Box, IconButton, Text} from '@chakra-ui/react';
import {Dispatch, SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';

export const CalendarHeader: React.FC<{
  dateLivraison: string,
  monthOffset: number,
  setMonthOffset: Dispatch<SetStateAction<number>>
}> = ({
  dateLivraison,
  monthOffset,
  setMonthOffset
}) => {
  const {t} = useTranslation('common');
  return (
    <Box 
      display="flex" 
      flexWrap='wrap' 
      justifyContent="space-between" 
      alignItems="center" 
      mt='0'
    >
      <Box w='100%'>
        <Box 
          display="inline-flex"
          height="59px"
          alignItems="center"
          gap="10px"
          w='100%'
          bg='gray.light'
          borderTopRadius='8px'
        >
          <IconButton
            icon={<ChevronLeftBlueIcon color='gray.dark' height='28px' width='28px'/>}
            onClick={() => {
              setMonthOffset((offset)=>offset-1);
            }} 
            aria-label={t('global.mois_precedent')}
            background={'transparent'}
            _hover={{background: 'transparent'}}
          />

          <Text color="gray.dark" mx={2} fontWeight='700' w='100%' textAlign='center'>
            {moisAnneeEnTexte(dateLivraison, monthOffset)}
          </Text>
          <IconButton 
            icon={<ChevronRightBlueIcon color='gray.dark' height='28px' width='28px' />}
            onClick={() => {
              setMonthOffset((offset)=>offset+1);
            }} 
            aria-label={t('global.mois_suivant')}
            background={'transparent'} 
            _hover={{background: 'transparent'}}
          />
        </Box>
      </Box>
    </Box>
  );
};
