import {useCommand} from '@synako/haligator';
import {State} from '@synako/halitrek';
import {LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {Trans, useTranslation} from 'react-i18next';
import {useDefaultOnError} from '../useDefaultOnError';
import {useSimpleTextToast} from '../useSimpleTextToast';
import {dateAvecSlashes} from '@/utils/date-tools';
import {AlertIcon} from '@chakra-ui/react';
import {ModaleRupture} from '@/domains/cadencier/components/ModaleRupture';
import NiceModal from '@ebay/nice-modal-react';

export type LigneDePanierCommands = ReturnType<typeof useLigneDePanierCommands>;

export const useLigneDePanierCommands = (
  ligneDePanier: State<LigneDePanierDesc>|undefined,
) => {
  const onError = useDefaultOnError();
  const textToast = useSimpleTextToast();
  const {t} = useTranslation('common');

  const remplacer = useCommand(
    ligneDePanier,
    'c:remplacer-un-article', {
      onError,
      onSuccess: (event) => {
        const {libelle} = event.data.data.ligne_de_panier;
        textToast(t('toast.produit_remplace', {libelle}), 2000, undefined, 'produit-remplace-toast');
        NiceModal.remove(ModaleRupture);
      }
    }
  );
  
  const differer = useCommand(
    ligneDePanier,
    'c:differer-la-livraison', {
      onError,
      onSuccess: (event) => {
        const {date_de_livraison} = event.data.data.panier_destination.livraison_tolede;
        textToast([
          <Trans t={t}>{t('toast.produit_differe', {date: dateAvecSlashes(date_de_livraison)})}</Trans>,
          t('toast.produit_differe_cta')
        ], 2000, undefined, 'produit-differe-toast');
        NiceModal.remove(ModaleRupture);
      }
    }
  );

  const supprimer = useCommand(
    ligneDePanier,
    'c:supprimer-du-panier', {
      onError,
      onSuccess: ()=>{
        textToast(t('toast.supprime_panier'), 2000, undefined, 'supprime-panier-toast');
      }
    }
  );

  const switchUnite = useCommand(
    ligneDePanier,
    'c:switch-unite', {
      onError,
      onSuccess: () => {
        // noop
      }
    }
  );

  const changerLaQuantite = useCommand(
    ligneDePanier,
    'c:changer-la-quantite', {
      onError,
      onSuccess: async (event) => {
        if (event.data.data.ligne_de_panier.quantite <= 0) {
          textToast(t('toast.supprime_panier'), 2000, undefined, 'supprime-panier-toast');
        } else if (event.data.data.message) {
          // quantité minimum non respectée
          textToast(<Trans t={t}>{t(`error.${event.data.data.message}`)}</Trans>, undefined, <AlertIcon />, 'quantite-minimum-error-toast');
        } else if (!location.pathname.includes('panier')) { 
          /*
            On ne veut checker la dispo que lorsque le panier n'est pas affiché
            Sur la page panier, on laisse le contrôle panier gérer l'affichage des erreurs
          */
          try {
            const dispo = await event.follow('disponibilite_article').get();
            const ligneDePanier = event.follow('ligne_de_panier');
            if (dispo.data.tolede.disponibilite === 'rupture' || dispo.data.quantite_manquante) {
              NiceModal.show(ModaleRupture, {
                dispo,
                ligneDePanier,
              });
            }
          } catch(_error) {
            textToast(<Trans t={t}>{t('error.controle_dispo')}</Trans>, undefined, <AlertIcon />, 'controle-dispo-error-toast');
          }
        }
      }
    }
  );

  /* 
    commande pour gérer les ruptures partielles
    la quantité maximum qui sert d'ajustement est fournie pas le get-dispo
    il n'est pas utile de le ré-exécuter ici
  */
  const ajuster = useCommand(
    ligneDePanier,
    'c:changer-la-quantite', {
      onError,
      onSuccess: event => {
        /* 
          commande pour gérer les ruptures partielles
          la quantité maximum qui sert d'ajustement est fournie pas le get-dispo
          il n'est pas utile de le ré-exécuter ici
        */
        const quantite = event.data.data.ligne_de_panier.quantite;
        ligneDePanier?.follow('item').get().then(itemCatalogue => {
          textToast(t('toast.quantite_ajustee', {
            quantite,
            unite:  t(`unite.${itemCatalogue.data.tolede.unite_de_commande_client}`, {count: quantite})
          }), 2000, undefined, 'quantite-ajustee-toast');
        });
      }
    }
  );

  const ligneDePanierCommands = {
    remplacer,
    differer,
    changerLaQuantite,
    ajuster,
    supprimer,
    switchUnite,
  };

  const somethingSubmitting = Object.values(ligneDePanierCommands).some(c => c.isSubmitting);

  return {
    somethingSubmitting,
    ...ligneDePanierCommands
  };
};
