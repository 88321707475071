import {Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, HStack, Text, VStack} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';
import routes from '@/routes';
import {ChevronRightIcon} from '@chakra-ui/icons';
import {Results} from '@orama/orama';
import {HitItemCatalogue} from 'api-types/ffconnect';
import {useTranslation} from 'react-i18next';

interface RechercheViewProps {
  itemResults: Results<HitItemCatalogue> | undefined;
  term: string | undefined;
}

export const RechercheView: React.FC<RechercheViewProps> = ({itemResults, term}) => {
  const navigate = useNavigate();
  const {t} = useTranslation('common');

  return(
    <Box bg='white'>
      {/* Version desktop */}
      <HStack pt='10px' ml='20px' display={{base:'none', md:'flex'}}>
        <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.dark' opacity="0.8"/>}>
          <BreadcrumbItem>
            <BreadcrumbLink mr="1" height="17px" opacity="0.8" fontWeight='400' fontSize="sm" lineHeight="17px" color="gray.dark" 
              onClick={()=>{navigate(routes.index.path);}}>
              {t('global.accueil')}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#' fontWeight='700' fontSize="sm">{t('global.recherche')}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </HStack>

      <HStack ml='20px' mb='20px' pt='20px' alignItems='end' display={{base:'none', md:'flex'}}>
        <Text fontSize='xl'>{t('recherche.resultat_pour_desktop')} <Text as="span" fontWeight="700">"{term}"</Text></Text>
        <Text fontSize='xs' color='gray.dark'>
          {t('global.produit', {count:itemResults?.count ?? 0}).toUpperCase()}
        </Text>
      </HStack>

      {/* Version mobile */}
      <VStack ml='20px' mb='10px' pt='10px' alignItems='start' spacing={0} display={{base:'flex', md:'none'}}>
        <Text fontSize='sm' color='gray.dark'>{t('recherche.resultat_pour_mobile')}</Text>
        <HStack>
          <Text fontWeight="700" fontSize='lg'>"{term}"</Text>
          <Text fontSize='xs' color='gray.dark'>
            ({itemResults?.count ?? 0})
          </Text>
        </HStack>
      </VStack>
    </Box>
  );
};
