import {VStack, SimpleGrid} from '@chakra-ui/react';
import {CatalogProductDetails} from './components/CatalogProductTemplate/CatalogProductTemplate';
import {HitItemCatalogue} from 'api-types/ffconnect';
import {Result} from '@orama/orama';
import {Location} from 'react-router-dom';
import {useContext} from 'react';
import {BreakPointContext} from '@/chakra/BreakpointProvider';

interface CatalogProductsGridProps {
  hitResult: Result<HitItemCatalogue>[];
  location: Location
}

export const CatalogProductsGrid: React.FC<CatalogProductsGridProps> = ({hitResult, location}) => {
  const view = useContext(BreakPointContext);
  const columnsDefine = {base: 1, sm: 2, md: 3, lg: 4, xl: 5, '2xl': 6};
  const columns = columnsDefine[view?.breakpoint ?? 'base'];

  return !view?.isMobile ?
    /* Version desktop */
    <SimpleGrid display={{base:'none', 'md':'grid'}}
      columns={columns} spacing="0" m="20px" bg="white.100" gap="0" sx={{
        [`& > *:nth-of-type(${columns}n), & > *:nth-last-of-type(1)`]:{
          borderRight: '1px solid',
          borderColor: 'gray.light',
        },
        [`& > *:nth-last-of-type(-n+${columns})`]: {
          borderBottom: '1px solid',
          borderColor: 'gray.light',
        }
      }}>
      {hitResult.map((produit) => (
        <CatalogProductDetails hit={produit.document} key={produit.id} location={location}/>
      ))}
    </SimpleGrid>

    : /* Version mobile */
    <VStack gap="0" display={{base:'flex', 'md':'none'}}>
      {hitResult.map((produit) => {
        return <CatalogProductDetails hit={produit.document} key={produit.id} location={location}/>;
      })}
    </VStack>;
};
