import {useEffect, useRef, useState} from 'react';
import {State} from '@synako/halitrek';
import {LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {LigneProblem} from 'api-types/ffconnect';
import {useLigneDePanierCommands} from '@/hooks/ligneDePanier/use-ligne-de-panier-commands';
import {ProduitPanierErrorCommun} from './ProduitPanierErrorCommun';

export interface ProduitPanierErrorProps {
  ligneDePanier?: State<LigneDePanierDesc>,
  probleme: LigneProblem,
  idError: string,
  validationRefresh: boolean,
}

export const ProduitPanierError: React.FC<ProduitPanierErrorProps> = (props) => {
  const {
    idError,
    probleme,
    ligneDePanier: lp,
    validationRefresh,
  } = props;
  // Cela règle le souci qu'il faut afficher l'ancienne ligne de panier potentiellement supprimée alors qu'on est en train de refresh
  const refLigneDePanier = useRef(lp);
  if (lp) {
    refLigneDePanier.current = lp;
  }
  const ligneDePanier = refLigneDePanier.current;

  const commands = useLigneDePanierCommands(ligneDePanier);
  
  // Alors qu'on l'action a submitted, il faut quand même garder le fait qu'on soit en 
  const {somethingSubmitting} = commands;
  const [submittingOrRefreshing, setSubmittingOrRefreshing] = useState(validationRefresh);
  useEffect(() => {
    if(somethingSubmitting) {
      setSubmittingOrRefreshing(true);
    }
  }, [somethingSubmitting]);
  useEffect(() => {
    if(!validationRefresh) {
      setSubmittingOrRefreshing(false);
    }
  }, [validationRefresh]);

  if (!ligneDePanier) {return null;} // Ne devrais jamais arriver

  return (
    <ProduitPanierErrorCommun
      idError={idError}
      probleme={probleme}
      ligneDePanier={ligneDePanier}
      submittingOrRefreshing={submittingOrRefreshing}
    />
  );
};
