import {CustomToast} from '@/components/toast/CustomToast';
import {Text, VStack, useToast} from '@chakra-ui/react';
import {isArray} from 'lodash-es';
import {ReactNode, useCallback} from 'react';

export const useSimpleTextToast = ()=>{
  const toast = useToast();

  const textToast = useCallback((content:ReactNode|ReactNode[], duration: number | null = 2000, icon?:ReactNode, id?: string)=>{
    const contentArray = (!isArray(content)) ? [content] : content;

    if (id && toast.isActive(id)) {
      // Si un toast avec cet ID est déjà actif, ne pas en créer un nouveau
      return;
    }

    toast.closeAll();

    toast({id, duration, isClosable: true, render: ({onClose}) => {
      return <CustomToast onClose={onClose} icon={icon} body={<VStack alignItems='start' gap='0'>
        {
          contentArray.map((c, cidx)=><Text key={cidx}>{c}</Text>)
        }
      </VStack>} />;
    }});
  }, [toast]);

  return textToast;
};
