import {ChevronDownIcon} from '@chakra-ui/icons';
import {Box, Button, Text, Tooltip, VStack} from '@chakra-ui/react';
import {ShippingCartIcon} from '../../svg/ShippingCartIcon';
import NiceModal from '@ebay/nice-modal-react';
import {jourMoisEnTexte} from '@/utils/date-tools';
import {useLivraisonsPossiblesContext} from '@/contexts/useContexts/useLivraisonsPossiblesContext';
import {ChangerDateModale} from '@/components/Modale/CalendarModale/ChangerDateModale';
import {Trans, useTranslation} from 'react-i18next';
import {useHalStates} from '@synako/haligator';
import {useLivraisons} from '@/haligator-factory';

export const MenuLivraison:React.FC = () => {  
  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();
  const {t} = useTranslation('common');

  const onOpen = () => {
    NiceModal.show(ChangerDateModale);
  };

  const {data: {tolede: {date_de_livraison: dateLivraison}}} = livraisonSelectionnee;
  const livraisonsPossibles = useHalStates(useLivraisons().followAll('items'));
  if (!livraisonsPossibles.states || livraisonsPossibles.error) {
    return;
  }

  const livraisonsAvecPanier = livraisonsPossibles.states.filter(livraison => livraison.hasOneLink('panier_ouvert'));
  
  return (
    <Tooltip label={t('menu_livraison.tooltip')}>
      <Button
        onClick={onOpen}
        flex={{base:'1'}}
        variant="ghost"
        _hover={{bg: 'transparent'}}
        background='blue.main_transparency_10'
        borderRight="1px solid rgba(0, 114, 186, 0.30)"
        borderRadius={{base:'0px',md:'50px 0px 0px 50px'}}
        height='100%'
        leftIcon={
          <Box position='relative'>
            <ShippingCartIcon />
            {livraisonsAvecPanier.length > 0 && (
              <VStack
                justify='center'
                position='absolute'
                top='0.5'
                right='-1.5'
                bg='blue.main'
                textColor='white'
                borderRadius='50%'
                boxSize='3.5'
                fontSize='xs'
              >
                <Box as='span'>{livraisonsAvecPanier.length}</Box>
              </VStack>
            )}
          </Box>
        }
        rightIcon={<ChevronDownIcon color="blue.main" />}
        w='265px'
      >
        <Text textColor='blue.main' fontSize={{base:'xs', md:'sm'}}>
          <Trans t={t}>
            {t('menu_livraison.livraison', {date: jourMoisEnTexte(dateLivraison)})}
          </Trans>
        </Text>
      </Button>
    </Tooltip>
  );
};
