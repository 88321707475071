import {Link, useRouteError} from 'react-router-dom';
import routes from '../../routes/index';
import {HStack, VStack, Image, Text, Stack, Button} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import NiceModal from '@ebay/nice-modal-react';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {CatalogueModale} from '@/domains/article/NomenclatureModale/CatalogueModale';
import {imageOptimisee} from '@/domains/mediatheque/optimisation';

export const Erreur404: React.FC = () => {
  const {t} = useTranslation('common', {keyPrefix:'erreur404'});
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const error = useRouteError();
  // TODO: voir comment on peut récupérer ces erreurs dans nos logs.
  console.error(error);
  
  return <Stack direction={{base: 'column', md: 'row'}} bg='white' w='100%' h='90vh' justify='center' alignItems='center'>
    <Image src={imageOptimisee(`${import.meta.env.BASE_URL}/erreur/carton.png`, 480)} alt={t('alt_img_carton')} maxW='30em' />
    <VStack alignItems='start'>
      <Text as={'h2'} fontSize='4xl' fontWeight='700'>{t('c_est_bien_vide_ici')}</Text>
      <Text fontSize='lg' fontWeight='500' mb='5'>{t('pas_de_panique')}</Text>
      <HStack mb='5' gap='5'>
        <Button as={Link} to={buildAssistanceRoute(routes.client.cadencier)} colorScheme='blue' variant='outline' _hover={{backgroundColor:'gray.light'}}>{t('voir_cadencier')}</Button>
        <Button colorScheme='blue' variant='outline' _hover={{backgroundColor:'gray.light'}} onClick={()=>{NiceModal.show(CatalogueModale);}}>{t('voir_catalogue')}</Button>
      </HStack>
      <Button as={Link} to={buildAssistanceRoute(routes.client.accueil)} colorScheme='blue'>{t('retour_accueil')}</Button>
    </VStack>

  </Stack>;
};
