import {useState, useEffect} from 'react';
import {useSearchClient} from '@/hooks/catalogue/use-search';

export function useCategories() {
  const search = useSearchClient();
  const nomenclatureWeb = search.getNomenclatureWeb();
  const [counts, setCounts] = useState<Awaited<ReturnType<typeof search.categorieCounts>>>();
  const [promoCounts, setPromoCounts] = useState<Awaited<ReturnType<typeof search.categorieCounts>>>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!search.indexIsBuilt) return;
    setIsLoading(true);
    Promise.all([
      search.categorieCounts(),
      search.categorieCounts({nature_du_prix: 'promo'}),
    ]).then(([countsResultats, promoCountsResultats]) => {
      setCounts(countsResultats);
      setPromoCounts(promoCountsResultats);
      setIsLoading(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.indexIsBuilt]);

  const premierNiveauCategories = nomenclatureWeb?.filter((item) => {
    return item.libelle !== 'Divers' && 
           item.id.split('-').length === 1 && 
           ((counts?.values[item.id] ?? 0) > 0);
  }) ?? [];

  return {
    counts,
    promoCounts,
    isLoading,
    nomenclatureWeb,
    premierNiveauCategories
  };
}
