import {Button, ButtonProps, StyleProps} from '@chakra-ui/react';

export const ErrorButton: React.FC<ButtonProps & StyleProps & {
  libelle: string, 
  refreshing: boolean,
  onClick: () => void,
}> = ({
  libelle, 
  refreshing,
  onClick,
  ...props
}) => (
  <Button 
    variant='ghost' 
    border='1px' 
    borderColor='blue.main'
    color='blue.main' 
    isDisabled={refreshing}
    onClick={onClick}
    cursor='pointer'
    {...props}
  > 
    {libelle}
  </Button>
);
