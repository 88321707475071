import {dateAvecSlashes} from '@/utils/date-tools';
import {useCommand, useCommandWithOptions} from '@synako/haligator';
import {useDefaultOnError} from '../useDefaultOnError';
import {useSimpleTextToast} from '../useSimpleTextToast';
import {useTranslation} from 'react-i18next';
import {useNavigatePanier} from './useNavigatePanier';
import {Navigable} from '@synako/halitrek';
import {PanierDesc} from 'api-types/ffconnect-hal';

export type PanierCommands = ReturnType<typeof usePanierCommands>;

export const usePanierCommands = (
  panier: Navigable<PanierDesc>,
) => {
  const onError = useDefaultOnError();
  const textToast = useSimpleTextToast();
  const {t} = useTranslation('common');
  const navigatePanier = useNavigatePanier();

  const vider = useCommand(
    panier,
    'c:vider', {
      onError,
      onSuccess: (event) => {
        const {date_de_livraison} = event.data.data.panier.livraison_tolede;
        textToast(t('toast.panier_vidé', {date: dateAvecSlashes(date_de_livraison)}));
      }
    }
  );
  const changerDateDeLivraison  = useCommand(
    panier,
    'c:changer-date_de_livraison', {
      onError,
      onSuccess: async (event) => {
        const {date_de_livraison} = event.data.data.panier.livraison_tolede;
        textToast(t('toast.panier_deplace', {date: new Date(date_de_livraison)}));
        navigatePanier(date_de_livraison);
      }
    });

  const changerModeDeRendu = useCommandWithOptions(
    panier,
    'c:changer-mode_de_rendu', {
      onError,
    }
  );
  const changerReference = useCommand(
    panier,
    'c:changer-reference-panier',
    {onError}
  );
  const valider = useCommand(
    panier,
    'c:valider',
    {onError}
  );
  const panierCommands = {
    panier,
    vider,
    changerDateDeLivraison,
    changerModeDeRendu,
    valider,
    changerReference,
  };

  return panierCommands;
};
