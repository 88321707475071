import {CalendarIcon, TimeIcon} from '@chakra-ui/icons';
import {Box, Button, HStack, Heading, Spinner, Text, VStack, useBreakpointValue} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {ListIcon} from '../../../components/svg/ListIcon';
import {toDecimalFr} from '@/utils/toDecimalFr';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {State} from '@synako/halitrek';
import {ProduitBottomBar} from './ProduitBottomBar';
import {DisplayLabels} from '@/domains/panier/components/DisplayLabels';
import {HaliRenderMany, useCommand, useHalState} from '@synako/haligator';
import {CheckLoaded} from '@/containers/CheckLoaded';
import NiceModal from '@ebay/nice-modal-react';
import {ModalSelectList} from '@/domains/liste/components/ModalSelectList';
import {FicheProduit} from '@/domains/article/FicheArticle/FicheProduit';
import {useLignePourArticle} from '@/hooks/ligneDePanier/useLignePourArticle';
import {ListeDrapeaux} from '@/domains/panier/components/listeDrapeaux';
import {useLivraisonsPossiblesContext} from '@/contexts/useContexts/useLivraisonsPossiblesContext';
import {useBuyingBlocks} from '@/hooks/panier/useBuyingBlocks';
import {BreakPointContext} from '@/chakra/BreakpointProvider';
import {useCallback, useContext} from 'react';
import {useDefaultOnError} from '@/hooks/useDefaultOnError';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {useCadencier} from '@/haligator-factory';

export const ProduitResume: React.FC<{
  itemState:State<ItemCatalogueDesc>,
  prixHT:number,
  tva:number
}> 
= ({itemState, prixHT, tva}) => {
  const viewMobile = useBreakpointValue({base: true, md: false});
  const {t} = useTranslation('common');
  const articleHook = useHalState(itemState.follow('article'));
  const isMobile = useContext(BreakPointContext)?.isMobile;

  const precommande = itemState.data.tolede.precommande;

  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();
  const lignesDePanierPromise = livraisonSelectionnee.follow('panier_ouvert').followAll('lignes_de_panier');

  const ligneDePanier = useLignePourArticle(itemState.data.tolede.code_article, lignesDePanierPromise);
  const context = viewMobile ? 'panier' : 'fiche_produit';

  const pays = articleHook.data?.tolede.pays_d_origine ?? [];

  const {buyBlock, uniteQuantiteBlock} = useBuyingBlocks(itemState, ligneDePanier);

  const textToast = useSimpleTextToast();
  const onError = useDefaultOnError();
  const onSuccess = useCallback(() => {
    textToast(t('toast.ajout_cadencier'));
  }, [t, textToast]);
  const {submit: AjouterAuCadencierAction, isSubmitting:ajoutCandencierSubmit, isDisabled:ajoutCandencierDisabled} = useCommand(itemState.resource, 'c:ajouter-au-cadencier', {onSuccess, onError});
  const cadencier = useCadencier();

  return <>
    <CheckLoaded hook={articleHook} render={({state:articleState})=>{
      const {tolede} = articleState.data;
  
      return(
        <VStack m='20px' alignItems='lef' mb="50px"
          p={{base: '10px 20px 0px 20px', md: ''}}>
          <Text fontSize='2xs' color='gray.dark' fontWeight='400' lineHeight='14px'>
            {tolede.code_article}
          </Text>
          <Heading as='h4' size={{base: 'sm', md: 'md'}}>
            {tolede.libelle_commercial}
          </Heading>
          <Text size={{base: 'sm', md: 'md'}}>
            {tolede.marque?.[0]}
          </Text>
          <HStack>
            <DisplayLabels articleState={articleState}/>
            <ListeDrapeaux listepays={pays} context='fiche_produit' />
          </HStack>
          {tolede.le_plus_produit && <Text fontSize='sm' color='gray.dark' fontWeight='400' lineHeight='20px' mt="10px">{tolede.le_plus_produit}</Text>}
          <VStack height='40px' width='100%' alignItems='flex-start' gap='0' flex='2' mt="10px" mb={{base: '30px', md : ''}}>
            <HStack gap='0.5' alignItems='start'>
              <Text textColor='black.text' fontSize='2xl' fontWeight='700'>{toDecimalFr(prixHT, 2, 3)}</Text>
              <Text fontSize='xs' fontWeight='700'>€</Text>
              <Text fontSize='xs' fontWeight='700'>{t('ligne_produit.HT')}/{t(`unite.${articleState.data.tolede.unite_de_facturation ?? 'KG'}`, {count:1})}</Text>
            </HStack>
            {tva && <Text fontSize='2xs' fontWeight='400' color='gray.dark'>{t('ligne_produit.tva_percent', {tauxTVA:tva})}</Text>}
          </VStack>
          <>
            <VStack bg="gray.75" padding='15px' borderRadius='8px' mt={{base: '15px', md: 0}}>
              {precommande &&
                <HStack bg='lblue.100' width="100%" borderRadius="8px" p="10px">
                  <TimeIcon boxSize="30px" p="6px" color="blue.main" bg="rgba(255, 255, 255, 0.5)" borderRadius="5px" />
                  <Text fontSize="xs" color="blue.main">
                    <Text as='span' fontWeight='700'>{t('disponibilite.precommande')}</Text>
                  </Text>
                </HStack>
              }
              {isMobile ?
              /* Version mobile */
                <>
                  <Box width='100%' border='0.5px' borderRadius='8px'>
                    {uniteQuantiteBlock({bg:'white'})}
                  </Box>
                  <Box width='100%' position="relative">
                    {buyBlock({afficher_bouton_liste:true, mt:'0'})}
                  </Box>
                </>
                : /* Version desktop */
                <HStack alignItems='end' width='100%' flexWrap='wrap'>
                  <Box flex='1' border='0.5px' borderRadius='8px'>
                    {uniteQuantiteBlock({w:'100%', bg:'white'})}
                  </Box>
                  <Box flex='1' position="relative">
                    {buyBlock({mt:'0'})}
                  </Box>
                </HStack>
              }
              
              {!isMobile && <Box borderTop="1px solid" borderColor="gray.200" mt={2} pt={2}>
                <HStack spacing={8} justifyContent='center' alignItems='stretch'
                  color='gray.500'
                  fontWeight='600'
                  transition="all 0.2s ease-in-out"
                >
                  <HaliRenderMany
                    many={cadencier.followAll('selection')}
                    render={({data: cadencierData}) => {
                      const articleDejaPresentCadencier = cadencierData.some((article) => article.tolede.code_article === itemState.data.tolede.code_article);
                      return(
                        <Button
                          flex='1'
                          w='160px'
                          height='fit-content'
                          _hover={{bg: 'gray.100'}}
                          bgColor='transparent'
                          cursor="pointer"
                          isDisabled={ajoutCandencierDisabled || articleDejaPresentCadencier}
                          onClick={() => {AjouterAuCadencierAction({code_article: itemState.data.tolede.code_article});}}
                        >
                    
                          <VStack py='2'>
                            {ajoutCandencierSubmit
                              ? <Spinner size="md" color='gray.dark' my='auto' />
                              :<>
                                <CalendarIcon boxSize={5} color="gray.dark" />
                                <Text fontSize="sm" color="gray.500" mt='5px'>{t('liste.ajouter_au_cadencier')}</Text>
                              </>
                            }
                          </VStack>
                        </Button>
                      );
                    }}
                  />

                  <Button
                    flex='1'
                    w='160px'
                    height='fit-content'
                    _hover={{bg: 'gray.100'}}
                    bgColor='transparent'
                    onClick={() => NiceModal.show(ModalSelectList, {itemState, context})}
                  >
                    <VStack py='2'>
                      <ListIcon color='gray.dark'/>
                      <Text fontSize="sm" color="gray.500">{t('liste.ajouter_a_une_liste')}</Text>
                    </VStack>
                  </Button>
                </HStack>
              </Box>}
            </VStack>
            <FicheProduit articleState={articleState}/>
          </>

          {isMobile && <Box>
            <ProduitBottomBar itemState={itemState} prixHT={prixHT} tva={tva} 
              buyBlock={buyBlock({mt:'0'})} />
          </Box>}
        </VStack>
      );
    }}/>
  </>;
};
