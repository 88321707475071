import React, {useEffect, useRef, useState} from 'react';
import {CatalogHeader} from './components/CatalogHeader';
import {CatalogProductsGrid} from './CatalogProductsGrid';
import {CatalogFilter} from './components/CatalogFilter/CatalogFilter';
import {useFilters} from '@/hooks/use-filters';
import {FiltresItemCatalogue, coerceFiltresItemCatalogue as coerce, useSearchClient} from '../../hooks/catalogue/use-search';
import {FilterProvider} from '@/hooks/use-filters-context';
import {Box, Text} from '@chakra-ui/react';
import {RechercheView} from './RechercheView';
import {useLocation} from 'react-router-dom';
import {ITEMS_PAR_PAGE} from '../../hooks/catalogue/use-search';
import {useTranslation} from 'react-i18next';
import {Pagination} from '@/components/Pagination';
import {Chargement} from '@/components/Chargement/Chargement';

export const CatalogueView: React.FC = () => {
  const filtersHook = useFilters<FiltresItemCatalogue, typeof coerce>({coerce});
  const search = useSearchClient();
  const [itemResults, setItemResults] = useState<Awaited<ReturnType<typeof search.searchItems>>>();
  const [totalPages, setTotalPages] = useState(1);
  const location = useLocation();
  const term = filtersHook.filters.q;
  const {t} = useTranslation('common');

  // on stocke les valeurs précédentes de labels / pays d'origine et nature du prix pour revenir à la page 1 si ces valeurs changent
  const previousFilters = useRef({pays_d_origine: filtersHook.filters.pays_d_origine, labels: filtersHook.filters.labels, nature_du_prix: filtersHook.filters.nature_du_prix});

  useEffect(() => {
    if (search.indexIsBuilt) {
      // comparaison des valeurs actuelles et précédentes
      const paysChanged = previousFilters.current.pays_d_origine !== filtersHook.filters.pays_d_origine;
      const labelsChanged = previousFilters.current.labels !== filtersHook.filters.labels;
      const natureDuPrixChanged = previousFilters.current.nature_du_prix !== filtersHook.filters.nature_du_prix;

      // redirection à la page 1 si changement de valeurs pour labels / pays d'origine ou nature du prix
      if (paysChanged || labelsChanged || natureDuPrixChanged) {
        filtersHook.changeFilter('page', 1);
      }
      search.searchItems(filtersHook.filters).then((r) => {
        setItemResults(r);
        setTotalPages(Math.ceil(r.count / ITEMS_PAR_PAGE));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersHook.filters, search.indexIsBuilt]);

  const currentPage = filtersHook.filters.page ?? 1;
  const setCurrentPage = (page: number) => {
    filtersHook.changeFilter('page', page);
  };

  if (!search.indexIsBuilt) {
    return (
      <Chargement texte={t('catalogue.chargement')}/>
    );
  }

  return (
    <FilterProvider hook={filtersHook}>
      <Box bg='white'>
        {location.pathname.includes('/catalogue') ? (
          <CatalogHeader catWeb={filtersHook.filters.cat_web?.[0] ?? ''} />
        ) : (
          <RechercheView term={term} itemResults={itemResults}/>
        )}
        <CatalogFilter itemResults={itemResults}/>
        <CatalogProductsGrid hitResult={itemResults?.hits ?? []} location={location}/>
        {itemResults?.count === 0 ?
          <Box mt='30px' pb='30px'>
            <Text textAlign='center' fontWeight='500'>{t('catalogue.aucun_produit')}</Text>
          </Box>
          :
          <Pagination
            pageActuelle={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        }
      </Box>
    </FilterProvider>
  );
};
