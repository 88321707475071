import {Box, Stack, VStack} from '@chakra-ui/layout';
import {CadencierHeader} from './components/CadencierHeader';
import {HaliRenderAll, HaliRenderOne} from '@synako/haligator';
import {useCadencier} from '@/haligator-factory';
import {useCallback, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import routes from '@/routes';
import {ArrowForwardIcon} from '@chakra-ui/icons';
import {useTranslation} from 'react-i18next';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {useLivraisonsPossiblesContext} from '@/contexts/useContexts/useLivraisonsPossiblesContext';
import {BlueButton} from '@/components/Button/BlueButton';
import {Skeleton} from '@chakra-ui/react';
import {CadencierSection} from './controllers/CadencierSection';
import {CadencierTitreNombreDeProduits} from './components/CadencierTitreNombreDeProduits';

export const MonCadencier: React.FC = () => {
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const {t} = useTranslation('common');
  const cadencierHook = useCadencier();
  const cadencierSelectionHook = cadencierHook.followAll('selection');
  const [editMode, setEditMode] = useState<boolean>(false);
  // utilisation dans Box de cadencierHeader pour connaitre la hauteur
  const headerRef = useRef<HTMLDivElement>(null);
  const [activeTag, setActiveTag] = useState<string | null>(null);
  // pointe vers Bos avec cadencierSEction
  const sectionsRef = useRef<HTMLDivElement>(null);

  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();

  const scrollVersCategorie = (categorieId: string) => {
    const element = document.getElementById(categorieId);
    // recuperation de la hauteur du header
    const headerHeight = headerRef.current?.offsetHeight ?? 0;
    // recuperation de la hauteur du header
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      // position finale de défilement
      const offsetPosition = elementPosition - headerHeight - 130;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
      setActiveTag(categorieId);
    }
  };

  const handleScroll = useCallback(() => {
    // verification si la reférence à une section existe
    if (!sectionsRef.current) return;
    // recuperation de la hauteur du header
    const headerHeight = headerRef.current?.offsetHeight ?? 0;
    const isMobile = window.innerWidth <= 768;
    // ajout 1/4 en mobile de la hauteur de la fenêtre pour déclencher le changement un peu avant que la section atteigne le haut
    // 80px en desktop
    const scrollPosition = window.scrollY + headerHeight + (isMobile ? window.innerHeight / 4 : 80);    // tableau à partir des enfants de l'élément référencé par sectionsRef
    // chaque enfant représente une catégorie dans le cadencier
    const sections = Array.from(sectionsRef.current.children);
    // pour chaque section comparaison de sa position supérieure avec la position de défilement calculée
    // si la position de défilement est >= à la position de la section, cette section est considérée comme active
    for (let i = sections.length - 1; i >= 0; i--) {
      const section = sections[i] as HTMLElement;
      const sectionTop = section.offsetTop;
      if (scrollPosition >= sectionTop) {
        setActiveTag(section.id);
        break;
      }
    }
  }, []);

  useEffect(() => {
    // écouteur d'événement sur scroll
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <HaliRenderAll all={[cadencierHook, cadencierSelectionHook]} 
      skeleton={()=><Skeleton width='100%' height='10' m='5'/>}
      render={({states, data}) => {
        const cadencier = data[0];
        const items = states[1];
        return (
          <Box bg='#F7F7F7' py={{base:'0', lg:'10'}} px={{base:'0', md: '2', xl: '20'}}>
            <VStack bg={{lg:'white'}} p={{base:'0', md:'4'}} gap={{base:'0',lg:'10'}} alignItems='start' position='relative'>
              <Stack gap='0' flexDirection='column' justify='start'
                width='100%' alignItems='baseline'
                bg='white' display={{base:'block', md:'none'}} position='sticky' top='114px' zIndex='sticky'>
                <CadencierTitreNombreDeProduits nombreDeProduits={cadencier!.nombre_articles} />
              </Stack>
              <Box ref={headerRef} width="100%" zIndex='dropdown' position={{md:'sticky'}} top={{md:'120px'}} bg='white'>
                <CadencierHeader
                  nombreDeProduits={cadencier!.nombre_articles}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  categories={cadencier?.tri_selection.map(cat => ({
                    categorieId: cat.categorieId,
                    categorie: cat.categorie
                  })) ?? []}
                  scrollVersCategorie={scrollVersCategorie}
                  activeTag={activeTag}
                />
              </Box>
              <Box ref={sectionsRef} width="100%">
                {!items
                  ? <Skeleton width='100%' height='10' m='5'/>
                  : cadencier?.tri_selection.map((cat) => {
                    return (
                      <Box
                        key={cat.categorieId}
                        id={cat.categorieId}
                        width="100%"
                      >
                        <CadencierSection
                          title={cat.categorie}
                          produits={items.filter((p) => cat.articles.includes(p.data.tolede.code_article))}
                          editMode={editMode}
                        />
                      </Box>
                    );
                  })}
              </Box>
              {/* Bouton Valider Panier */}
              <Box m='5' ml='auto'>
                {livraisonSelectionnee.hasOneLink('panier_ouvert') && 
                  <HaliRenderOne 
                    nav={livraisonSelectionnee.follow('panier_ouvert')} 
                    // le rerender est long sur les gros cadenciers, on peut avoir un lien panier_ouvert 
                    // alors que le panier n'existe plus en DB. On fallback sur un élément vide
                    fallback={() => <></>}
                    render={({state:panierState})=>{
                      return <Link to={buildAssistanceRoute(routes.client.panier, {date_panier_actif: panierState.data.livraison_tolede.date_de_livraison})}>
                        <BlueButton texte={t('cadencier.cta_panier')} rightIcon={<ArrowForwardIcon/>} p='7' m='2'/>
                      </Link>;
                    }}/>}
              </Box>
            </VStack>
          </Box>
        );
      }} />
  );
};
