import {useClient} from '@/haligator-factory';
import {dateAvecSlashes} from '@/utils/date-tools';
import {Text, Tooltip} from '@chakra-ui/react';
import {HaliRenderOne} from '@synako/haligator';
import {Navigable} from '@synako/halitrek';
import {DisponibiliteArticle} from 'api-types/ffconnect';
import {Trans, useTranslation} from 'react-i18next';

export const EncartDLC:React.FC<{dispo?:Navigable<DisponibiliteArticle>}> = ({dispo: followDispo})=>{
  const {t} = useTranslation('common');
  const clientNav = useClient();
  return followDispo && <HaliRenderOne nav={clientNav} render={({data})=>{
    if (data.affichage_dlc === 'active'){
      return <HaliRenderOne nav={followDispo} getOptions={{cache: 'no-store'}} render={({data})=>{
        // DLC du jour pour une préparation du jour
        return <>{data.tolede.dlc_envisagee!==undefined && 
          <Tooltip label={t('ligne_produit.encart_dlc_tooltip')}>
            <Text fontSize='sm' textColor='gray.dark' fontWeight='500'>
              <Trans>{t('ligne_produit.encart_dlc',{date:dateAvecSlashes(data.tolede.dlc_envisagee)})}</Trans>
            </Text>
          </Tooltip>
        }</>;
      }}/>;
    }
    return <></>;
  }}/>;
};
