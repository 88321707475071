import {Box, HStack, StyleProps, Text, VStack} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';
import {DisplayLabels} from './DisplayLabels';
import {ListeDrapeaux} from '../components/listeDrapeaux';
import {ReactNode} from 'react';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import routes from '@/routes';
import {VignetteArticleVStack} from '@/domains/mediatheque/VignetteArticleVStack';
import {EncartDLC} from '@/domains/ligneProduit/EncartDLC';
import {Navigable} from '@synako/halitrek';
import {useHalState} from '@synako/haligator';
import {ArticleDesc, DisponibiliteArticleDesc} from 'api-types/ffconnect-hal';
import ColdIcon from '@/components/svg/ColdIcon';

export const ArticleBlock: React.FC<{
  code_article:string,
  article:Navigable<ArticleDesc>,
  dispo?:Navigable<DisponibiliteArticleDesc>,
  precommande?: ReactNode,
} & StyleProps> = ({
  code_article,
  article: navArticle,
  dispo: navDispo,
  precommande,
  ...props
}) => {
  const navigate = useNavigate();

  const {buildAssistanceRoute} = useIsAssistanceMode();
  const routeToArticle = buildAssistanceRoute(routes.client.produit, {code_article});
  const article = useHalState(navArticle);

  return (
    <HStack alignItems='start' cursor='pointer' onClick={()=>{navigate(routeToArticle);}} mr='2em' {...props} position='relative'>
      {precommande}
      <Box position="absolute" top="55px" left="0px">{article.data?.temperature_livraison === 'negatif' && <ColdIcon boxSize='25px' />}</Box>
      <VignetteArticleVStack article={article.navigable} />
      <VStack alignItems='start'>
        <HStack alignContent='baseline'>
          <Text fontSize='xs' fontWeight='400'>{code_article}</Text>
          <DisplayLabels SIQO_et_regimes_alimentaires={article.data?.tolede.SIQO_et_regimes_alimentaires}/>
          <ListeDrapeaux listepays={article.data?.tolede.pays_d_origine ?? []}/>
        </HStack>
        {/* Desktop */}
        <Text display={{base: 'none', md: 'block'}} fontWeight='600' lineHeight='16px' maxW='400px'>
          {article.data?.tolede.libelle_commercial}
        </Text>
        <Text display={{base: 'none', md: 'block'}} lineHeight='16px' maxH='32px' maxW='400px'>
          {article.data?.tolede.marque?.[0]}
        </Text>
        {/* Mobile */}
        <Text display={{base: 'block', md: 'none'}} fontWeight='600' lineHeight='16px'>
          {article.data?.tolede.libelle_commercial}&nbsp;{article.data?.tolede.marque?.[0]}
        </Text>
        <EncartDLC dispo={navDispo}/>
      </VStack>
    </HStack>
  );  
};
