import {PropsWithChildren, createContext, useContext} from 'react';
import {CoerceOf, HookFilters} from './use-filters';

const FilterContext = createContext<HookFilters | undefined>(undefined);

export interface DefaultFiltersConfig<D = unknown, C = CoerceOf<D> > {
  Data: D;
  Coerce: C;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useFiltersContext<Config extends DefaultFiltersConfig = DefaultFiltersConfig>() {
  const value = useContext(FilterContext);
  if (!value) {
    throw new Error('useFilters must be used within a FilterProvider');
  }
  return value as HookFilters<Config['Data'], Config['Coerce']>;
}

export function FilterProvider (
  {children, hook} : PropsWithChildren<{hook: HookFilters}>
) {
  return <FilterContext.Provider value={hook}>
    {children}
  </FilterContext.Provider>;
}