import {Navigable} from '@synako/halitrek';
import {ClientDesc} from 'api-types/ffconnect-hal';
import {createContext, PropsWithChildren, useState, useEffect} from 'react';
import {SearchIndexes, buildIndexes} from './use-search';

export const SearchClientContext = createContext<SearchIndexes | undefined>(undefined);

export function SearchClientProvider(
  {client, children} : PropsWithChildren<{
    client: Navigable<ClientDesc>
  }>
) {
  const [indexes, setIndexes] = useState<SearchIndexes>();
  useEffect(() => {
    // console.log('BUILDING indexexes');
    client.follow('search').get()
      .then(state => buildIndexes(state.data))
      .then(setIndexes)
      .then(() => {console.log('Indexes are built.');})
      .catch((e : unknown) => {
        console.error(e);
        console.warn('Indexes could not be built.');
      });
  }, [client]);
  return <SearchClientContext.Provider value={indexes}>{children}</SearchClientContext.Provider>;
}