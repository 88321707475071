import {AlertIcon, Link, Text} from '@chakra-ui/react';
import {HalitrekErrorHandler, ProblemBody} from '@synako/halitrek';
import {useCallback} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useSimpleTextToast} from './useSimpleTextToast';
import {useIsAssistanceMode} from './auth/useIsAssistanceMode';
import routes from '@/routes';
import {useNavigate} from 'react-router-dom';

export const useDefaultOnError = ()=>{
  const textToast = useSimpleTextToast();
  const {t} = useTranslation('common');
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const navigate = useNavigate();

  const onError = useCallback<HalitrekErrorHandler>((error)=>{
    let message = error.message;
    console.warn(message);
    if ((error.body as ProblemBody | undefined)?.type === 'validation_error'){
      const validation = (error.body as {validation?:{keyword:string}[]}).validation; 
      if (validation?.length == 1){
        message = t([`error.${validation[0].keyword}`, message]);
      }
    }
    textToast(
      <Text as="span">
        <Trans 
          t={t}
          components={{
            Reload: <Link onClick={() => {location.reload();}} />,
            // href provoque un rechargement de la page, on utilise onClick et navigate
            Contact: <Link onClick={() => {navigate(buildAssistanceRoute(routes.client.contacts));}} />
          }}
        >{t([`error.${message}`, message])}</Trans>
      </Text>,
      4000, <AlertIcon/>
    );
  }, [t, textToast, navigate, buildAssistanceRoute]);

  return onError;
};
