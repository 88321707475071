import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {useDefaultOnError} from '@/hooks/useDefaultOnError';
import {Box, Button, HStack, VStack} from '@chakra-ui/react';
import {HaliRenderOne, useHalState} from '@synako/haligator';
import {State, Resource} from '@synako/halitrek';
import {ArticleDesc, ItemCatalogueDesc, ItemListeSelectionDesc, ListeDesc} from 'api-types/ffconnect-hal';
import {useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {TrashIcon} from '@/components/svg/TrashIcon';
import {useLivraisonsPossiblesContext} from '@/contexts/useContexts/useLivraisonsPossiblesContext';
import {PrixBlockUnitaire} from '@/domains/cadencier/components/PrixBlockUnitaire';
import {ArticleBlock} from '@/domains/panier/PanierFound/ArticleBlock';
import {PoidsVariableTooltip} from '@/domains/panier/PanierFound/ProduitPanier/PoidsVariableTooltip';
import {useLignePourArticle} from '@/hooks/ligneDePanier/useLignePourArticle';
import {LabelDisponibilite} from '@/domains/catalogue/components/LabelDisponibilite';
import {useBuyingBlocks} from '@/hooks/panier/useBuyingBlocks';
import {LigneProduitLayout} from './LigneProduitLayout';
import {BreakPointContext} from '@/chakra/BreakpointProvider';

export const ListeSection:React.FC<{produits: Resource<ItemListeSelectionDesc>[], liste: State<ListeDesc> | undefined}> 
= ({produits, liste: selectedListState})=>{
  return <VStack alignItems='stretch' width='100%' mt='3'>
    <VStack gap='0'>
      {produits.map((p:Resource<ItemListeSelectionDesc>, index) => {
        return <LoadLigneListe key={`ligne_liste_${index}`} produit={p} listState={selectedListState}/>;
      }
      )}
    </VStack>
  </VStack>;
};

const LoadLigneListe: React.FC<{produit: Resource<ItemListeSelectionDesc>, listState: State<ListeDesc> | undefined}> = ({produit, listState}) => {
  const {t} = useTranslation('common');
  const textToast = useSimpleTextToast();
  const onError = useDefaultOnError();
  const {state: articleState, loading: articleLoading} = useHalState(produit.follow('self').follow('article'));
  
  const deleteAction = useCallback(async ()=>{
    const code_article = articleState?.data.tolede.code_article;
    if (!code_article) {
      textToast(t('toast.produit_introuvable_supprime'));
      return;
    }
  
    try {
      await listState?.submitCommand('c:supprimer-article-d-une-liste', {liste_id: listState.data.id, code_article});
      textToast(t('toast.supprime_liste', {code_article}));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      onError(error);
    }
  }, [articleState?.data.tolede.code_article, textToast, t, listState, onError]);
  
  if(articleLoading) {
    return 'Chargement...';
  }
  
  return <>
    <HaliRenderOne nav={produit.follow('item_catalogue')} render={({state: config_client}) => {
      if (!articleState) {
        return <div>Article dans le cadencier mais pas dans la base FF {config_client.data.tolede.code_article}</div>;
      }
      return <ProduitListe
        itemState={config_client}
        articleState={articleState}

        prixHT={config_client.data.tolede.prix_par_livraison![0].montant_ht}
        tauxTVA={config_client.data.tolede.taux_de_tva}

        deleteAction={deleteAction} somethingSubmitting={false}/>;
    }}/>
  </>;
};

const ProduitListe: React.FC<{
  itemState: State<ItemCatalogueDesc>,
  articleState: State<ArticleDesc>,
  prixHT:number,
  tauxTVA?:number,
  somethingSubmitting:boolean,
  deleteEnCours?: boolean,
  deleteAction?:()=>void,
}> = ({itemState, articleState, prixHT, tauxTVA, 
  somethingSubmitting,
  deleteEnCours, deleteAction})=>{
  const isMobile = useContext(BreakPointContext)?.isMobile;
  const precommande = itemState.data.tolede.precommande;
  
  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();
  const lignesDePanierPromise = livraisonSelectionnee.hasOneLink('panier_ouvert') ? livraisonSelectionnee.follow('panier_ouvert').followAll('lignes_de_panier') : undefined;
  const lignePanier = useLignePourArticle(itemState.data.tolede.code_article, lignesDePanierPromise);
  
  const prixBlock = <PrixBlockUnitaire
    prixUnitaire={prixHT}
    uniteFacturation={itemState.data.tolede.unite_de_facturation} 
    tauxTVA={tauxTVA}  
  />;
  
  const deleteBlock = <Button variant='ghost' position={{base:'absolute', lg:'relative'}} m={{base:'5', lg:'initial'}} top={0} right={0}
    onClick={deleteAction} isDisabled={deleteEnCours ?? somethingSubmitting}
  >
    <TrashIcon />
  </Button>;
  
  const {uniteQuantiteBlock, buyBlock} = useBuyingBlocks(itemState, lignePanier);
  const articleBlock = <ArticleBlock code_article={articleState.data.tolede.code_article} article={articleState.follow('self')}/>;
  
  const selectBlock = <Box 
    mt='15px'
    ml='0'
    minW='fit-content'
  >
    {uniteQuantiteBlock({minW:'245px', w:'245px'})}
    {articleState.data.tolede.a_peser && <PoidsVariableTooltip/>}
  </Box>;
  
  const precommandeBlock = precommande && <LabelDisponibilite precommande={precommande}/>;
  
  return <LigneProduitLayout precommande={precommande}>
    
    {!isMobile ?
    /* Version desktop */
      <HStack w='100%' flexWrap='wrap'>
        {precommandeBlock}
        <HStack>
          {articleBlock}
        </HStack>
        <HStack alignItems='start' ml='auto'>
          {prixBlock}
          {selectBlock}
          {buyBlock({afficher_bouton_liste:true, minW:'280px', w:'280px'})}
          <Box mt='15px'>
            {deleteBlock}
          </Box>
        </HStack>
      </HStack>
    /* Version mobile */
      :<VStack alignItems='stretch' w='100%'>
        <HStack>
          {precommandeBlock}
          {articleBlock}
          {deleteBlock}
        </HStack>
        <HStack justify='space-between' w='100%' mb='2'>
          {selectBlock}
          {prixBlock}
        </HStack>
        <HStack w='100%'>
          {buyBlock({afficher_bouton_liste:true})}
        </HStack>
      </VStack>
    }
  </LigneProduitLayout>;
};
