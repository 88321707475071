import {HStack,Image, Skeleton, VStack} from '@chakra-ui/react';
import {CartButton} from './CartButton/CartButton';
import {MenuCatalog} from './MenuCatalog/MenuCatalog';
import {Searchbar} from './Searchbar/Searchbar';
import routes from '@/routes';
import {useNavigate, useParams} from 'react-router-dom';
import {HeaderBand} from '../../domains/HeaderBand/HeaderBand';
import {CheckLoaded} from '@/containers/CheckLoaded';
import {HaliRenderOne, useHalState, useHalStates} from '@synako/haligator';
import {useLivraisons, useSocieteCommerciale} from '@/haligator-factory';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {useLivraisonsPossiblesContext} from '@/contexts/useContexts/useLivraisonsPossiblesContext';
import {NavButton} from './NavButton';
import {CadencierIcon} from '../svg/CadencierIcon';
import {useTranslation} from 'react-i18next';
import {ListIcon} from '../svg/ListIcon';
import {imageOptimisee} from '@/domains/mediatheque/optimisation';
import {PromotionIcon} from '../svg/PromotionIcon';
import {StarIcon} from '@chakra-ui/icons';
import {usePromoMpSearch} from '@/hooks/catalogue/usePromoMpSearch';
import {Panier} from 'api-types/ffconnect';

export const Navbar:React.FC = () => {
  const hide_mobile = {display:{base:'none', md:'flex'}};

  const navigate = useNavigate();

  const societeCommerciale = useHalState(useSocieteCommerciale());

  const {
    livraisonSelectionnee,
  } = useLivraisonsPossiblesContext();

  // vérification si en mode assistance pour le positionnement sticky de la barre de navigation
  const {id_client_assistance} = useParams();
  const isAssistanceMode = !!id_client_assistance;
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const {t} = useTranslation('common');

  const {itemResultsPromo, itemResultsMP, promoDispo} = usePromoMpSearch({
    inclureFiltersHook: false
  });

  const promoLien = buildAssistanceRoute(routes.client.catalogue, {}, {nature_du_prix: 'promo', from: 'promo'});
  const MPLien = buildAssistanceRoute(routes.client.catalogue, {}, {est_MP: true, from: 'mp'});

  const buttonText = promoDispo ? t('catalogue.promotions') : t('catalogue.indispensables');
  const Lien = promoDispo ? promoLien : MPLien;

  // Fonction pour supprimer "/ffconnect" de l'URL du logo
  const removeFFConnect = (url: string | undefined) => {
    return url?.replace(/^\/ffconnect/, '');
  };

  const livraisonsPossibles = useHalStates(useLivraisons().followAll('items'));
  if (!livraisonsPossibles.states || livraisonsPossibles.error) {
    return;
  }

  return (
    <VStack gap='0' top={isAssistanceMode ? '50px' : '0'} position="sticky" zIndex='sticky'>
      <HeaderBand prochaineLivraison={livraisonsPossibles.states[0]}/>
      <HStack w='100%' h={{base:'16', md:'20'}} alignItems='center' p={{base:'0',md:'3'}} px={{base:'0',md:'2',xl:'20'}} gap="4"
        bg='white' borderBottom={{base:'0', md:'1px solid'}} borderColor={{md:'gray.light'}} >
        <HStack alignItems='stretch' maxHeight='100%' cursor='pointer' _hover={{opacity:'30%'}}
          onClick={()=>{navigate(buildAssistanceRoute(routes.client.accueil));}} {...hide_mobile}>
          <CheckLoaded hook={societeCommerciale} render={({data})=>{
            const logoUrl = removeFFConnect(data.url_logo);
            return <Image src={imageOptimisee(`${import.meta.env['VITE_MEDIA_URL']}${logoUrl}`, 96)} alt={data.tolede.nom_commercial} htmlWidth='464' htmlHeight='294' maxW='6em' objectFit='contain' margin='-1'/>;
          }}/>
        </HStack>
        <MenuCatalog props={hide_mobile}/>
        <Searchbar props={{display:{base:'none', md:'flex'}}}/>
        <HStack display={{base:'none', md:'flex'}} alignItems='stretch' h='100%' spacing={0.5}>
          {(itemResultsPromo?.hits ?? itemResultsMP) && (
            <>
              <NavButton ButtonIcon={promoDispo ? PromotionIcon : StarIcon} label={buttonText} to={Lien} color={promoDispo ? 'red.500' : 'blue.main'} />
            </>
          )}
          <NavButton ButtonIcon={ListIcon} label={t('global.mes_listes')} to={buildAssistanceRoute(routes.client.listes_default)}/>
          <NavButton ButtonIcon={CadencierIcon} label={t('global.mon_cadencier')} to={buildAssistanceRoute(routes.client.cadencier)}/>
        </HStack>
        {livraisonSelectionnee.hasOneLink('panier_ouvert') ? 
          <HaliRenderOne 
            nav={livraisonSelectionnee.follow('panier_ouvert')} 
            skeleton={() => <Skeleton maxW={{base: '100%', md: '400px'}} minW={{base: '100%', md: '400px'}}/>}
            // le rerender est long sur les gros cadenciers, on peut avoir un lien panier_ouvert 
            // alors que le panier n'existe plus en DB. On fallback sur un élément à 0€
            fallback={() => <CartButton panierData={{
              nombre_de_lignes: 0, 
              total_estime_ht: 0, 
              livraison_tolede: {
                date_de_livraison: livraisonSelectionnee.data.tolede.date_de_livraison
              }
            } as Panier} />}
            render={({state}) => {
              return <CartButton panierData={state.data} />;
            }}
          />            
          : <CartButton/>
        }
      </HStack>
    </VStack>
  );
};
