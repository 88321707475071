import {Trans, useTranslation} from 'react-i18next';
import {Box, HStack, Spacer, Text, Tooltip, VStack} from '@chakra-ui/react';
import {State} from '@synako/halitrek';
import {DisponibiliteArticleDesc, LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {LigneProblem} from 'api-types/ffconnect';
import {ArticleBlock} from '../ArticleBlock';
import {RemplacerErrorButton} from '../ErrorButton/RemplacerErrorButton';
import {DiffererErrorButton} from '../ErrorButton/DiffererErrorButton';
import {SupprimerErrorButton} from '../ErrorButton/SupprimerErrorButton';
import {AjusterErrorButton} from '../ErrorButton/AjusterErrorButton';
import {useLigneDePanierCommands} from '@/hooks/ligneDePanier/use-ligne-de-panier-commands';

export interface ProduitPanierErrorCommunProps {
  ligneDePanier?: State<LigneDePanierDesc>,
  idError: string,
  probleme?: LigneProblem,
  dispo?: State<DisponibiliteArticleDesc>,
  onClose?: () => void,
  submittingOrRefreshing?: boolean,
}

export const ProduitPanierErrorCommun: React.FC<ProduitPanierErrorCommunProps> = (props) => {
  const {
    idError,
    probleme,
    dispo,
    ligneDePanier,
    onClose,
    submittingOrRefreshing,
  } = props;
  const {t} = useTranslation('common');

  const commands = useLigneDePanierCommands(ligneDePanier);

  if (!ligneDePanier) {return null;} // Ne devrais jamais arriver

  const articleBlock = <ArticleBlock
    code_article={ligneDePanier.data.code_article}
    article={ligneDePanier.follow('article')}
    dispo={ligneDePanier.follow('disponibilite_article')}
  />;

  let errorCode: string | undefined;
  let quantiteMax: number | undefined;

  if (probleme) {
    errorCode = probleme.problem.code;
    quantiteMax = probleme.problem.quantite_max;
  } else if (dispo) {
    if (dispo.data.tolede.disponibilite === 'rupture') {
      errorCode = 'rupture';
    } else if (dispo.data.quantite_manquante) {
      errorCode = 'quantite_trop_importante';
    } else if (dispo.data.unite_commande) {
      errorCode = 'erreur_unite_commande';
    }
    quantiteMax = dispo.data.quantite_livrable;
  }

  const quantite = ligneDePanier.data.quantite;
  const uniteCommande = ligneDePanier.data.unite_de_commande_client;
  let message;
  let buttons;

  switch (errorCode) {
    case 'rupture':
      message = <Trans t={t}>{t('ligne_produit.indisponible.rupture', {
        quantite, 
        unite: t(`unite.${uniteCommande}`, {count: quantite})
      })}</Trans>;
      buttons = <>
        <RemplacerErrorButton commands={commands} ligneDePanier={ligneDePanier} />
        <DiffererErrorButton commands={commands} ligneDePanier={ligneDePanier} />
        <SupprimerErrorButton commands={commands} onClose={onClose} />
      </>;
      break;
    case 'quantite_trop_importante':
      buttons = <>
        <RemplacerErrorButton commands={commands} ligneDePanier={ligneDePanier} />
        <AjusterErrorButton ligneDePanier={ligneDePanier} commands={commands} quantiteMaximale={quantiteMax} onClose={onClose} />
      </>;
      message = <Trans t={t}>
        {t('ligne_produit.indisponible.rupture_partielle', {
          quantite_max: `${quantiteMax} ${t(`unite.${uniteCommande}`, {count: quantiteMax})}`,
          quantite_demande: quantite,
          count: quantiteMax
        })}
      </Trans>;
      break;
    case 'article_inconnu':
    case 'article_obsolete':
    case 'non_commercialise':
    case 'non_autorise':
    case 'non_web':
    case 'erreur_temperature_tournee':
    case 'erreur_multiple_de_commande':
    case 'generique':
    case 'heure_depassee':
    case 'adresse_inconnue': // A partir de là normalement cela ne concerne que la commande
    case 'client_bloque':
    case 'client_inconnu':
    case 'delai_de_prepa_long':
    case 'erreur_code_tournee':
    case 'erreur_date_livraison':
    case 'erreur_mode_de_rendu':
    case 'erreur_nb_lignes':
    case 'franco_poids_prix_non_atteint':
      buttons = <>
        {/* <RemplacerErrorButton commands={commands} ligneDePanier={ligneDePanier} /> */}
        <SupprimerErrorButton commands={commands} />
      </>;
      message = <Tooltip label={`${probleme?.problem.message} (${probleme?.problem.code})`} placement='top'>
        <Text>{t('ligne_produit.indisponible.generique')}</Text>
      </Tooltip>;
      break;
  }

  return (
    <VStack
      id={idError}
      p={{base:'2', md:'20px'}}
      borderTop="1px"
      borderColor="gray.light"
      _notLast={{borderBottom:'0'}}
      background="white"
      width="100%" 
      alignItems="stretch"
      position="relative"
      display="flex"
    >
      {/* Desktop */}
      <HStack 
        display={{base: 'none', md: 'flex'}}
        width='100%' 
        alignItems='start' 
        justify='end' 
        flexWrap='wrap' 
        gap='0' pt="0px" 
        flex='1' 
        opacity={submittingOrRefreshing ? 0.3 : 1}
      >

        {articleBlock}

        <Spacer/>

        <HStack justifyContent='end' alignItems='start' justifySelf='end' w="100%">
          <VStack alignItems="end">
            <HStack>
              <Box 
                p='1.5' 
                px='2.5' 
                borderRadius='8px' 
                backgroundColor='red.warning_bg' 
                color='red.fail'
                fontSize='xs'
              >
                {message}
              </Box>
            </HStack>
            <HStack 
              justifyContent={{base:'start', lg:'end'}}
            >
              {buttons}
            </HStack>
          </VStack>
        </HStack>
      </HStack>

      {/* Mobile */}
      <VStack
        display={{base: 'block', md: 'none'}}
        width='100%' 
        alignItems='start' 
        justify='end'
      >
        {articleBlock}
        <Spacer />
        <Box 
          p='1.5' 
          px='2.5' 
          borderRadius='8px' 
          backgroundColor='red.warning_bg' 
          color='red.fail'
          fontSize='xs'
          mb='1'
        >
          {message}
        </Box>
        <HStack justify={{base:'end', md:'space-between'}} sx={{flex:1}}>
          {buttons}
        </HStack>
      </VStack> 
    </VStack>
  );
};
