import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {useSelectedLivraison} from './hooks/use-selected-livraison';
import {CalendarModale} from './CalendarModale';
import {useCallback} from 'react';
import {Calendar} from './Calendar/Calendar';
import {useLivraisonsPossiblesContext} from '@/contexts/useContexts/useLivraisonsPossiblesContext';
import {useAfficherCadencier} from '@/hooks/livraison/use-afficher-cadencier';
import {useAfficherPanier} from '@/hooks/livraison/use-afficher-panier';
import {Box, Flex} from '@chakra-ui/react';
import {useHalStates} from '@synako/haligator';
import {useLivraisons} from '@/haligator-factory';
import {ListePaniersEnCours} from '@/domains/accueil/components/ListePaniersEnCours';

export const ChangerDateModale = NiceModal.create(({
  derniereAction
}: {
  derniereAction?: string
}) => {
  const modal = useModal();
  const afficherPanier = useAfficherPanier();
  const afficherCadencier = useAfficherCadencier();

  const {
    livraisonSelectionnee, 
    setLivraisonSelectionnee,
  } = useLivraisonsPossiblesContext();

  const {
    selectedLivraison, 
    onDateSelect, 
    onCancel
  } = useSelectedLivraison(
    livraisonSelectionnee,
    setLivraisonSelectionnee,
    modal
  );

  const onValidate = useCallback(async () => {
    // bouton Choisir une livraison de PanierNotFound
    // Il n'y a pas d'autres paniers en cours, on redirect sur le cadencier
    if (derniereAction === 'valide') {
      afficherCadencier(selectedLivraison.data.tolede.date_de_livraison);
    } else {
    // si on n'est pas dans le panier, il suffit de mettre à jour la date de livraison globale
      if (
        !location.pathname.includes('panier') && 
      selectedLivraison.uri !== livraisonSelectionnee.uri
      ) {
        setLivraisonSelectionnee(selectedLivraison);
      } 
      // dans le panier, il faut également rediriger vers la bonne url
      if (location.pathname.includes('panier')) {
        afficherPanier(selectedLivraison.data.tolede.date_de_livraison);
      }
    }
    modal.remove();
  }, [
    modal,
    selectedLivraison,
    afficherCadencier,
    afficherPanier,
    livraisonSelectionnee,
    setLivraisonSelectionnee,
    derniereAction
  ]);

  const livraisonsPossibles = useHalStates(useLivraisons().followAll('items'));
  if (!livraisonsPossibles.states || livraisonsPossibles.error) {
    return;
  }

  const livraisonsAvecPanier = livraisonsPossibles.states.filter(livraison => livraison.hasOneLink('panier_ouvert'));

  const size_desktop = livraisonsAvecPanier.length === 0 ? 'xl' : '5xl';

  return (
    <CalendarModale modal={modal} messageKey={'default'} onCancel={onCancel} size_desktop={size_desktop}>
      <Flex w='100%' height='100%' direction={{base: 'column', md:'row'}}>
        <Box flex={1} borderRadius="8px" border='1px solid' borderColor='gray.light' height='100%'>
          <Calendar
            onCancel={onCancel}
            onDateSelect={onDateSelect}
            onValidate={onValidate}
            selectedLivraison={selectedLivraison}
          />
        </Box>
        {livraisonsAvecPanier.length > 0 && <Box flex={1} height='100%'>
          <ListePaniersEnCours afficheInfo={false} dansModaleCalendrier={true}/>
        </Box>}
      </Flex>
    </CalendarModale>
  );
});
