import {Box, HStack} from '@chakra-ui/react';
import {ContactComponent} from './components/ContactComponent';
import {HeaderImage} from './components/HeaderImage';
import {UserMenu} from './components/UserMenu';
import {ShippingWhiteIcon} from '../../components/svg/ShippingWhiteIcon';
import {LivraisonDesc} from 'api-types/ffconnect-hal';
import {State} from '@synako/halitrek';
import {useInfoLivraison} from '@/hooks/livraison/use-info-livraison';
import {CommandeDateTexte} from '@/domains/accueil/components/CommandeDateTexte';

export const HeaderBand: React.FC<{prochaineLivraison: State<LivraisonDesc>}> = ({prochaineLivraison}) => {
  const {date_de_livraison} = useInfoLivraison(prochaineLivraison.data);
  return (
    <Box position='relative' backgroundColor='white' w='100vw'>
      <HeaderImage />
      <HStack
        position='absolute'
        top='0'
        left='0'
        alignItems='center'
        justify='center'
        width='100%'
        height='100%'
        pl={{base: '0', md: '4'}}
      >
        <Box display={{base: 'none', md: 'block'}}>
          <ShippingWhiteIcon />
        </Box>
        <Box textAlign={{base:'center', md:'start'}} p={1}>
          <CommandeDateTexte date_de_livraison={date_de_livraison} color="white" fontSize={{base:'2xs', sm:'xs', md:'sm'}}/>
        </Box>
        <HStack ml='auto' alignItems='center' spacing={0} display={{base: 'none', md: 'flex'}}>
          <ContactComponent />
          <Box height='20px' borderLeft='1px solid white' ml={2} />
          <UserMenu />
        </HStack>
      </HStack>
    </Box>
  );
};
