import {Button, Text, VStack, Box, HStack} from '@chakra-ui/react';
import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {useState, useCallback, useEffect} from 'react';
import {AdresseDeLivraisonDesc, ChangerModeDeRenduOptionsDesc} from 'api-types/ffconnect-hal';
import {State} from '@synako/halitrek';
import {guessTitreAdresseDeLivraison} from '@/hooks/client/guessTitreAdresseDeLivraison';
import {useTranslation} from 'react-i18next';
import {ChangerModeDeRenduCommand, ModeDeRenduDesArticles} from 'api-types/ffconnect';
import {CartonIcon} from '@/components/svg/CartonIcon';
import {ShippingCartIcon} from '@/components/svg/ShippingCartIcon';
import {LocationIcon} from '@/components/svg/LocationIcon';
import {ModaleLayout} from '@/components/Modale/ModaleLayout';
import {BlueButton} from '@/components/Button/BlueButton';
import {GrayButton} from '@/components/Button/GrayButton';

export const ModaleChangerAdresseLivraison = NiceModal.create<{
  codeAdresse: string; 
  changerModeDeRenduOptions: State<ChangerModeDeRenduOptionsDesc>, 
  validateAction: (payload:ChangerModeDeRenduCommand) => void;
  mode_de_rendu: ModeDeRenduDesArticles,
  states: State<AdresseDeLivraisonDesc>[]; 
}>(
    ({codeAdresse, changerModeDeRenduOptions, validateAction, mode_de_rendu, states}) => {
      const modal = useModal();
      const {t} = useTranslation('common');
      const [selectedCode, setSelectedCode] = useState<string | undefined>(codeAdresse);
      const [tab, setTab] = useState<ModeDeRenduDesArticles>(mode_de_rendu);
      const tab_livraison = tab==='livraison';

      useEffect(() => {
        if (codeAdresse !== selectedCode) {
          setSelectedCode(codeAdresse);
        }
      // selectedCode n'est pas inclus directement dans les dépendances pour permettre la sélection
      // on inclut toutefois une condition dessus pour exécuter le hook même si codeAdresse n'a pas changé
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [codeAdresse, selectedCode === undefined]);

      const handleAdresseClick = useCallback((new_codeAdresse: string) => {
        setSelectedCode(new_codeAdresse);
      }, [setSelectedCode]);

      const closeModal = () => {
        modal.remove();
        setSelectedCode(undefined);
      };

      const {enlevement_possible, adresse_d_enlevement, livraison_possible} = changerModeDeRenduOptions.data;

      return <>{selectedCode && <ModaleLayout
        visible={modal.visible}
        hide={closeModal}
        size_desktop='xl'
        titre={t('commande.modale_changer_adresse_header')}
        footer={<HStack justifyContent='start'>
          <BlueButton flex='1' onClick={() => {
            if (selectedCode !== codeAdresse || tab !== mode_de_rendu) validateAction({code_adresse_de_livraison: selectedCode, mode_de_rendu: tab});
            closeModal();
          } } texte={t('global.valider')}/>
          <GrayButton flex='1' onClick={closeModal} texte={t('global.annuler')}/>
        </HStack>}
      >
        <VStack alignItems='stretch' spacing='3'>
          {(enlevement_possible && livraison_possible) && <HStack>
            <Button leftIcon={<CartonIcon color={tab_livraison?'gray.dark':'white'}/>} 
              onClick={()=>{setTab('enlevement');}} colorScheme={tab_livraison? 'gray' : 'blue'} 
              variant={tab_livraison? 'outline' : 'solid'} _hover={{backgroundColor: tab_livraison?'gray.light':''}}>
              {t('panier.recap.mode_de_rendu.enlevement')}
            </Button>
            <Button leftIcon={<ShippingCartIcon color={!tab_livraison?'gray.dark':'white'}/>} 
              onClick={()=>{setTab('livraison');}} colorScheme={!tab_livraison? 'gray' : 'blue'} 
              variant={!tab_livraison? 'outline' : 'solid'} _hover={{backgroundColor: !tab_livraison?'gray.light':''}}>
              {t('panier.recap.mode_de_rendu.livraison')}
            </Button>
          </HStack>}
          <VStack bg='blue.bg' borderRadius='8px' p='4' alignItems='start' gap='6'>
            <HStack gap='4'>
              <VStack justify='center' alignSelf='start' bg='white' p='2' borderRadius='5px' boxSize='35px'>
                {tab_livraison
                  ? <ShippingCartIcon color='black'/>
                  : <CartonIcon color='black'/>
                }
              </VStack>
              <VStack flex='1' alignItems='baseline'>
                <Text fontWeight='700' fontSize='lg' color='blue.main'>{t(`panier.recap.modale_adresse.titre_${tab}`)}</Text>
                {!tab_livraison && <HStack color='blue.main' mt='4' alignItems='start'>
                  {/* Affichage adresse enlevement */}
                  <LocationIcon/>
                  <VStack alignItems='start' spacing='0'>
                    {adresse_d_enlevement?.ligne1 !== undefined && <Text>{adresse_d_enlevement.ligne1}</Text>}
                    {adresse_d_enlevement?.ligne2 !== undefined && <Text>{adresse_d_enlevement.ligne2}</Text>}
                    <Text>{adresse_d_enlevement?.code_postal} {adresse_d_enlevement?.ville}</Text>
                  </VStack>
                </HStack>}
              </VStack>
            </HStack>
            {tab_livraison &&
                <VStack alignItems='stretch' w='100%'>
                  <Text fontSize='sm' fontWeight='600'>{t('commande.adresse_livraison')}</Text>
                  {states.map(state => (
                    <LigneChangerAdresse 
                      key={`changer_adresse_${state.uri}`} 
                      adresseState={state} 
                      handleAdresseClick={handleAdresseClick} 
                      codeAdresse={selectedCode} 
                    />))}
                </VStack>
            }
          </VStack>
        </VStack>
      </ModaleLayout>
      }</>;
    });

const LigneChangerAdresse:React.FC<{adresseState: State<AdresseDeLivraisonDesc>, codeAdresse:string, handleAdresseClick:(_data:string)=>void}> 
= ({adresseState, codeAdresse, handleAdresseClick})=>{
  const adresseData = adresseState.data.tolede;
  const isActive = (adresseData.code_adresse_de_livraison == codeAdresse);
  
  const {nom_client, enseigne} = guessTitreAdresseDeLivraison(adresseData);

  return (<Box cursor="pointer" display="flex" alignItems="center" py='2'
    width='100%' minH="48px" flexShrink={0} borderRadius="10px" border="1px solid" borderColor={isActive ? 'blue.main' : '#D2D2D2'} 
    background={isActive ? 'white' : 'gray.bg'} _hover={{bg: isActive?'gray.bg':'gray.light'}} 
    onClick={() => {
      handleAdresseClick(adresseData.code_adresse_de_livraison);
    }}>
    <Box ml={{base: '1',md: '3'}}>
      <LocationIcon color={isActive?'blue.main':'black'} />
    </Box>
    <Box display="flex" flexDirection="column" ml={{base: '1', lg: '2'}} fontWeight={400}>
      {enseigne && <Text fontWeight='400' justifyContent='flex-start' color='gray.dark' fontFamily='Inter' fontSize='sm' fontStyle='normal' lineHeight='normal'>
        {enseigne}
      </Text>}
      {nom_client && <Text fontWeight='400' justifyContent='flex-start' color='gray.dark' fontFamily='Inter' fontSize='sm' fontStyle='normal' lineHeight='normal'>
        {nom_client}
      </Text>}
      <Text fontSize='xs' justifyContent='flex-start'>
        {adresseData.adresse.ligne1}, {adresseData.adresse.ligne2} {adresseData.adresse.code_postal} {adresseData.adresse.ville}
      </Text>
    </Box>
  </Box>);
  // }}/>;
};
