import {Drawer, DrawerContent, useDisclosure, Box, Icon, DrawerOverlay} from '@chakra-ui/react';
import {CloseIcon} from '@chakra-ui/icons';
import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {CategoriesView} from './CategoriesView';
import {useTranslation} from 'react-i18next';
import {Chargement} from '@/components/Chargement/Chargement';
import {useCategories} from '@/hooks/catalogue/useCategories';

export const CatalogueModale = NiceModal.create(() => {
  const {t} = useTranslation('common');
  const modal = useModal();
  const {isOpen, onClose} = useDisclosure({isOpen: modal.visible, onClose: modal.remove});
  const {counts, promoCounts, isLoading, nomenclatureWeb} = useCategories();

  return (
    <>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="full">
        <DrawerOverlay/>
        <DrawerContent zIndex={101} boxShadow="none">
          <Box display="flex" height="100%">
            {isLoading
              ? <Chargement texte={t('modale_nomenclature.chargement')}/>
              : <CategoriesView
                categories={nomenclatureWeb}
                onClose={onClose}
                counts={counts?.values ?? {}}
                promoCounts={promoCounts}
              />
            }
            {/* Uniquement en desktop */}
            <>
              <Box display={{base:'none', md:'block'}} boxSize='70px' position="relative" flexShrink={0}>
                <Icon as={CloseIcon} position="absolute" top='24px' left="50%" transform="translateX(-50%)" cursor="pointer" 
                  bg='white' boxSize='37px' padding='10px' borderRadius='8px' color='gray.dark' zIndex={101}
                  onClick={onClose}/>
              </Box>
              <Box display={{base:'none', md:'block'}} position="fixed" top={0} left={0} right={0} bottom={0} bg='transparent' zIndex={100}
                onClick={onClose}/>
            </>
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  );
});
