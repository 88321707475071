import {ModaleLayout} from '@/components/Modale/ModaleLayout';
import {ProduitPanierErrorCommun} from '@/domains/panier/PanierFound/ProduitPanier/ProduitPanierErrorCommun';
import {dateAvecSlashes} from '@/utils/date-tools';
import NiceModal from '@ebay/nice-modal-react';
import {useHalState} from '@synako/haligator';
import {FollowPromiseOne, State} from '@synako/halitrek';
import {DisponibiliteArticleDesc, LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {useTranslation} from 'react-i18next';

export interface ModaleRuptureProps {
  dispo: State<DisponibiliteArticleDesc>,
  ligneDePanier: FollowPromiseOne<LigneDePanierDesc>,
  date_de_livraison?: string,
}

export const ModaleRupture = NiceModal.create(({
  dispo, ligneDePanier, date_de_livraison
}: ModaleRuptureProps) => {
  const {t} = useTranslation('common');
  const modal = NiceModal.useModal();
  const ligneDePanierState = useHalState(ligneDePanier).state;

  const clearAndClose = () => {
    modal.remove();
  };

  return(
    <ModaleLayout visible={modal.visible} hide={clearAndClose} titre={t('ligne_produit.indisponible.produit_indisponible', {
      dateDeLivraison: dateAvecSlashes(date_de_livraison)
    })}>
      <ProduitPanierErrorCommun
        dispo={dispo}
        idError='error'
        ligneDePanier={ligneDePanierState}
        onClose={clearAndClose}
      />
    </ModaleLayout>
  );
});
