import {Box, HStack, Text, VStack, Skeleton} from '@chakra-ui/react';
import React, {Dispatch, SetStateAction, useCallback, useState} from 'react';
import {CalendarGrid} from './CalendarGrid';
import {ArticleDesc, ItemCatalogueDesc, LivraisonDesc} from 'api-types/ffconnect-hal';
import {Resource, State} from '@synako/halitrek';
import {LoadingSpinner} from '@/components/Loading/LoadingSpinner';
import {HaliRenderAll, HaliRenderOne, useHalState} from '@synako/haligator';
import {useTranslation} from 'react-i18next';
import {UniteQuantite, UniteSwitcheeEvent} from 'api-types/ffconnect';
import {TooltipAsToast} from '@/components/TooltipAsToast/TooltipAsToast';
import {BlueButton} from '@/components/Button/BlueButton';
import {GrayButton} from '@/components/Button/GrayButton';
import {BuyProductPanierInput} from '@/domains/panier/BuyProduct/BuyProductPanierInput';
import {SelectUnite} from '@/domains/panier/BuyProduct/SelectUnite';
import {useSelectionParColis} from '@/hooks/catalogue/useSelectionParColis';
import {ArticleBlock} from '@/domains/panier/PanierFound/ArticleBlock';
import {CalendarHeader} from '@/components/Modale/CalendarModale/Calendar/CalendarHeader';

interface CalendarProps {
  onReturn: () => void;
  onDateSelect: (livraison: Resource<LivraisonDesc>) => void;
  onDeliveryDateSelect: (quantite:number, selectionParColis:boolean) => void;
  isOpen: boolean;
  commandeParColis?:boolean,
  selectedLivraison: Resource<LivraisonDesc> | undefined,
  livraisonStates: State<LivraisonDesc>[] | undefined,
  selectionQuantitePossible?:boolean
  dateDebut?:string,
  itemPreco: State<ItemCatalogueDesc>,
}

export const Calendar: React.FC<CalendarProps> 
= ({
  onReturn, 
  onDateSelect, 
  onDeliveryDateSelect, 
  dateDebut, 
  itemPreco, 
  selectedLivraison, 
  livraisonStates, 
  commandeParColis, 
  selectionQuantitePossible
}) => {
  const {t} = useTranslation('common');
  
  const selectedLivraisonHook = useHalState(selectedLivraison);

  const startQuantite = commandeParColis ? (itemPreco.data.tolede.quantite_colis_entier ?? 0) :
    Math.max(itemPreco.data.tolede.minimum_de_commande_client,
      itemPreco.data.tolede.multiple_de_commande_client);

  const [quantite, setQuantite] = useState<number>(startQuantite);
  const {selectionParColis, setSelectionUnite} = useSelectionParColis(undefined, itemPreco.data.tolede.code_article);

  const [monthOffset, setMonthOffset] = useState<number>(0);

  const handleDateSelect = useCallback((livraison: Resource<LivraisonDesc>, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDateSelect(livraison);
  }, [onDateSelect]);

  const handleDeliveryDateSelect = useCallback(() => {
    onDeliveryDateSelect(quantite, selectionParColis);
  }, [onDeliveryDateSelect, quantite, selectionParColis]);

  const handleDateClick = useCallback((livraison: Resource<LivraisonDesc>, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleDateSelect(livraison, e);
    setMonthOffset(0);
  }, [handleDateSelect, setMonthOffset]);

  if (!selectedLivraisonHook.data) {
    return;
  }
  
  return (
    <Box border='1px solid' borderColor='gray.light' borderRadius='8px'>
      <HaliRenderOne
        nav={selectedLivraisonHook.navigable}
        render={({data}) =>
          <CalendarHeader
            dateLivraison={data.tolede.date_de_livraison}
            monthOffset={monthOffset}
            setMonthOffset={setMonthOffset}
          />
        }
      />
      {livraisonStates?
        <CalendarGrid 
          livraisonStates={livraisonStates} 
          selectedLivraison={selectedLivraison} 
          handleDateClick={handleDateClick} 
          monthOffset={monthOffset} 
          dateDebut={dateDebut}
          itemPreco={itemPreco}
          setSelectionUnite={setSelectionUnite}
          setQuantite={setQuantite}
        />
        : <LoadingSpinner/>}

      <HaliRenderAll all={[itemPreco.follow('article'), itemPreco.follow('disponibilite_article', {date_de_livraison:selectedLivraisonHook.data.tolede.date_de_livraison})]} 
        skeleton={()=><Skeleton w='100%' height='10'/>} render={({states:[articlePrecoState, dispoState]})=>{
          if (!articlePrecoState) return;
          return <>
            <InformationArticlePreco key='InformationArticlePreco'
              quantite={quantite}
              setQuantite={setQuantite}
              selectionParColis={selectionParColis}
              setSelectionUnite={setSelectionUnite}
              itemPrecoState={itemPreco} articlePrecoState={articlePrecoState}
              selectionQuantitePossible={selectionQuantitePossible ?? false}
              quantite_max={dispoState?.data.tolede.quantite_livrable?.type === 'finie' ? dispoState.data.tolede.quantite_livrable.quantite ?? dispoState.data.tolede.quantite_physique : undefined}
            />
          </>;
        }}/>
      
      <HStack mt={{base:'5', md:'10'}} gap='3' flexDirection={{base: 'column', md: 'row'}} alignItems='stretch' bottom='50px' p='5px'>
        <BlueButton texte={t('global.choisir')} onClick={() => {handleDeliveryDateSelect();}} mt='0' flex='1' flexShrink={0} isDisabled={!selectedLivraison}/>
        <GrayButton texte={t('global.annuler')} onClick={() => {onReturn();}} mt='0' flex='1' p='3' flexShrink={0} />
      </HStack>
    </Box>
  );
};

const InformationArticlePreco:React.FC<{
  quantite:number,
  setQuantite:Dispatch<SetStateAction<number>>,
  selectionParColis:boolean,
  setSelectionUnite:(unite:UniteQuantite)=>void,
  itemPrecoState:State<ItemCatalogueDesc>, articlePrecoState:State<ArticleDesc>,
  selectionQuantitePossible:boolean,
  quantite_max?:number
}> 
= ({
  quantite, 
  setQuantite,
  selectionParColis, 
  setSelectionUnite,
  itemPrecoState, 
  articlePrecoState, 
  selectionQuantitePossible,
  quantite_max
})=>{
  const {quantite_colis_entier} = itemPrecoState.data.tolede;

  const [tooltip, setTooltip] = useState<string>();

  return <HStack mt='4' px={4} py={2} alignItems='center' spacing={3}>
    <ArticleBlock
      code_article={articlePrecoState.data.tolede.code_article}
      article={articlePrecoState}
      fontSize='sm'
    />
    {selectionQuantitePossible && <VStack position="relative" w='100%'>
      <SelectUnite 
        itemCatalogue={itemPrecoState} 
        initialValue={selectionParColis?'colis':'unitaire'} 
        onSelect={(unite:UniteQuantite)=>{
          setSelectionUnite(unite);
          let newQuantite = quantite;
          if (unite==='colis' && quantite_colis_entier) {
            if (quantite < quantite_colis_entier) {
              newQuantite = quantite_colis_entier;
            } else if (quantite % quantite_colis_entier !== 0) {
              const nbColis = Math.round(quantite / quantite_colis_entier);
              newQuantite = nbColis * quantite_colis_entier;
            }
          }
          const event = {
            data: {
              data: {
                ligne_de_panier: {
                  _before: {
                    quantite
                  },
                  quantite: newQuantite,
                  quantite_en_colis_entier:  
                    unite==='colis' && quantite_colis_entier ? 
                      newQuantite / quantite_colis_entier : 
                      0
                }
              }
            }
          } as State<UniteSwitcheeEvent>;
          setQuantite(newQuantite);
          return Promise.resolve(event);
        }}
        quantite_max={quantite_max}
        w='100%'/>
      <TooltipAsToast tooltip={tooltip} setTooltip={setTooltip} bottom="0em" />
      <BuyProductPanierInput 
        itemCatalogue={itemPrecoState}
        quantite={quantite}
        onQuantite={(nouvelleQuantite) => {
          setQuantite(nouvelleQuantite);
        }} 
        commande_en={selectionParColis ? 'colis' : 'unitaire'} 
        somethingSubmitting={false}
      />
      {/* TODO - A TRADUIRE */}
      {quantite_max && <Text>Quantité maximale: {quantite_max}</Text>}
    </VStack>}
  </HStack>;
};
