import {Box, Text, VStack, HStack, Icon, Tag} from '@chakra-ui/react';
import {StarIcon} from '@chakra-ui/icons';
import {HaliRenderOne, HaliRenderMany} from '@synako/haligator';
import {useTranslation} from 'react-i18next';
import {FollowPromiseMany, FollowPromiseOne, State} from '@synako/halitrek';
import {AdresseDeFacturationDesc, AdresseDeLivraisonDesc, LivraisonDesc, TourneeDesc} from 'api-types/ffconnect-hal';
import {guessTitreAdresseDeLivraison} from '@/hooks/client/guessTitreAdresseDeLivraison';
import {dateEnTexte} from '@/utils/date-tools';

interface AddressesclientProps {
  adresseFacturation: FollowPromiseOne<AdresseDeFacturationDesc>,
  adressesLivraison: FollowPromiseMany<AdresseDeLivraisonDesc>,
  tournees: FollowPromiseMany<TourneeDesc>,
  prochaineLivraison?: State<LivraisonDesc>
}

export const ProchaineLivraison: React.FC<{prochaineLivraison: State<LivraisonDesc>}> = ({prochaineLivraison}) => {
  const {t} = useTranslation('common');
  const date_de_livraison = dateEnTexte(prochaineLivraison.data.tolede.date_de_livraison);
  return(
    <>
      <HStack>
        <Text fontWeight='500'>{t('back_office.prochaine_date_livraison')}</Text>
        <Text>{date_de_livraison}</Text>
      </HStack>
    </>
  );
};

export const Addressesclient: React.FC<AddressesclientProps> = ({adresseFacturation, adressesLivraison, tournees, prochaineLivraison}) => {
  const {t} = useTranslation('common');

  return (
    <VStack align='stretch' spacing='4'>
      <Box>
        <Text fontWeight='bold'>{t('mes_informations.adresses_de_facturation')}</Text>
        <VStack bg='white' p='20px' borderRadius='8px' border='1px solid' borderColor='gray.light' w='100%' mt='5px' justifyContent='start' alignItems='start' spacing='0'>
          <HaliRenderOne
            nav={adresseFacturation}
            render={({data: adresseFacturationData}) => (
              <>
                <Text>{adresseFacturationData.tolede.ligne1}</Text>
                <Text>{adresseFacturationData.tolede.ligne2}</Text>
                <Text>{adresseFacturationData.tolede.code_postal} {adresseFacturationData.tolede.ville}</Text>
              </>
            )}
            fallback={() => null}
          />
        </VStack>
      </Box>
      <Box>
        <Text fontWeight='bold'>{t('mes_informations.adresses_de_livraison')}</Text>
        <VStack align='stretch' spacing='2'>
          <VStack bg='white' p='20px' borderRadius='8px' border='1px solid' borderColor='gray.light' w='100%' mt='5px' justifyContent='start' alignItems='start'>
            <HStack>
              <Icon as={StarIcon} color='blue.main' />
              <Text fontWeight='bold'>{t('back_office.adresse_magasin')}</Text>
            </HStack>
            <VStack spacing='0' justifyContent='start' alignItems='start'>
              <HaliRenderMany
                many={adressesLivraison}
                render={({data: adressesLivraisonData}) => (
                  <>
                    {adressesLivraisonData[0] ? adressesLivraisonData.map((adresse) => 
                    {
                      const {nom_client, enseigne} = guessTitreAdresseDeLivraison(adresse.tolede);
                      return (
                        <Box key={adresse.id}>
                          {enseigne && <Text fontWeight="500">{enseigne}</Text>}
                          {nom_client && <Text fontWeight="500">{nom_client}</Text>}
                          <Text>{adresse.tolede.adresse.ligne1}</Text>
                          <Text>{adresse.tolede.adresse.ligne2}</Text>
                          <Text>{adresse.tolede.adresse.code_postal} {adresse.tolede.adresse.ville}</Text>
                        </Box>
                      );
                    }) : (
                      <Text>{t('back_office.pas_de_contact')}</Text>
                    )}
                  </>
                )}
              />
            </VStack>
          </VStack>
          <HaliRenderMany
            many={tournees}
            render={({data: tourneesData}) => {
              const tourneeEnlevementTrouvee = tourneesData.find((tournee) => tournee.tolede.mode_de_rendu === 'enlevement');
              return(
                <>
                  {prochaineLivraison && <ProchaineLivraison prochaineLivraison={prochaineLivraison} />}
                
                  {tourneeEnlevementTrouvee && (
                    <VStack bg='white' p='20px' borderRadius='8px' border='1px solid' borderColor='gray.light' w='100%' mt='5px' justifyContent='start' alignItems='start'>
                      <HStack>
                        <Text fontWeight='bold'>{t('back_office.adresse_depot')}</Text>
                        <Tag size='sm'>{t('back_office.point_livraison')}</Tag>
                      </HStack>
                      <VStack spacing='0' justifyContent='start' alignItems='start'>
                        <Text>{tourneeEnlevementTrouvee.tolede.adresse_d_enlevement?.ligne1}</Text>
                        <Text>{tourneeEnlevementTrouvee.tolede.adresse_d_enlevement?.ligne2}</Text>
                        <Text>{tourneeEnlevementTrouvee.tolede.adresse_d_enlevement?.code_postal} {tourneeEnlevementTrouvee.tolede.adresse_d_enlevement?.ville}</Text>
                      </VStack>
                    </VStack>
                  )}
                </>
              );
            }}
            fallback={() => null}
          />
        </VStack>
      </Box>
    </VStack>
  );
};
