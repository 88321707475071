import {Route} from '@synako/enhanced-router';

const index: Route = {
  // path: '/ffconnect',
  path: import.meta.env.BASE_URL,
};

export default {
  ...index,
};
