import {/*CartIcon*/} from '@/components/svg/CartIcon';
import {CheckLoaded} from '@/containers/CheckLoaded';
import {isDateEqual} from '@/utils/date-tools';
import {/*Box, Button, */Grid, GridItem, HStack, Text} from '@chakra-ui/react';
import {useHalState} from '@synako/haligator';
import {Resource, State} from '@synako/halitrek';
import {ItemCatalogueDesc, LivraisonDesc} from 'api-types/ffconnect-hal';
import React, {Dispatch, useCallback} from 'react';
import {CalendarCellInactive} from './components/CalendarCellInactive';
import {CalendarCellPanier} from './components/CalendarCellPanier';
import {CalendarCellLivraison} from './components/CalendarCellLivraison';
import {CalendarCellPanierArticle} from './components/CalendarCellPanierArticle';
import {CalendarCellLivraisonArticle} from './components/CalendarCellLivraisonArticle';
import {UniteQuantite} from 'api-types/ffconnect';

export const CalendarGrid: React.FC<{
  livraisonStates:State<LivraisonDesc>[], 
  selectedLivraison: Resource<LivraisonDesc>|undefined;
  handleDateClick: (_livraison: Resource<LivraisonDesc>, _e: React.MouseEvent<HTMLButtonElement>) => void;
  monthOffset:number; dateDebut:string|undefined
  itemPreco?:  State<ItemCatalogueDesc> | undefined,
  setSelectionUnite: (unite:UniteQuantite)=>void,
  setQuantite: Dispatch<React.SetStateAction<number>>
}> = ({
  livraisonStates, 
  selectedLivraison, 
  handleDateClick, 
  monthOffset, 
  dateDebut,
  itemPreco,
  setSelectionUnite,
  setQuantite
}) => {
  const selectedLivraisonHook = useHalState(selectedLivraison);

  const date_de_debut = dateDebut ? new Date(dateDebut) : undefined;

  const generateDateGrid = useCallback((selectedDate:Date) => {
    const selectedMonth = selectedDate.getMonth() + monthOffset;
    const selectedYear = selectedDate.getUTCFullYear();
    //On n'affiche automatiquement pas les dates du mois suivant, ainsi on peut se permettre de récupérer plus de dates que nécessaire
    const daysInMonth = 31;
    const startDate = new Date(selectedYear, selectedMonth, 1);
    const startDay = startDate.getDay();
    const dateGrid: Date[] = [];

    for (let i = 0; i <= daysInMonth + startDay; i++) {
      const currentDate = new Date(selectedYear, selectedMonth, i - startDay + 2);
      
      dateGrid.push(currentDate);
    }

    return dateGrid;
  }, [monthOffset]);

  return <CheckLoaded hook={selectedLivraisonHook} render={({state:livraisonState})=>{
    const selectedDate = new Date(livraisonState.data.tolede.date_de_livraison);
    return (<Grid w='100%' templateColumns="repeat(7, 1fr)" gap='2' columnGap={{
      base: '0',
      md: '3'
    }} mt={{base:'5', md:'7'}} justifyItems='stretch' alignItems='stretch'>
      {['D', 'L', 'M', 'M', 'J', 'V', 'S'].map((day, index) => <GridItem as={HStack} key={index} alignItems='stretch' justify='center' paddingBottom={2} color='#9E9E9E'>
        <Text>{day}</Text>
      </GridItem>)}
      {generateDateGrid(selectedDate).map((d, idx_raw) =>{
        const idx = `calendar_cell_${idx_raw}`;
        // date notée en UTC mais avec la timezone appliquée 
        // -> décalage de 1 ou 2h si le système est en Europe/Paris
        const date =  new Date(d.getTime() + d.getTimezoneOffset());
        const isSelected = isDateEqual(date, selectedDate);
        const livraisonAssociee = livraisonStates.find((l)=>{
          const dateLivraison = new Date(l.data.tolede.date_de_livraison);
          if (date_de_debut && dateLivraison < new Date(date_de_debut)) return false;
          return isDateEqual(dateLivraison, date); 
        });

        const offsetDate = new Date(selectedDate);
        offsetDate.setUTCDate(1); //Si la date selectionnée est > 28, Février est sauté par la prochaine opération
        offsetDate.setUTCMonth(offsetDate.getUTCMonth()+monthOffset);
        const offsetMonth = offsetDate.getUTCMonth();
        const dateMonth = date.getUTCMonth();

        if (dateMonth <  offsetMonth || date.getUTCFullYear() < offsetDate.getUTCFullYear()) {
          return <GridItem key={idx}></GridItem>;
        }
        if (dateMonth > offsetMonth) return null;
        if (livraisonAssociee) { // la date sélectionnée correspond à une livraison
          if (livraisonAssociee.hasOneLink('panier_ouvert')) { // la livraison a un panier ouvert
            if (itemPreco) { // contexte de précommande
              return (
                <CalendarCellPanierArticle
                  key={idx} 
                  date={date}
                  isSelected={isSelected}
                  livraisonAssociee={livraisonAssociee}
                  handleDateClick={handleDateClick}
                  itemPreco={itemPreco}
                  setSelectionUnite={setSelectionUnite}
                  setQuantite={setQuantite}
                />
              );
            }
            return ( // pas d'article à afficher
              <CalendarCellPanier 
                key={idx} 
                date={date}
                isSelected={isSelected}
                livraisonAssociee={livraisonAssociee}
                handleDateClick={handleDateClick}
              />
            );
          } else { // pas de panier ouvert sur la livraison
            if (itemPreco) { // contexte de précommande
              return (
                <CalendarCellLivraisonArticle
                  key={idx} 
                  date={date}
                  isSelected={isSelected}
                  livraisonAssociee={livraisonAssociee}
                  handleDateClick={handleDateClick}
                  itemPreco={itemPreco}
                  setSelectionUnite={setSelectionUnite}
                  setQuantite={setQuantite}
                />
              );
            }
            return (
              <CalendarCellLivraison 
                key={idx} 
                date={date}
                isSelected={isSelected}
                livraisonAssociee={livraisonAssociee}
                handleDateClick={handleDateClick}
              />
            );
          }
        } else { // date non sélectionnable
          return <CalendarCellInactive key={idx} date={date} />;
        }
      } 
      
      )}
    </Grid>);
  }}/>;
};
