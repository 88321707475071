import {HStack, StyleProps, VStack} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {Dispatch, SetStateAction, useEffect} from 'react';
import {State} from '@synako/halitrek';
import {ItemCatalogueDesc, LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {UniteQuantite} from 'api-types/ffconnect';
import {useLivraisonsPossiblesContext} from '@/contexts/useContexts/useLivraisonsPossiblesContext';
import {TooltipAsToast} from '@/components/TooltipAsToast/TooltipAsToast';
import {BuyProductPanierInput} from '../panier/BuyProduct/BuyProductPanierInput';
import {PrecommandeButton} from '../panier/BuyProduct/PrecommandeButton';
import {AjouterButton} from '../panier/BuyProduct/AjouterButton';
import {AjoutListeBouton} from '../cadencier/components/AjoutListeBouton';
import {useLigneDePanierCommands} from '@/hooks/ligneDePanier/use-ligne-de-panier-commands';

export const ProduitBuyButton: React.FC<{
  lignePanier?:State<LigneDePanierDesc>,
  itemState: State<ItemCatalogueDesc>,
  commande_en: UniteQuantite,
  somethingSubmitting?: boolean,
  afficher_bouton_liste?:boolean
  quantiteDisplay:number|undefined,
  setQuantiteDisplay:(quantite:number|undefined)=>void,
  tooltip:string|undefined,
  setTooltip:Dispatch<SetStateAction<string | undefined>>
} & StyleProps> 
= ({lignePanier, itemState, commande_en, 
  somethingSubmitting=false, afficher_bouton_liste,
  quantiteDisplay, setQuantiteDisplay,
  tooltip, setTooltip,
  ...props
}) => {
  const {t} = useTranslation('common');

  useEffect(() => {
    setQuantiteDisplay(lignePanier?.data.quantite);
  }, [lignePanier, setQuantiteDisplay]);

  const {changerLaQuantite} = useLigneDePanierCommands(lignePanier);
  const precommande = itemState.data.tolede.precommande;
  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();

  return <VStack justifyContent={'end'} mt='15px' w={{base:'100%', lg:'auto'}} minW='300px' position="relative" {...props}>
    <TooltipAsToast tooltip={tooltip} setTooltip={setTooltip} bottom="0em"/>
    {quantiteDisplay !== undefined && quantiteDisplay !== 0
      ? 
      <BuyProductPanierInput
        quantite={quantiteDisplay}
        itemCatalogue={itemState}
        commande_en={commande_en}
        somethingSubmitting={somethingSubmitting || changerLaQuantite.isSubmitting}
        onQuantite={(nouvelleQuantite) => {
          setQuantiteDisplay(nouvelleQuantite);
          changerLaQuantite.submit({
            quantite: nouvelleQuantite
          });
        }}
      /> : 
      <HStack w='100%'>
        {precommande
          ? <PrecommandeButton itemState={itemState} t={t}/>
          : <AjouterButton livraisonSelectionnee={livraisonSelectionnee.resource}
            itemCatalogue={itemState.data} commandeEnColis={commande_en==='colis'} 
            t={t}/>}
        {afficher_bouton_liste && <AjoutListeBouton itemState={itemState}/>}
      </HStack>
    }
  </VStack>;    
};
