import {useState, useEffect} from 'react';
import {FiltresItemCatalogue, coerceFiltresItemCatalogue as coerce, useSearchClient} from '@/hooks/catalogue/use-search';
import {useFilters} from '@/hooks/use-filters';

interface PromoMpSearchOptions {
  inclureFiltersHook?: boolean;
}

export const usePromoMpSearch = (options: PromoMpSearchOptions = {}) => {
  const {inclureFiltersHook = false} = options;
  const filtersHook = useFilters<FiltresItemCatalogue, typeof coerce>({coerce});
  const search = useSearchClient();
  const [itemResultsPromo, setItemResultsPromo] = useState<Awaited<ReturnType<typeof search.searchItems>>>();
  const [itemResultsMP, setItemResultsMP] = useState<Awaited<ReturnType<typeof search.searchItems>>>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (search.indexIsBuilt) {
      setIsLoading(true);
      Promise.all([
        search.searchItems({...filtersHook.filters, nature_du_prix: 'promo'}),
        search.searchItems({...filtersHook.filters, est_MP: true}),
      ])
        .then(([promoResults, mpResults]) => {
          setItemResultsPromo(promoResults);
          setItemResultsMP(mpResults);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.indexIsBuilt, ...(inclureFiltersHook ? [filtersHook.filters] : [])]);

  const promoDispo = itemResultsPromo?.hits && itemResultsPromo.hits.length > 0;

  return {
    itemResultsPromo,
    itemResultsMP,
    isLoading,
    promoDispo,
    filtersHook
  };
};
