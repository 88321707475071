import {SearchClientProvider} from '@/hooks/catalogue/SearchClientContext';
import {useClient} from '@/haligator-factory';
import NiceModal from '@ebay/nice-modal-react';
import {HaliRenderMany, useHalState} from '@synako/haligator';
import {Outlet, ScrollRestoration} from 'react-router-dom';
import {ChargementCompte} from './ChargementCompte';
import {CompteBloque} from './CompteBloque';
import {LivraisonsPossibles} from '@/contexts/LivraisonsPossiblesContextProvider';
import {BreakpointProvider} from '@/chakra/BreakpointProvider';

export const ClientApp : React.FC = () => {
  const force = new URLSearchParams(location.search).get('force');
  const client = useClient();
  const livraisons = useHalState(client.follow('livraisons_possibles'));
  const catalogue = useHalState(client.follow('catalogue'));
  const search = useHalState(client.follow('search'));
  useHalState(client.follow('commandes', {periode: 'en_cours'}));

  if (livraisons.loading || catalogue.loading || search.loading) {
    return (
      <ChargementCompte idMessage="chargement_catalogue" />
    );
  }

  if (livraisons.error ?? catalogue.error ?? search.error) {
    // pour permettre l'affichage éventuel de la liste des clients sélectionnables, 
    // on fait le rendu de l'outlet si 'force' est présent dans la query string
    if (force) {
      return (
        <Outlet />
      );
    }
    return (
      <HaliRenderMany
        many={client.follow('representants').followAll('items')}
        render={({states: representants})=>{
          const commerciale = representants.find((representant) => representant.data.tolede.en_tant_que === 'commercial');
          return(
            <CompteBloque commerciale={commerciale}/>
          );
        }}
      />        

    );
  }

  return (
    <SearchClientProvider client={client}>
      <LivraisonsPossibles livraisonsPossiblesPromise={livraisons.state?.followAll('items')}>
        <BreakpointProvider>
          <ScrollRestoration />
          <NiceModal.Provider>
            <Outlet />
          </NiceModal.Provider>
        </BreakpointProvider>
      </LivraisonsPossibles>
    </SearchClientProvider>
  );
};
