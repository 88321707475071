import {useMemo} from 'react';
import {GridItem} from '@chakra-ui/react';
import {State} from '@synako/halitrek';
import {useTranslation} from 'react-i18next';
import {LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {LigneProblem} from 'api-types/ffconnect';
import {ProduitPanierAffiche} from './ProduitPanier/ProduitPanierAffiche';
import {ErreursLignesBlock} from './ErreursLignesBlock';
import {ProduitPanierError} from './ProduitPanier/ProduitPanierError';
import {analyseLignes} from '@/utils/analyseLignes';

export const PanierContent: React.FC<{
  date_de_livraison: string,
  lignesDePanier: State<LigneDePanierDesc>[],
  problemesLignes: LigneProblem[],
  validationRefresh: boolean,
}> = ({
  date_de_livraison,
  lignesDePanier,
  problemesLignes,
  validationRefresh,
}) => {
  const {t} = useTranslation('common');
  const {lignesAvecProbleme, problemes} = useMemo(() => analyseLignes(lignesDePanier, problemesLignes), [lignesDePanier, problemesLignes]);
  
  return (
    <GridItem colSpan={{base: 1, lg: 5}}>
      {problemes.length > 0 && (
        <ErreursLignesBlock nbErreurs={problemes.length} dateDeLivraison={date_de_livraison} t={t}>
          {problemes.map(({probleme, ligneDePanier}) => {
            const idError = `${probleme.problem.code}-${probleme.ligne_de_panier_id}`;
            return <ProduitPanierError
              key={idError} 
              idError={idError}
              ligneDePanier={ligneDePanier}
              probleme={probleme}
              validationRefresh={validationRefresh}
            />;
          })}
        </ErreursLignesBlock>
      )}

      {lignesAvecProbleme.map(({ligneDePanier, probleme}) => {
        const idError = probleme ? `${probleme.problem.code}-${probleme.ligne_de_panier_id}` : ligneDePanier.data.id;
        return <ProduitPanierAffiche
          key={ligneDePanier.data.id} 
          idError={idError}
          ligneDePanier={ligneDePanier}
          probleme={probleme}
        />;
      })}
    </GridItem>
  );  
};
