import {HStack, Text, Button, VStack, Box} from '@chakra-ui/react';
import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {Drawer, DrawerCloseButton, DrawerContent, DrawerOverlay, Image, Input, InputGroup, InputLeftElement, SimpleGrid, Skeleton, Stack} from '@chakra-ui/react';
import {ItemCatalogueDesc, LivraisonDesc} from 'api-types/ffconnect-hal';
import {State, Resource} from '@synako/halitrek';
import {HaliRenderOne, useHalState} from '@synako/haligator';
import {FiltresItemCatalogue, useSearchClient, coerceFiltresItemCatalogue as coerce} from '@/hooks/catalogue/use-search';
import {useEffect, useState, useCallback} from 'react';
import {Results} from '@orama/orama';
import {Article, HitItemCatalogue} from 'api-types/ffconnect';
import {toDecimalFr} from '@/utils/toDecimalFr';
import {Trans, useTranslation} from 'react-i18next';
import {DisplayLabels} from './DisplayLabels';
import {SwitchIcon} from '@/components/svg/SwitchIcon';
import {SearchIcon} from '@chakra-ui/icons';
import {CatalogFilter} from '@/domains/catalogue/components/CatalogFilter/CatalogFilter';
import {FilterProvider} from '@/hooks/use-filters-context';
import {useFilters} from '@/hooks/use-filters';
import {useApi, useClient} from '@/haligator-factory';
import {isSelectionColisAvailable} from '@/hooks/catalogue/isSelectionColisAvailable';
import {ListeDrapeaux} from './listeDrapeaux';
import {PrecommandeModal} from '@/domains/ligneProduit/PrecommandeModal';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {RemplacerUnArticleCommand} from 'api-types/ffconnect';
import {imageOptimisee} from '@/domains/mediatheque/optimisation';
import {LabelDisponibilite} from '@/domains/catalogue/components/LabelDisponibilite';
import {useLivraisonsPossiblesContext} from '@/contexts/useContexts/useLivraisonsPossiblesContext';
import {differenceInDays} from 'date-fns/differenceInDays';

export const ModaleRemplacementCatalogue = NiceModal.create<{
  articleData: Article,
  remplacerAction: (payload: RemplacerUnArticleCommand)=>void,
}>(({
      articleData,
      remplacerAction,
    }) => {
      const modal = useModal();
      const {t} = useTranslation('common');

      const search = useSearchClient();
      const filtersHook = useFilters<FiltresItemCatalogue, typeof coerce>({coerce});

      const [remplacements, setRemplacements] = useState<Results<HitItemCatalogue>|undefined>(undefined);

      const [term, setTerm] = useState('');

      const {livraisonSelectionnee} = useLivraisonsPossiblesContext();

      const handleTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const selectValue = event.target.value;
        setTerm(selectValue);
      };

      useEffect(()=>{
        if(!search.indexIsBuilt) return;
        const fetchRemplacements = async () => {
          const diff = differenceInDays(new Date(), livraisonSelectionnee.data.tolede.cut_off_de_preparation!);
          const ApourB = diff <= 1;
          let results;
          if (term) {
            results = await search.searchItems({
              nature_du_prix: filtersHook.filters.nature_du_prix,
              pays_d_origine: filtersHook.filters.pays_d_origine,
              labels: filtersHook.filters.labels,
              q: term,
              precommande: ApourB ? false : undefined,
            });
          } else {
            results = await search.remplacementPour(
              articleData,
              {
                enleverPrecommande: ApourB,
              }
            );
          }
          setRemplacements(results);
        };
        fetchRemplacements();
      },
      //Including "search" create an infinite loop
      //eslint-disable-next-line react-hooks/exhaustive-deps
      [search.indexIsBuilt, articleData, term, filtersHook.filters, livraisonSelectionnee]
      );

      return <Drawer
        placement='right'
        isOpen={modal.visible}
        onClose={()=>{modal.remove();}}
        size='lg'
      >
        <DrawerOverlay />
        <DrawerContent position='relative' bg='#F5F5F5' transition='all 0.2s ease-out' overflow='scroll'>
          <FilterProvider hook={filtersHook}>
            <VStack height="100%" spacing={0}>
              <HStack
                p='4' bg='blue.main' color='white' position='sticky' top='0' zIndex='sticky' w='100%'>
                <Text>
                  <Trans t={t}>{t('commande.remplacer_article', {nom:articleData.tolede.libelle_commercial})}</Trans>
                </Text>
                <DrawerCloseButton/>
              </HStack>
              <Box w='100%' maxW='container.xl' mx='auto'>
                <VStack spacing={4} align='stretch' w='100%'>
                  <Box bg='gray.light' p='5'>
                    <InputGroup zIndex={1003} bg='white' borderRadius='8px'>
                      <InputLeftElement
                        pointerEvents="auto"
                        cursor="pointer"
                      >
                        <SearchIcon />
                      </InputLeftElement>
                      <Input
                        placeholder={t('commande.chercher_tout_catalogue')}
                        onChange={handleTermChange}
                        value={term}
                      />
                    </InputGroup>
                  </Box>
                  {term && <Box p='5' pl={{base:'5', md:'0'}} w='100%'>
                    <CatalogFilter itemResults={remplacements} 
                      props={{bg:'transparent', p:'0!important'}}/>
                  </Box>}
                </VStack>
              </Box>
              <Box flex={1} w="100%" overflowY="auto" mb='60px'>{
                (remplacements?.hits.length===0)?
                  <Text textAlign='center' my='10' fontSize='xl' color='gray.dark'>{t('commande.remplacement_aucun_article')}</Text>
                  : <SimpleGrid columns={{base:1, md:3}} spacing="0" p="20px" gap="0">
                    {remplacements?.hits.map((hitProduit) => {
                      // Régles d'affichage
                      // si A pour B : panier dont la date est à moins de un jour du cut-off, il faut masquer la précommande.
                      // si A pour C : panier au delà d'un jour alors on peut afficher un autre article en pré-commande.
                      return (
                        <RemplacementItem
                          key={hitProduit.id}
                          hit={hitProduit.document}
                          remplacerAction={(nouveau_code_article: string, date_de_livraison_destination?: string | undefined) => {
                            remplacerAction({
                              date_de_livraison_destination,
                              code_article: nouveau_code_article
                            });
                            modal.remove();
                            setRemplacements(undefined);
                          }}
                        />
                      );
                    })}
                  </SimpleGrid>}
              </Box>
            </VStack>
          </FilterProvider>
        </DrawerContent>
      </Drawer>;
    });

const RemplacementItemSkeleton: React.FC = () => {
  return (
    <VStack height='420px' padding="10px" bg='white' border="1px" borderColor='gray.light' alignItems='stretch' position='relative'>
      <Skeleton height='20px' width='100%' />
      <Skeleton height='134px' width='100%' mb='10px' />
      <VStack width='100%'>
        <HStack height='22px' justifyContent="left" width='100%'>
          <Skeleton height='12px' width='80px' />
        </HStack>
        <Skeleton height='60px' width='100%' />
        <Skeleton height='10px' width='100%'/>
      </VStack>
      <HStack justifyContent='end' width='100%' mb='1'>
        <VStack height='40px' alignItems='flex-end' gap='0' mt='10px'>
          <Skeleton height='20px' width='100px' />
          <Skeleton height='11px' width='80px' />
        </VStack>
      </HStack>
      <Skeleton height='40px' width='100%' />
    </VStack>
  );
};

const RemplacementItem:React.FC<{
  hit: HitItemCatalogue,
  remplacerAction: (nouveau_code_article: string, date_de_livraison_destination?:string|undefined) => void
}> = ({hit, remplacerAction}) => {
  const code_article = hit.code;
  const itemHook = useHalState(useClient().follow('go:item_catalogue', {code_article}));
  const articleNav = useApi().follow('get-article', {code_article});
  const {t} = useTranslation('common');
  const textToast = useSimpleTextToast();
  const {livraisonSelectionnee, setLivraisonSelectionnee} = useLivraisonsPossiblesContext();

  const pays = hit.pays_d_origine ?? [];

  const AjouterAuPanierPrecommande = useCallback(async (selectedLivraison: Resource<LivraisonDesc>) => {
    const {data:{tolede:{date_de_livraison}}} = await selectedLivraison.get();
    remplacerAction(code_article, date_de_livraison);
    textToast(t('toast.ajout_preco', {date:new Date(date_de_livraison)}));
  }, [remplacerAction, code_article, textToast, t]);

  const LabelSkeleton: React.FC = () => null;
  
  return (
    <HaliRenderOne nav={itemHook.navigable} skeleton={RemplacementItemSkeleton} render={({data:itemData, state:itemState}) => {
      const precommande = itemState.data.tolede.precommande;
      const itemVignette = imageOptimisee(hit.vignette_url, 200);
      const {tolede:toledeItem} = itemData;
      const montant_ht = toledeItem.prix_par_livraison?.[0]?.montant_ht;

      const handleRemplacementAction = () => {
        if (precommande) {
          NiceModal.show(PrecommandeModal, {
            livraisonSelectionnee: livraisonSelectionnee,
            setLivraisonSelectionnee,
            itemState,
            remplacementAction: AjouterAuPanierPrecommande
          });
        } else {
          remplacerAction(hit.code);
        }
      };

      return (
        <>
          {/* Version desktop */}
          <VStack display={{base:'none', md:'flex'}} height='450px' padding="10px" bg='white' border="1px" borderColor='gray.light' alignItems='stretch' position='relative'>
            <LabelDisponibilite precommande={precommande} />
            <Stack maxHeight='150px' flexGrow={1} cursor='pointer' p='5px' justifyContent="center" alignItems="center" mt='10px'>
              <Image src={itemVignette} alt={hit.libelle_commercial} objectFit="contain" width="100%" height="100%" maxWidth="202px" maxHeight="134px"/>
            </Stack>
            <VStack width='100%' alignItems='stretch' spacing='0' flex={1}>
              <HStack height='22px' alignItems="center" justifyContent="start">
                <Text align='left' color='gray.dark' fontSize='xs' fontWeight='400' noOfLines={1}>
                  {code_article}
                </Text>
                <HStack>
                  <HaliRenderOne nav={articleNav} render={({state: articlestate}) => {
                    return <DisplayLabels articleState={articlestate}/>;
                  }}/>
                  <ListeDrapeaux listepays={pays} max={4}/>
                </HStack>
              </HStack>
              <Text align='left' color='black' fontSize='sm' lineHeight='18px' fontWeight='600' noOfLines={4}>
                {hit.libelle_commercial}
              </Text>
              <Text align='left' color='black' fontSize='xs' lineHeight='18px' mb='20px' noOfLines={1}>
                {hit.marque}
              </Text>
              <RemplacementDisplayMultiples itemState={itemState}/>
            </VStack>
            <HStack justifyContent='end' width='100%' mb='3'>
              <VStack height='40px' alignItems='flex-end' gap='0' mt='10px'>
                <HStack gap='0.5' alignItems='start'>
                  <Text textColor='black.text' fontSize='xl' fontWeight='700'>{toDecimalFr(montant_ht, 2,3)}</Text>
                  <Text fontSize='xs'>€</Text>
                  <Text fontSize='xs'>{t('ligne_produit.HT')}/{t(`unite.${toledeItem.unite_de_facturation}`, {count: 1})}</Text>
                </HStack>
                <Text fontSize='2xs' fontWeight='400' color='gray.dark' textAlign='left' width='100%'>
                  {t('ligne_produit.tva_percent', {tauxTVA:toledeItem.taux_de_tva})}
                </Text>
              </VStack>
            </HStack>
            <Button
              onClick={handleRemplacementAction}
              variant='outline'
              borderColor='blue.main'
              color='blue.main'
              leftIcon={<SwitchIcon color='blue.main'/>}
              h='40px'
            >
              {t('global.remplacer')}
            </Button>
          </VStack>

          {/* Version mobile */}
          <VStack display={{base:'flex', md:'none'}}
            height='255px' width='100%' padding="20px" pt='30px' border="1px"
            borderColor='gray.light' position="relative" bg='white'>
            <LabelDisponibilite precommande={precommande} />
            <HStack height='85px' width='100%' mb='10px' alignItems="flex-start" justifyContent="flex-start" spacing={4}>
              <Box boxSize="90px" display="flex" justifyContent="center" alignItems="center">
                <Image src={itemVignette} alt={hit.libelle_commercial} objectFit="contain" maxWidth="100%" maxHeight="100%" width="auto" height="auto" />
              </Box>
              <VStack alignItems="flex-start" spacing={1}>
                <HStack >
                  <Text align='left' color='gray.dark' fontSize='xs' fontWeight='400'>{code_article}</Text>
                  <HaliRenderOne nav={articleNav} skeleton={LabelSkeleton} render={({state: articlestate}) => {
                    return<DisplayLabels articleState={articlestate}/>;
                  }}/>
                  <ListeDrapeaux listepays={pays} max={9}/>
                </HStack>
                <VStack spacing={0} alignItems='start'>
                  <Text align='left' color='black' fontSize='sm' lineHeight='18px' fontWeight='600'>{hit.libelle_commercial}</Text>
                  <Text align='left' color='black' fontSize='xs' lineHeight='18px'>{hit.marque}</Text>
                  <RemplacementDisplayMultiples itemState={itemState}/>
                </VStack>
              </VStack>
            </HStack>
            <HStack justifyContent='end' width='100%' mb='3'>
              <VStack height='40px' alignItems='flex-end' gap='0' mt='10px'>
                <HStack gap='0.5' alignItems='start'>
                  <Text textColor='black.text' fontSize='xl' fontWeight='700'>{toDecimalFr(montant_ht, 2,3)}</Text>
                  <Text fontSize='xs'>€</Text>
                  <Text fontSize='xs'>{t('ligne_produit.HT')}/{t(`unite.${toledeItem.unite_de_facturation}`, {count: 1})}</Text>
                </HStack>
                <Text fontSize='2xs' fontWeight='400' color='gray.dark' textAlign='left' width='100%'>
                  {t('ligne_produit.tva_percent', {tauxTVA:toledeItem.taux_de_tva})}
                </Text>
              </VStack>
            </HStack>
            <Button
              onClick={handleRemplacementAction}
              variant='outline'
              borderColor='blue.main'
              color='blue.main'
              leftIcon={<SwitchIcon color='blue.main'/>}
              h='40px'
              w='100%'
            >
              {t('global.remplacer')}
            </Button>
          </VStack>
        </>
      );
    }}/>
  );
};

const RemplacementDisplayMultiples:React.FC<{itemState:State<ItemCatalogueDesc>}> = ({itemState})=>{
  const {t} = useTranslation('common');
  const {tolede} = itemState.data;

  const quantite_colis_entier = itemState.data.tolede.quantite_colis_entier??0;
  const colisPossible = isSelectionColisAvailable(quantite_colis_entier, tolede.multiple_de_commande_client);

  return <>{
    <Text align='left' color='black' fontSize='2xs' textColor='gray.dark'>
      {t('commande.par_multiple', {quantite:tolede.multiple_de_commande_client, unite: t(`unite.${tolede.unite_de_commande_client}`, {count:tolede.multiple_de_commande_client})})}
      {colisPossible?` | ${t('commande.par_colis', {quantite:quantite_colis_entier, unite: t(`unite.${tolede.unite_de_commande_client}`, {count:quantite_colis_entier})})}`:''}
    </Text>}</>;
};
