import {Box, HStack, Text, VStack} from '@chakra-ui/react';
import {toDecimalFr} from '@/utils/toDecimalFr';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {State} from '@synako/halitrek';
import {useTranslation} from 'react-i18next';

export const ProduitBottomBar: React.FC<{
  itemState:State<ItemCatalogueDesc>,
  prixHT:number,
  tva:number,
  buyBlock:React.ReactElement|undefined
}> = ({itemState, prixHT, tva, buyBlock}) => {
  const {t} = useTranslation('common');
  const uniteFacturation = itemState.data.tolede.unite_de_facturation;

  return (
    <>
      <Box position="fixed" bottom="4.5rem" left={0} right={0} zIndex='dropdown' bg="white" p="10px" borderTopWidth="1px" borderColor="gray.light" shadow="md">
        <HStack p="0px 15px 0px 10px">
          <VStack height="40px" width="30%" alignItems="flex-start" gap="0" flex="2">
            <HStack gap="0.3" alignItems="start" mr="10px">
              <Text textColor="black.text" fontSize="xl" fontWeight="700">{toDecimalFr(prixHT, 2,3)}</Text>
              <Text fontSize="xs" fontWeight="700">€</Text>
              <Text fontSize="xs" fontWeight="700">{t('ligne_produit.HT')}/{t(`unite.${uniteFacturation}`, {count:1})}</Text>
            </HStack>
            <Text fontSize='2xs' fontWeight="400" color="gray.dark">
              {t('ligne_produit.tva_percent', {tauxTVA:tva})}
            </Text>
          </VStack>
          <Box width="62%">
            {buyBlock}
          </Box>
        </HStack>
      </Box>
    </>
  );
};
